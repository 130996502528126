import {Banner} from "../../components/Banner/Banner";
import React, {useEffect, useState} from "react";
import {DeviceList} from "../../components/DeviceList/DeviceList";
import styles from "./Pos.module.scss";
import {Process} from "../../components/Process/Process";
import {
  dataSourceK4Tech,
  dataSourceK7Tech,
  dataSourcePosOperation,
  dataSourcePosTech,
  dataSourceSameTech
} from "./dataSource";
import {Specs} from "../../components/Specs/Specs";
import {Col, Row} from "antd";
import {useLocation} from "react-router-dom";
import {scrollTo} from "../../utils/utils";
import {YiWoVideo} from "../../components/YiWoVideo/YiWoVideo";

export const Pos = () => {
  let location = useLocation().state;
  const [isK4, setIsK4] = useState(true);
  useEffect(() => {
    scrollTo(location)
  }, []);

  const btnSelectK4 = () => {
    setIsK4(true);
  };
  const btnSelectK7 = () => {
    setIsK4(false);
  };

  return (
    <div>
      <Banner title={"蚁窝智能硬件设备"}
              image={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/banner/banner.png"}
              text={"基于多年对传统团餐及传统食堂用餐模式的深度了解，蚁窝技术团队从运营及应用等多维度分析探究，研发出多款应用于智慧餐厅的智能设备，能够有效解决传统食堂的痛点，提升用餐体验。"}/>
      <div id={"pos_device"}>
        <DeviceList heightLightKey={"2"}/>
      </div>
      <div id={"pos"}>
        <Banner
          title={"蚁窝双面屏收银机"}
          titleColor={"#FFCA00"}
          text={"蚁窝双面屏收银机适用于各种零售及点餐场景，与支付宝联合开发，结合套餐功能和输入金额收银功能，支持刷脸支付、扫码支付、刷卡支付等多种支付方式。"}
          textColor={"#8E6800"}
          image={"https://assets.yiwoaikeji.com/prod/official-website-assets/pos/banner/banner_pos.png"}/>
      </div>
      <YiWoVideo
        id={"double_sided_pos_operation"}
        width={"10rem"}
        height={"3rem"}
        videoUrl={"video/double_sided_pos_operation.mp4"}
        poster={"pos/backgroundImage/bck_operation_bind01.png"}/>
      <div className={`${styles.operation_pad}`}>
        <Process space={10} items={dataSourcePosOperation} arrowWidth={96}/>
      </div>
      <div className={styles.tech_container}>
        <Specs items={dataSourcePosTech}/>
      </div>
      <div id={"outlet"}>
        <Banner title={"蚁窝外卖打单机"}
                text={"蚁窝外卖打单机是一款具有超高集成度和便携性的供外卖打单的机器，可以随时为用户提供集强大计算、安全支付、高速打印、快速数据采集和信息访问功能于一体的产品。（共两款可选）"}
                image={isK4 ? "https://assets.yiwoaikeji.com/prod/official-website-assets/pos/banner/banner_waiMai_printing.png"
                  : "https://assets.yiwoaikeji.com/prod/official-website-assets/pos/banner/banner_waiMai_K7.png"}>
          <div className={styles.accessory_card_container_btn}>
            <div style={{cursor: "pointer"}} onClick={btnSelectK4}
                 className={isK4 ? styles.accessory_card_container_btn_active
                   : styles.accessory_card_container_btn_not_active}>
              K4普通款
            </div>
            <div
              className={isK4 ? styles.accessory_card_container_btn_not_active : styles.accessory_card_container_btn_active}
              onClick={btnSelectK7} style={{cursor: "pointer"}}>
              K7高配款
            </div>
          </div>
        </Banner>
      </div>
      <YiWoVideo
        id={"printing_operation"}
        width={"100%"}
        height={"3.5rem"}
        poster={`pos/backgroundImage/${isK4 ? 'bck_printing_video' : 'bck_waimai_k7_video'}.png`}
        videoUrl={"video/printing_operation.mp4"}/>
      <div className={styles.waiMai_tech_container}>
        <Row style={{marginBottom: "0.3rem"}} gutter={[16, 10]}>
          <Col xs={24} md={12}>
            <div className={styles.tech_bracelet_title}>技术规格（K4普通款）</div>
            <div className={styles.tech_bracelet}>
              <Row style={{marginTop: "0.2rem"}} gutter={[64, 64]}>
                {dataSourceK4Tech.map((item, index) => {
                  return (
                    <Col style={{display: "flex", alignItems: "center"}} key={index.toString()}
                         md={12} xs={24}>
                      <img src={item.image}/>
                      <div className={styles.tech_bracelet_card_margin}>
                        <div className={styles.tech_bracelet_card_title}>{item.title}</div>
                        <div className={styles.tech_bracelet_card_text}>{item.children.map((item, index) => {
                          return (
                            <div key={index.toString()}>{item.text}</div>
                          )
                        })}
                        </div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className={styles.tech_bracelet_title}>技术规格（K7高配款）</div>
            <div className={styles.tech_bracelet}>
              <Row style={{marginTop: "0.2rem"}} gutter={[64, 64]}>
                {dataSourceK7Tech.map((item, index) => {
                  return (
                    <Col style={{display: "flex", alignItems: "center"}} key={index.toString()}
                         md={12} xs={24}>
                      <img src={item.image}/>
                      <div className={styles.tech_bracelet_card_margin}>
                        <div className={styles.tech_bracelet_card_title}>{item.title}</div>
                        <div className={styles.tech_bracelet_card_text}>{item.children.map((item, index) => {
                          return (
                            <div key={index.toString()}>{item.text}</div>
                          )
                        })}
                        </div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </Col>
        </Row>
        <Specs techTitle={false} titleColor={"#FFFFFF"} textColor={"#DEDEDE"} items={dataSourceSameTech}/>
      </div>
    </div>
  )
}