import React, {useEffect, useState} from "react";
import styles from "./Case.module.scss";
import {Col, ConfigProvider, Modal, Pagination, PaginationProps, Row} from "antd";
import {
  dataSourceNavText,
  dataSourceBusiness,
  dataSourceUniversity,
  dataSourceSchool,
  dataSourceFactory,
  dataSourceIndusPark,
  dataSourceCommunity,
  dataSourceAllExamples
} from "./dataSource";
import {useLocation} from "react-router-dom";
import {useEventBusSubscribeDelay} from "../../envent_bus/event_bus";
import {CaseListener} from "../../envent_bus/events";
import {Element} from 'rc-banner-anim';
import {TweenOneGroup} from 'rc-tween-one';
import 'rc-banner-anim/assets/index.css';
import {OverPack} from "rc-scroll-anim";
import {CaseCard} from "../../components/CaseCard/CaseCard";
import {toRemAuto} from "../../utils/utils";

const Case = () => {
  const [type, setType] = useState("1");
  const [showExamples, setShowExamples] = useState<Array<object>>(dataSourceAllExamples.slice(0, 7));
  const [pageNum, setPageNum] = useState(1);
  const [totalNum, setTotalNum] = useState(dataSourceAllExamples.length);
  const [toLoadExamples, setToLoadExamples] = useState(dataSourceAllExamples);
  const setCaseTypeListener = useEventBusSubscribeDelay<CaseListener>(CaseListener.eventName);
  const [showCarousel, setShowCarousel] = useState(false);
  const [showImages, setShowImages] = useState<any>([]);
  const [imagesIndex, setImagesIndex] = useState(0);
  const [containerStyle, setContainerStyle] = useState({});
  let location = useLocation().state;
  const BgElement = Element.BgElement;

  useEffect(() => {
    if (location === null) {
      document.body.scrollIntoView();
      return;
    }
    //保证执行
    beforeLoadSelect(location.local)
    setCaseTypeListener(res => {
      beforeLoadSelect(res.caseType.local)
    })
  }, []);

  useEffect(() => {
    if (document.documentElement.clientWidth <= 1200) {
      return;
    }
    // if (showExamples.length === 0) {
    //   setContainerStyle({height: "auto"})
    // } else if (showExamples.length > 0 && showExamples.length <= 3) {
    //   setContainerStyle({height: "5rem", minHeight: "720px"})
    // } else if (showExamples.length > 3 && showExamples.length <= 6) {
    //   setContainerStyle({height: "9rem", minHeight: "1320px"})
    // } else {
    //   setContainerStyle({height: "13rem", minHeight: "2000px"})
    // }
    setContainerStyle({height: toRemAuto((showExamples.length) * (240 + 30) + 250)})
  }, [showExamples.length]);

  const beforeLoadSelect = (type) => {
    switch (type) {
      case "companyInstance":
        btnSelect("2")
        break;
      case "schoolInstance":
        btnSelect("3")
        break;
      case "middleSchoolInstance":
        btnSelect("4")
        break;
      case "factoryInstance":
        btnSelect("5")
        break;
      case "industryInstance":
        btnSelect("6")
        break;
      case "communityInstance":
        btnSelect("7")
        break;
      default:
        btnSelect("1");
        break;
    }
  }


  const bgd = {
    backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/case/banner/solve.png"})`,
  }
  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <div style={{display: "flex", marginRight: "0.2rem", marginTop: "0.05rem"}}>
          <img style={{width: "18px", height: "18px"}}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/case/icon/icon_left_arrow.png"}/>
          <div className={styles.page_arrow}>上一页</div>
        </div>
      )
    }
    if (type === 'next') {
      return (
        <div style={{display: "flex", marginLeft: "0.2rem", marginTop: "0.05rem"}}>
          <img style={{width: "18px", height: "18px"}}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/case/icon/icon_right_arrow.png"}/>
          <div className={styles.page_arrow}>下一页</div>
        </div>
      )
    }
    return originalElement;
  };
  const btnSelect = (type) => {
    let k = -0.0465;
    const b = -47;
    setType(type);
    setPageNum(1);
    window.scrollTo(0, 0);
    switch (type) {
      case "1":  //全部案例
        setShowExamples(dataSourceAllExamples.slice(0, 7));
        setTotalNum(dataSourceAllExamples.length);
        setToLoadExamples(dataSourceAllExamples);
        break;
      case "2":  //企事业单位案例
        setShowExamples(dataSourceBusiness.slice(0, 7));
        setTotalNum(dataSourceBusiness.length);
        setToLoadExamples(dataSourceBusiness);
        break;
      case "3":  //高校案例
        setShowExamples(dataSourceUniversity.slice(0, 7));
        setTotalNum(dataSourceUniversity.length);
        setToLoadExamples(dataSourceUniversity);
        break;
      case "4":  //中小学案例
        setShowExamples(dataSourceSchool.slice(0, 7));
        setTotalNum(dataSourceSchool.length);
        setToLoadExamples(dataSourceSchool);
        break;
      case "5":  //工厂案例
        setShowExamples(dataSourceFactory.slice(0, 7));
        setTotalNum(dataSourceFactory.length);
        break;
      case "6":  //产业园案例
        setShowExamples(dataSourceIndusPark.slice(0, 7));
        setTotalNum(dataSourceIndusPark.length);
        setToLoadExamples(dataSourceIndusPark);
        break;
      case "7":  //社区案例
        setShowExamples(dataSourceCommunity.slice(0, 7));
        setTotalNum(dataSourceCommunity.length);
        setToLoadExamples(dataSourceCommunity);
        break;
    }
    let scrollHeight: any = document.getElementById("cardHead");
    if (document.body.clientWidth < 1400) {
      k = -0.036;
    } else if (document.body.clientWidth > 3000) {
      k = -0.0499
    }
    //y=kx+b
    window.scrollTo({top: scrollHeight.offsetTop + (k * (document.body.clientWidth) + b)});
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setPageNum(page);
    const toShow = toLoadExamples.slice((page - 1) * 7, (page - 1) * 7 + 7);
    setShowExamples(toShow);
  };

  const toShowModalClick = (images) => {
    setShowImages(images);
    setImagesIndex(0);
    setShowCarousel(true);
  }
  const btnTransLeft = () => {
    if (imagesIndex === 0) {
      return;
    }
    setImagesIndex(imagesIndex - 1);
  }
  const btnTransRight = () => {
    if (imagesIndex === showImages.length - 1) {
      return;
    }
    setImagesIndex(imagesIndex + 1);
  }

  return (
    <div style={{display: "flex", flexDirection: "column"}}>
      <Modal width={toRemAuto(1150)} onCancel={() => {
        setShowImages([]);
        setShowCarousel(false)
      }} centered={true} maskClosable={true} footer={null} open={showCarousel}>
        <div className="flex" style={{alignItems: 'center', justifyContent: "center"}}>
          <img style={{
            opacity: imagesIndex !== 0 ? 1 : 0,
            cursor: imagesIndex !== 0 ? 'pointer' : ''
          }}
               onClick={imagesIndex !== 0 ? btnTransLeft : () => {
               }}
               className={styles.modal_arrow}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/hardwareDevice/images/arrow_left.png"}/>
          {showImages.length > 0 &&
              <img className={styles.case_card_image} src={showImages[imagesIndex].image}/>
          }
          <img style={{
            opacity: imagesIndex === showImages.length - 1 ? 0 : 1,
            cursor: imagesIndex === showImages.length - 1 ? '' : 'pointer'
          }}
               onClick={imagesIndex === showImages.length - 1 ? () => {
               } : btnTransRight}
               className={styles.modal_arrow}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/hardwareDevice/images/arrow_right.png"}/>
        </div>
      </Modal>
      <div style={bgd} className="banner-img">
                <span className="banner-title">
                    客户案例
                </span>
        <span className="banner-text">
                蚁窝智能的解决方案和服务覆盖了企事业单位、高校、中小学、工厂、产业园、社区等各类场景。
            </span>
      </div>

      <div className={styles.nav}>
        {dataSourceNavText.map(item => {
          return (
            <span onClick={() => {
              btnSelect(item.type)
            }} style={{color: type === item.type ? '#FFB119' : '#666666'}}
                  className={styles.nav_title}
                  key={item.type}>
                            {item.text}
                        </span>
          )
        })}
      </div>
      <div id={"cardHead"} className={styles.container} style={{...containerStyle, backgroundColor: "#ffffff"}}>
        <Row gutter={[0, 30]}>
          {showExamples.map((item: any, index) => {
            return (
              <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                   xs={24} sm={24}
                   key={index.toString()}>
                <OverPack key={index.toString()} playScale={0.1}>
                  <TweenOneGroup
                    key={"ul"}
                    enter={{
                      x: '+=30',
                      opacity: 0,
                      type: 'from',
                      ease: 'easeInOutQuad',
                    }}
                    leave={{x: '+=30', opacity: 0, ease: 'easeInOutQuad'}}>
                    <div onClick={() => {
                      toShowModalClick(item.carousel)
                    }} style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                         key={index.toString()}>
                      <CaseCard titles={item.titles} text={item.text} imagePath={item.image}/>
                    </div>
                  </TweenOneGroup>
                </OverPack>
              </Col>
            )
          })}
        </Row>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#FFB119",
            }
          }}
        >
          <Pagination onChange={onChange}
                      style={{display: "flex", alignItems: "center", marginTop: "0.2rem", marginBottom: "0.2rem"}}
                      itemRender={itemRender}
                      current={pageNum}
                      pageSize={7}
                      defaultCurrent={1}
                      total={totalNum}/>
        </ConfigProvider>
      </div>
    </div>
  )
}
export default Case;