import React from "react";
import styles from "./YiwoSystem.module.scss";

export const dataSourceCharacteristic = [
  {
    title: "菜品销量分析，科学更新新品",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoSystem/card/sales_analysis.png",
    children: [
      {
        text: "实时了解当前销售情况，通过前端硬件设备收集，获取菜品的销售重量和剩余数量等数据信息，保证餐厅菜品科学出餐，控制浪费。"
      }, {
        text: "分析日周月年的菜品的销量走势，淘汰及开发新品，保障菜品多元化。"
      }
    ]
  }, {
    title: "数据可视化，财务管理轻松便利",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoSystem/card/data_visualization.png",
    children: [
      {
        text: "提供一站式管理服务，同一运营商可以同时管理旗下多家餐厅，效率大幅度提升。"
      }, {
        text: "可随时导出财务报表，数据可视化使财务分析和管理有迹可循，轻松便利。"
      }
    ]
  }, {
    title: "面面俱到，实现餐厅系统化管理",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoSystem/card/icon_yiwo_manage.png",
    children: [
      {
        text: "蚁窝智能后台系统主要功能模块分为四大类，分别是财务管理模块、餐厅管理模块、餐厅运营模块和菜品管理模块，四大模块基本囊括餐厅运营管理所需的功能，运营者能够通过后台全面而系统地管理餐厅。"
      }
    ]
  },
]