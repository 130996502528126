import React from "react";
import styles from "./Specs.module.scss";
import {OverPack} from "rc-scroll-anim/es";
import QueueAnim from "rc-queue-anim/lib/QueueAnim";
import {Col, Row} from "antd";
import {toRemAuto} from "../../utils/utils";

interface propsTypes {
  items: any,
  techTitle?: boolean,
  techTitleColor?: string,
  titleColor?: string,
  textColor?: string,
}

interface IpropsType extends React.FC<propsTypes> {

}

export const Specs: IpropsType = ({
                                    items, techTitle = true,
                                    titleColor = "#333333",
                                    textColor = "#999999",
                                    techTitleColor = "#333333"
                                  }) => {
  return (
    <div className={styles.technology_container}>
      <Row gutter={[48, 42]} justify={"start"}>
        <Col span={24} style={{marginBottom: "0.2rem", display: techTitle ? "flex" : 'none'}}>
          <div style={{color: techTitleColor}} className={styles.technology_title}>技术规格</div>
        </Col>
        {items.map((item: any, index) => {
          return (
            <Col style={{display: "flex", alignItems: "flex-start"}} key={index.toString()}
                 md={8} xs={12}>
              <div className={styles.technology_img}>
                <img src={item.image}/>
              </div>
              <div className={styles.text_container}>
                <div style={{color: titleColor}} className={styles.title}>{item.title}</div>
                <div style={{color: textColor}} className={styles.text}>
                  {item.children.map((child, index) => {
                    return (
                      <div key={index.toString()}>{child.text}</div>
                    )
                  })}
                </div>
              </div>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}