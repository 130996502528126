import Main from "../pages/Main/Main";
import Solution from "../pages/Solution/Solution";
import React from "react";
import Case from "../pages/Case/Case";
import News from "../pages/News/News";
import {NewsDetail} from "../pages/NewsDetail/NewsDetail";
import {Cooperation} from "../pages/Cooperation/Cooperation";
import {Action} from "../pages/Action/Action";
import {About} from "../pages/About/About";
import {BindingDevice} from "../pages/BindingDevice/BindingDevice";
import {ProductCenter} from "../pages/ProductCenter/ProductCenter";
import {IntelligenceAccessory} from "../pages/IntelligenceAccessory/IntelligenceAccessory";
import {Pos} from "../pages/Pos/Pos";
import {Error} from "../pages/Error/Error";
import {YiwoApp} from "../pages/YiwoApp/YiwoApp";
import {YiwoSystem} from "../pages/YiwoSystem/YiwoSystem";
import {KitchenSignage} from "../pages/KitchenSignage/KitchenSignage";
import {HardwareDevice} from "../pages/HardwareDevice/HardwareDevice";

export default [
    {
        label: '首页',
        path: '/',
        element: <Main/>,
        isShowNav: true,
    },
    {
        label: '产品中心',
        path: '/product_center',
        element: <ProductCenter/>,
        isShowNav: true,
    },
    {
        label: '绑盘设备',
        path: '/binding_device',
        element: <BindingDevice/>,
        isShowNav: false,
    },
    {
        label: '智能配件',
        path: '/intelligence_accessory',
        element: <IntelligenceAccessory/>,
        isShowNav: false,
    },
    {
        label: '收银设备',
        path: '/pos',
        element: <Pos/>,
        isShowNav: false,
    }, {
        label: '蚁窝AI app',
        path: '/yiwo_app',
        element: <YiwoApp/>,
        isShowNav: false,
    }, {
        label: '蚁窝智能软件系统',
        path: "/yiwo_system",
        element: <YiwoSystem/>,
        isShowNav: false,
    },
    {
        label: '智能后厨看板',
        path: "/kitchen_signage",
        element: <KitchenSignage/>,
        isShowNav: false,
    },
    {
        label: '硬件设备',
        path: "/hardware_device",
        element: <HardwareDevice/>,
        isShowNav: false,
    },
    {
        label: '解决方案',
        path: '/solution',
        element: <Solution/>,
        isShowNav: true,
    },
    {
        label: '项目案例',
        path: '/case',
        element: <Case/>,
        isShowNav: true,
    },
    {
        label: '资讯中心',
        path: '/news',
        element: <News/>,
        isShowNav: true,
    },
    {
        label: '资讯中心详情',
        path: '/news_detail',
        element: <NewsDetail/>,
        isShowNav: false,
    },
    {
        label: '渠道合作',
        path: '/cooperation',
        element: <Cooperation/>,
        isShowNav: true,
    },
    {
        label: '技术支持',
        path: '/action',
        element: <Action/>,
        isShowNav: true,
    },
    {
        label: '关于我们',
        path: '/about',
        element: <About/>,
        isShowNav: true,
    }, {
        label: '网站建设',
        path: '/build',
        element: <Error/>,
        isShowNav: false
    }
];
