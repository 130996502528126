import React from "react";

export const dataSourceFuncCharacter = [
  {
    title: "称重计费",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/hardwareDevice/card/weigh_billing.png",
    children: [
      {
        text: "智能秤感应到菜品重量变化，智能计算减重数据并反馈至智能平板",
      }, {
        text: "智能平板自动计费，无需人工干预，减少人力运营成本",
      }, {
        text: "单菜单价，智能结算，精确到克，避免人为计算差错",
      }, {
        text: "用餐者自助取餐，取多少吃多少，从根源处杜绝浪费"
      },
    ]
  }, {
    title: "无感支付",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/hardwareDevice/card/pay.png",
    children: [
      {
        text: "支持多方式绑盘，就餐完成，极速结算",
      }, {
        text: "无感免密支付，简化就餐流程，无需排队收银",
      }, {
        text: "支付订单随时查询，即刻反馈，明细安全透明",
      },
    ]
  }, {
    title: "营养分析",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/hardwareDevice/card/nutrition_analysis.png",
    children: [
      {
        text: "实时采集订单数据，精准分析营养数据",
      }, {
        text: "用户端生成营养报告，把控能量摄入",
      }, {
        text: "数据跟踪时间跨度广，年月周日统计分析更直观",
      },
    ]
  }, {
    title: "健康指导",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/hardwareDevice/card/health_guide.png",
    children: [
      {
        text: "根据用餐者习惯及需求，提供合理的膳食建议",
      }, {
        text: "当餐信息实时推送，直观展示菜品营养成分和能量",
      }, {
        text: "更有过敏原提醒，保障饮食安全",
      },
    ]
  },
];
export const dataSourceWeighOperation = [
  {
    text: "支付宝/微信扫码绑定托盘"
  }, {
    text: "丰富菜品选择，自主取餐",
  }, {
    text: "单菜单价，智能称重",
  }
];
export const dataSourceFlat = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_screen8_white.png",
    child: [
      {
        text: "8寸高清大屏时尚美观"
      }
    ]
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_light_white.png",
    child: [
      {
        text: "全彩LED灯满足多种提示需要"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_face_white.png",
    child: [
      {
        text: "高清摄像头准确实现人脸识别"
      }
    ],
  },
];
export const dataSourceFlatFunc = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_show_white.png",
    text: "显示菜品名称、单价，本次消费重量与价格，累计消费重量与价格",
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_remind_white.png",
    text: "提醒对该机位进行补菜、换菜操作，可设置菜",
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_warning_white.png",
    text: "接收并处理秤和采集盒上传的信息，并将信息传送至云端服务器",
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_accept_white.png",
    text: "接收并处理秤和采集盒上传的信息，并将信息传送至云端服务器",
  },
];
export const dataSourceFlatTech = [
  {
    title: "型号",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_model_white.png",
    children: [
      {
        text: "V5.0",
      }
    ]
  }, {
    title: "尺寸",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size_white.png",
    children: [
      {
        text: "135*130*302mm",
      }
    ]
  }, {
    title: "重量",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_weight_white.png",
    children: [
      {
        text: "710g",
      }
    ]
  }, {
    title: "材质",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_material_white.png",
    children: [
      {
        text: "ABS",
      }
    ]
  }, {
    title: "屏幕尺寸",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size_white.png",
    children: [
      {
        text: "≥8寸",
      }
    ]
  }, {
    title: "操作系统",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_system_white.png",
    children: [
      {
        text: "Android系统",
      }
    ]
  }, {
    title: "供电供网一体",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_electrity_white.png",
    children: [
      {
        text: "poe",
      }
    ]
  }, {
    title: "音响",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_sound_white.png",
    children: [
      {
        text: "3W",
      }
    ]
  }, {
    title: "外部接口",
    children: [
      {
        text: "USB串口 x3"
      }, {
        text: "Micro USB接口 x1"
      }, {
        text: "RJ45网络接口 x1"
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_interface_white.png"
  },
];
export const dataSourceScale = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_scale_size.png",
    child: [
      {
        text: "秤面大576*388mm的大面积摆放位置"
      }
    ]
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_range_size.png",
    child: [
      {
        text: "量程大"
      }, {
        text: "60kg上限"
      }
    ]
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_accuracy.png",
    child: [
      {
        text: "高精度计量"
      }, {
        text: "误差小于1g"
      }
    ],
  },
];
export const dataSourceScaleFunc = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_blooming.png",
    text: "用于盛放保温炉"
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_count.png",
    text: "计算取菜重量、保温炉中剩余菜的重量",
  }
];
export const dataSourceSaleTech = [
  {
    title: "型号",
    children: [
      {
        text: "V5.0"
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_model_white.png"
  }, {
    title: "尺寸",
    children: [
      {
        text: "576*388*108mml"
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size_white.png"
  }, {
    title: "重量",
    children: [
      {
        text: "12kg"
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_weight_white.png"
  }, {
    title: "材质",
    children: [
      {
        text: "钢化玻璃铝合金"
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_material_white.png"
  }, {
    title: "秤体最大量程",
    children: [
      {
        text: "60kg"
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_scale_surface.png"
  }, {
    title: "外部接口",
    children: [
      {
        text: "USB串口 x1",
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_interface_white.png"
  },
];
export const dataSourceCollectionBox = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_rfid.png",
    child: [
      {
        text: "内置RFID感应天线毫秒级读取速度"
      }
    ],

  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_device_thin.png",
    child: [
      {
        text: "设备轻薄灵便安装简易",
      }
    ],

  }
];
export const dataSourceCollectionBoxFunc = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_tray_mes.png",
    text: "读取托盘信息，并将信息传送给平板"
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_tray_judge.png",
    text: "判断托盘进入或者离开感应区",
  }
];
export const dataSourceCollectionBoxTech = [
  {
    title: "型号",
    children: [
      {
        text: "V5.0",
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_model_white.png"
  }, {
    title: "尺寸",
    children: [
      {
        text: "175*120*10mm 嵌入式",
      },
      {
        text: "394*294*29mm 非嵌入式"
      },
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size_white.png"
  }, {
    title: "重量",
    children: [
      {
        text: "710g嵌入式",
      },
      {
        text: "1.6kg非嵌入式"
      },
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_weight_white.png"
  }, {
    title: "材质",
    children: [
      {
        text: "ABS",
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_material_white.png"
  }, {
    title: "外部接口",
    children: [
      {
        text: "USB-B型串口 x1"
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_interface_white.png"
  },
];
export const dataSourceExample = [
  {
    type: "2",
    titles: [
      {title: "浙江中控技术股份有限公司"}
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/zheJiangzhongkong/image01.png",
    text: "浙江中控技术股份有限公司成立于1999年，是高新技术企业和国家技术创新示范企业。2022年3月，广州蚁窝智能科技有限公司旗下智慧餐饮品牌“蚁窝智慧餐厅”携手浙江中控技术股份有限公司，将内部食堂升级改造为智慧餐饮模式，加速浙江中控技术股份有限公司后勤业务的数字化管理转型和智能化经营升级。",
    carousel: [
      {
        image: 'https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/zheJiangzhongkong/image01.png'
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/zheJiangzhongkong/image02.png"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/zheJiangzhongkong/image03.png"
      }
    ]
  }, {
    type: "2",
    titles: [{title: "惠州市市民服务中心"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image01.jpg",
    text: "为应对职工的就餐需求，惠州市市民服务中心选择与智能团餐模式的领导品牌——“蚁窝智慧餐厅”进行深度合作，通过自助取餐和智能支付结算等功能，让就餐者按需点餐、精准点餐，进而避免粮食的铺张浪费。每天约有1000人次就餐，餐厅每天可供应100多道菜式，通过精密的数据汇总分析，每位职工每天预计用餐重量约为350克，从源头中避免餐厅管理中产生的浪费，提高了餐厅按需购材的能力，切实响应“光盘行动”的号召！",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image01.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image02.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image03.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image04.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image05.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image06.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image07.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image08.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image09.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image10.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image11.JPG"
      }
    ]
  }, {
    type: "2",
    titles: [{title: "惠阳区行政机关"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiyangAdministration/image01.jpg",
    text: "2021年9月，惠阳区行政机关正式引进智能团餐模式的领导品牌——“蚁窝智慧餐厅”，结合信息化管理系统平台及用餐大数据，全面助力惠阳区政府食堂提升就餐品质，帮助食堂降低人力成本，减少就餐差错率，落实并践行光盘行动！惠阳区行政机关智慧餐厅每天能同时容纳1500人就餐，日均接待量接近3000人次，餐厨智慧岛完全替代传统后厨，节省约1/2的后厨空间，6人即可应对1000人就餐。无感支付，无需等待和排队结算，无需排队结算，足足提高3倍的用餐效率。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiyangAdministration/image01.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiyangAdministration/image02.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiyangAdministration/image03.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiyangAdministration/image04.jpg"
      },
    ]
  },
]