import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import styles from "./CaseCard.module.scss";
import enquire from 'enquire-js'

interface propsType {
  titles: Array<object>;
  text: string;
  imagePath: string;
  textColor?: string;
  titleColor?: string;
  border?: boolean;
  backgroundColor?: string;
}

interface IpropsTypes extends React.FC<propsType> {

}

export const CaseCard: IpropsTypes = ({
                                        titles,
                                        text,
                                        imagePath,
                                        textColor = "#999999",
                                        titleColor = "#333333",
                                        // border = true,
                                        // backgroundColor = "#FFFFFF",
                                      }) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    enquire.register("screen and (max-width:1024px)", {
      match: function () {
        setIsMobile(true)
      },
      unmatch: () => {
        setIsMobile(false);
      }
    });
    if (document.documentElement.clientWidth < 1024) {
      setIsMobile(true);
    }
  }, []);
  return (
    <div className={styles.card_container}>
      <Row>
        <Col xs={24} lg={8}>
          <img className={styles.card_img} src={imagePath}/>
        </Col>
        <Col xs={24} lg={16}>
          <div className={styles.card_container_head}>
            <div className={styles.card_container_text}>
              <img className={styles.card_dot} src={"https://assets.yiwoaikeji.com/prod/official-website-assets/case/icon/icon_dot.png"}/>
              <div>
                {isMobile &&
                    <div style={{color: titleColor}}>{titles.map((item: any, index) => {
                      return (
                        <div className={styles.card_container_title} key={index.toString()}>{item.title}</div>
                      )
                    })}
                    </div>
                }
                {
                  !isMobile &&
                    <div style={{color: titleColor}}>{titles.map((item: any, index) => {
                      return (
                        <span className={styles.card_container_title} key={index.toString()}>{item.title}</span>
                      )
                    })}
                    </div>
                }
              </div>
            </div>
            <div className={styles.card_text} style={{color: textColor}}>{text}</div>
          </div>
        </Col>
      </Row>
    </div>
  )

}