import React, {useEffect} from "react";
import styles from "./Cooperation.module.scss";
import {Col, Row} from "antd";
import QueueAnim from "rc-queue-anim/lib/QueueAnim";
import {OverPack} from "rc-scroll-anim";
import {
  dataSourceCertificate,
  dataSourceCooperationData,
  dataSourceCoreAdv,
  dataSourceSteps,
  dataSourceUsers
} from "./dataSource";
import {TweenOneGroup} from "rc-tween-one";
import {useLocation} from "react-router-dom";
import {scrollTo} from "../../utils/utils";
import {assetsUrl} from "../../constants/constants";

export const Cooperation = () => {
  let location = useLocation().state;
  useEffect(() => {
    scrollTo(location);
  }, [])
  return (
    <div>
      <div
        style={{backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/banner/banner.png"})`}}
        className="banner-img">
                <span className="banner-title">
                    市场前景广阔
                </span>
        <span className="banner-text">
                    诚挚招募合作伙伴
                </span>
      </div>
      <div className={styles.market_container}>
        <div id={"market"} className={styles.market_title}>市场前景</div>
        <OverPack playScale={0.2}
                  style={{marginTop: "20px", width: "100%"}}>
          <QueueAnim
            type="bottom"
            key="block"
            component={Row as React.ClassType<any, any, any>}
            componentProps={{gutter: [64, 32]}}
            leaveReverse
          >
            {dataSourceUsers.map((item, index) => {
              return (
                <Col key={index.toString()}
                     style={{display: "flex", justifyContent: "center", flexDirection: "column"}}
                     md={12} xs={24} lg={8}>
                  <img src={item.image}/>
                  <div style={{textAlign: "center"}}>
                    <span className={styles.market_card_num}>{item.number}</span>
                    <span className={styles.market_card_unit}>（家）</span>
                  </div>
                  <div className={styles.market_card_text}>{item.text}</div>
                </Col>
              )
            })}
          </QueueAnim>
        </OverPack>
      </div>
      <div style={{backgroundColor: "#F9F9F9"}} className={styles.core_adv}>
        <div className={styles.core_adv_title}>
          <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_yiwo_head.png"}/>
          <div id={"coreAdv"} className={styles.market_title}>核心优势</div>
          <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/vertical_orange.png"}/>
        </div>
        <div className={styles.core_adv_anim}>
          <OverPack
            playScale={0.2}
            style={{marginTop: "20px", width: "100%"}}>
            <TweenOneGroup
              component={Row as React.ClassType<any, any, any>}
              componentProps={{gutter: [16, 32], justify: "center"}}
              key="ul"
              enter={{
                x: '+=30',
                opacity: 0,
                type: 'from',
                ease: 'easeInOutQuad',
              }}
              leave={{x: '+=30', opacity: 0, ease: 'easeInOutQuad'}}
            >
              {dataSourceCoreAdv.map((item, index) => {
                return (
                  <Col key={index.toString()} className={styles.core_card_col} xs={24}
                       md={6}>
                    <div className={styles.core_adv_card}>
                      <img src={item.image}/>
                      <div className={styles.core_adv_card_text}>{item.text}</div>
                    </div>
                  </Col>
                )
              })}
            </TweenOneGroup>
          </OverPack>
        </div>
      </div>
      <div>
        <img className={styles.mode_img} src={assetsUrl + "public/bck_cooperation_cases.jpg"}/>
      </div>
      <div className={`${styles.mode} ${styles.cp_background_process}`}>
        <div id={"process"} style={{color: "#ffffff"}} className="title-small">合作流程</div>
        <div className={styles.cp_text}>为客户提供一站式全流程服务</div>
        <div className={styles.cp_steps}>
          {dataSourceSteps.map((item, index) => {
            return (
              <div key={index.toString()} className={styles.cp_step_item}>
                <img className={styles.cp_step_item_img} src={item.title}/>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: index === dataSourceSteps.length - 1 ? 'none' : 'center'
                }}>
                  <img className={styles.cp_item_img}
                       src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_circle_null.png"}/>
                  <div style={{display: index === dataSourceSteps.length - 1 ? 'none' : 'flex'}}
                       className={styles.cp_item_dived}/>
                </div>
                <span className={styles.cp_item_text}>{item.text}</span>
              </div>
            )
          })}
        </div>
      </div>
      <div className={`${styles.mode} ${styles.qualification_bck}`}>
        <div id={"enterpriseNews"} className="title-small">企业资质</div>
        <Row style={{marginTop: "0.2rem"}} justify={"center"} gutter={[16, 16]}>
          <Col style={{display: "flex", justifyContent: "center"}} xs={24} lg={10}>
            <img
              src={"https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/image/information_security.png"}/>
          </Col>
          <Col xs={24} lg={14}>
            <Row justify={"center"} gutter={[16, 16]}>
              {dataSourceCertificate.map((item, index) => {
                return (
                  <Col style={{display: "flex", justifyContent: "center"}} key={index.toString()}
                       xs={24} md={12} lg={8}>
                    <img src={item.image}/>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
      </div>
      <div style={{backgroundColor: "#ffffff"}} className={styles.mode}>
        <div id={"partner"} className="title-small">合作伙伴发布</div>
        <div className={styles.cp_container}>
          <img className={styles.cp_china_image}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/image/china_cooperation.png"}/>
          <div className={styles.cp_list_data}>
            {dataSourceCooperationData.map((item, index) => {
              return (
                <div key={index.toString()} style={{marginTop: "0.3rem"}}>
                  <div className="flex">
                    <span className={styles.cp_list_data_item_num}>{item.number}</span>
                    <span className={styles.cp_list_data_item_unit}>{item.unit}</span>
                  </div>
                  <div className={styles.cp_list_data_item_text}>{item.text}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}