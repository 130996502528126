import React, {useEffect} from "react";
import styles from "./About.module.scss";
import {Col, Row} from "antd";
import {TweenOneGroup} from "rc-tween-one";
import {OverPack} from "rc-scroll-anim";
import {dataSourceHonor} from "./dataSource";
import {useLocation} from "react-router-dom";
import {scrollTo} from "../../utils/utils";

export const About = () => {
  const location = useLocation().state;
  useEffect(() => {
    if (location === null) {
      document.body.scrollIntoView();
      return;
    }
    scrollTo(location)
  }, []);
  return (
    <div className="container">
      <div style={{backgroundColor: "#ffffff", position: "relative"}}>
        <div className={styles.banner_img}>
          <div>
            <img className={styles.left_angle}
                 src={"https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/left_angle.png"}/>
            <div className="flex" style={{alignItems: "flex-end"}}>
              <div className={styles.banner_title}>了解蚁窝</div>
              <img className={styles.left_angle}
                   src={"https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/right_angle.png"}/>
            </div>
            <div className={styles.banner_about_title}>ABOUT US</div>
            <div className={`${styles.banner_about_text} text-space-normal`}>了解蚁窝解决餐饮行业痛点&nbsp;&nbsp;实现利润翻倍增长</div>
          </div>
        </div>
        <div className={`${styles.text_container} text-space-normal text-ali-fontR`}>
          <div>
            广州蚁窝智能科技有限公司研发的“蚁窝智能餐饮系统”是国内首创的智慧餐饮新模式——<span className={"text-ali-fontM"}>智能称重无感支付</span>
            。以消费者自主取餐，智能称重自动计费方式颠覆了传统餐饮售卖方式，实现愉悦用餐和光盘行动。“蚁窝智能餐饮系统”具有强大的后台管理系统，通过精准的数据采集和ERP系统，为餐饮企业提供了全面智能管理，是高效、安全、健康、环保、节约的智能餐饮新模式。
          </div>
          <div>
            广州蚁窝智能科技有限公司的开发团队由一群朝气蓬勃锐意进取的年轻人组成，于<span className={"text-ali-fontM"}>2014年</span>
            成功研发了全球首创的“蚂蚁盒子”开门自取式智能售卖机系统。在移动互联、物联网和大数据等领域取得技术突破和沉淀后，蚁窝团队于<span className={"text-ali-fontM"}>2017年</span>
            成功研发了“蚁窝智能餐饮系统”，并迅速得到市场认可和应用，已成功打造了<span className={"text-ali-fontM"}>600多</span>
            个智能团餐项目，涵盖学校、产业园、政府机关、写字楼和工厂企业等多个业态。
          </div>
          <div>蚁窝团队迄今已获得16项软件著作权和8项国家发明专利，期望以雄厚的技术实力与广大餐饮企业携手，共同为产业升级贡献力量。</div>
        </div>
        <div className={styles.summary_container}>
          <div style={{marginRight: "0.3rem"}} className={styles.summary_container_card}>
            <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/icon_mission.png"}/>
            <div className={styles.summary_container_card_title}>使命</div>
            <div className={styles.summary_container_card_dived}/>
            <div>
              <div className={styles.summary_container_card_text}>引领智慧餐饮</div>
              <div className={styles.summary_container_card_text}>创造幸福生活</div>
            </div>
          </div>
          <div style={{marginRight: "0.3rem"}} className={styles.summary_container_card}>
            <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/icon_values.png"}/>
            <div className={styles.summary_container_card_title}>价值观</div>
            <div className={styles.summary_container_card_dived}/>
            <div>
              <div className={styles.summary_container_card_text}>创新 严谨</div>
              <div className={styles.summary_container_card_text}>担当 共赢</div>
            </div>
          </div>
          <div className={styles.summary_container_card}>
            <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/icon_mission.png"}/>
            <div className={styles.summary_container_card_title}>愿景</div>
            <div className={styles.summary_container_card_dived}/>
            <div>
              <div className={styles.summary_container_card_text}>成为智慧餐饮</div>
              <div style={{textAlign: "center"}} className={styles.summary_container_card_text}>科技领导者
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.brand_adv}>
        <div id={"brandAdv"} className={`${styles.brand_adv_title} title-small`}>品牌优势</div>
      </div>
      <div style={{backgroundColor: "#ffffff", width: "100%"}} className="container container_pad">
        <div id={"honor"} className="title-small">荣誉资质</div>
        <OverPack
          className={styles.honor_container}
          playScale={0.2}
          style={{marginTop: "20px", width: "100%"}}>
          <TweenOneGroup
            component={Row as React.ClassType<any, any, any>}
            componentProps={{gutter: [32, 32]}}
            key="ul"
            enter={{
              x: '+=30',
              opacity: 0,
              type: 'from',
              ease: 'easeInOutQuad',
            }}
            leave={{x: '+=30', opacity: 0, ease: 'easeInOutQuad'}}
          >
            {dataSourceHonor.map((item, index) => {
              return (
                <Col xs={24} md={12} lg={6} key={index.toString()}
                     style={{display: "flex", justifyContent: "center"}}>
                  <img src={item.image}/>
                </Col>
              )
            })}
          </TweenOneGroup>
        </OverPack>
      </div>
      <div style={{backgroundColor: "#F9F9F9", width: "100%"}}
           className={`container ${styles.concat_container_pad}`}>
        <div id="concat" className="title-small">联系我们</div>
        {/*<Map/>*/}
        <img className={styles.concat_map}
             src={"https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/map.png"}/>
        <div className={styles.concat_container}>
          <Row gutter={[8, 16]}
               style={{
                 marginTop: "0.3rem",
               }}>
            <Col xs={24} sm={24}>
              <Row justify={"center"} gutter={[16, 16]}>
                <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={12}>
                  <div className={styles.concat}>
                    <img
                      src={"https://assets.yiwoaikeji.com/prod/official-website-assets/action/images/icon_phone.png"}/>
                    <div style={{marginLeft: "0.1rem"}}>
                      <div className={styles.concat_title}>技术热线电话</div>
                      <div className={styles.concat_dived}/>
                      <div className={styles.concat_text}>400-8922-177</div>
                    </div>
                  </div>
                </Col>
                <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={12}>
                  <div className={styles.concat}>
                    <img
                      src={"https://assets.yiwoaikeji.com/prod/official-website-assets/action/images/icon_weChat.png"}/>
                    <div style={{marginLeft: "0.1rem"}}>
                      <div className={styles.concat_title}>网络服务支持</div>
                      <div className={styles.concat_dived}/>
                      <div className={styles.concat_text}>yw17727161065（微信客服）</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col style={{display: "flex", justifyContent: "center"}} xs={24} sm={24}>
              <div className={styles.concat_big}>
                <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/action/images/icon_address.png"}/>
                <div style={{marginLeft: "0.1rem"}}>
                  <div className={styles.concat_title}>总部收件信息</div>
                  <div className={styles.concat_dived_big}/>
                  <div className={styles.concat_text}>地址：广州越秀区广州大道中599号泰兴606室</div>
                  <div className={styles.concat_text}>收件人：陈芳芳&nbsp;&nbsp;联系电话：18922214596</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}