export const dataSourceHonor = [
    {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/honor01.png"
    }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/honor02.png"
    }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/honor03.png"
    }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/honor04.png"
    }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/honor05.png"
    }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/honor06.png"
    }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/honor07.png"
    }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/honor08.png"
    }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/honor09.png"
    }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/about/images/honor10.png"
    },
]