import React, {ReactNode, useEffect, useState} from "react";
import {Banner} from "../../components/Banner/Banner";
import {TwoSoftware} from "../../components/TwoSoftware/TwoSoftware";
import {Col, Row} from "antd";
import styles from "./YiwoSystem.module.scss";
import {IntroduceCard} from "../../components/IntroduceCard/IntroduceCard";
import {dataSourceCharacteristic} from "./dataSource";
import {scrollTo} from "../../utils/utils";
import {OverPack} from "rc-scroll-anim";
import {TweenOneGroup} from "rc-tween-one";

export const YiwoSystem = () => {
  const [selectKey, setSelectKey] = useState("1");
  const [showContainer, setShowContainer] = useState<ReactNode>(<div/>);
  const [width, setWidth] = useState(document.documentElement.clientWidth);
  useEffect(() => {
    selectFinancial();
    scrollTo(null)
    window.addEventListener('resize', () => {
      const width = document.documentElement.clientWidth;
      setWidth(width);
    }, false);
  }, []);

  const selectFinancial = () => {
    setSelectKey("1");
    const show = (
      <div className={styles.service_container_body}>
        <div className={styles.service_container_res_text}>
          支持查看所有餐厅中的交易订单，及订单详情，并可对其进行操作调整；支持查询指定时间段内指定菜品的销售概况；支持查询餐厅的账单记录和内容，可查看每一笔交易订单明细。
        </div>
        <img className={styles.service_container_res_image}
             src={"https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoSystem/card/icon_financial_manage.png"}/>
      </div>
    );
    setShowContainer(show);
  };

  const selectCanteenManage = () => {
    setSelectKey("2");
    const show = (
      <div className={styles.service_container_body}>
        <div className={styles.service_container_res_text}>
          支持查看列表所有餐厅和设备，并对餐厅设备进行编辑管理；支持查看所有餐厅的所有会员或职员信息，并对其进行个别或批量管理；支持拥有“管理员”身份的员工进行后台系统操作。
        </div>
        <img className={styles.service_container_res_image}
             src={"https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoSystem/card/icon_canteen_manage.png"}/>
      </div>
    );
    setShowContainer(show);
  }

  const selectCanteenOperate = () => {
    setSelectKey("3");
    const show = (
      <div className={styles.service_container_body}>
        <div className={styles.service_container_res_text}>
          支持对充值/补贴规则进行管理编辑和查看详情，并对充值/补贴对象进行管理；支持添加促销活动，通过活动向指定用户或会员发放优惠等；支持显示和管理运营商所有消费配置；支持编辑小程序首页的“今日推荐”等内容。
        </div>
        <img className={styles.service_container_res_image}
             src={"https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoSystem/card/icon_canteen_operate.png"}/>
      </div>
    );
    setShowContainer(show);
  }
  const selectDishMange = () => {
    setSelectKey("4");
    const show = (
      <div className={styles.service_container_body}>
        <div className={styles.service_container_res_text}>
          支持对运营商所有餐厅的菜品信息进行编辑，同时支持分配、补菜、添加、删除等管理；支持对外卖配置、菜品、订单进行统计管理；支持显示和管理运营商所有餐厅报餐统计情况。
        </div>
        <img className={styles.service_container_res_image}
             src={"https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoSystem/card/dish_manage.png"}/>
      </div>
    );
    setShowContainer(show);
  }

  return (
    <div>
      <Banner titleColor={"#333333"}
              image={"https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoSystem/banner/banner.png"}
              title={"蚁窝智能软件系统"}
              text={"蚁窝智能软件系统包括智能后台系统以及蚁窝AI APP。云部署管理后台系统（SAAS系统），结合网络技术、RFID技术、大数据技术、AI技术，打造了一个适配于食堂数字化经营的生态环境，与客户端应用的使用方形成完美闭环。"}/>
      <TwoSoftware leftTitleColor={"#FFB119"} rightTitleColor={"#333333"}/>
      <div className={styles.system_card_bck}>
        <Row gutter={[64, 16]}>
          <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={12}>
            <div className={styles.system_card}>
              <img
                src={"https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoSystem/card/icon_yiwo_backend.png"}/>
              <div className={styles.system_card_title}>蚁窝智能后台系统</div>
              <div
                className={`${styles.system_card_text} text-space-normal`}>能够为各类应用场景提供科学智能的“移动互联”信息化整体解决方案，深挖智能管理精髓，掌握智能管理核心，为运营者量身打造效率翻倍、质量上乘的互联网餐饮解决方案。
              </div>
            </div>
          </Col>
          <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={12}>
            <div className={styles.system_card}>
              <img
                src={"https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoSystem/card/icon_yiwo_saas.png"}/>
              <div className={styles.system_card_title}>SaaS系统</div>
              <div
                className={`${styles.system_card_text} text-space-normal`}>
                所应用的云部署管理后台系统，具备营收数据图表化，菜品管理标准化，餐线排布计划化等功能，可视化数据在浏览器一目了然。多种促销活动、企业补贴方案可根据场景需求做出合适选择。
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div style={{backgroundColor: "#FAEFE7"}} className="container container_pad">
        <div style={{color:"#3F1607"}} className="title-small">平台特点</div>
        <div className={styles.platform_character}>
          {dataSourceCharacteristic.map((item: any, index) => {
            return (
              <div key={index.toString()}>
                <OverPack
                  playScale={0.2}>
                  <TweenOneGroup
                    key="ul"
                    enter={{
                      x: index % 2 ? '+=30' : '-=30',
                      opacity: 0,
                      type: 'from',
                      ease: 'easeInOutQuad',
                    }}
                    leave={{x: index % 2 ? '+=30' : '-=30', opacity: 0, ease: 'easeInOutQuad'}}
                  >
                    <div style={{marginTop: "0.1rem"}} key={index.toString()}>
                      <IntroduceCard title={item.title}
                                     image={<img className={styles.characteristic_img} src={item.image}/>}
                                     items={item.children}
                                     isImageLeft={index % 2 !== 0}/>
                    </div>
                  </TweenOneGroup>
                </OverPack>
              </div>
            )
          })}
        </div>
      </div>
      <div style={{backgroundColor: "#F8FAFF"}} className="container container_pad">
        <div style={{color:"#101D3D"}} className="title-small">功能模块</div>
        <div className={styles.service_container}>
          <div className={styles.service_container_nav}>
            <div style={{
              height: "0.4rem"
            }}>
              <div style={{
                fontWeight: selectKey === "1" ? 600 : 500,
                color: selectKey === "1" ? '#101D3D' : '#455278'
              }}
                   onClick={selectFinancial}
                   className={styles.service_container_nav_title}>财务管理
              </div>
              <div style={{display: selectKey === "1" && width > 1100 ? 'block' : 'none'}}
                   className={styles.service_container_item_dived}/>
            </div>
            <div style={{
              height: "0.4rem"
            }}>
              <div style={{
                fontWeight: selectKey === "2" ? 600 : 500,
                color: selectKey === "2" ? '#101D3D' : '#455278'
              }}
                   onClick={selectCanteenManage} className={styles.service_container_nav_title}>餐厅管理
              </div>
              <div style={{display: selectKey === "2" && width > 1100 ? 'block' : 'none'}}
                   className={styles.service_container_item_dived}/>
            </div>
            <div style={{
              height: "0.4rem"
            }}>
              <div style={{
                fontWeight: selectKey === "3" ? 600 : 500,
                color: selectKey === "3" ? '#101D3D' : '#455278'
              }}
                   onClick={selectCanteenOperate}
                   className={styles.service_container_nav_title}>餐厅运营
              </div>
              <div style={{display: selectKey === "3" && width > 1100 ? 'block' : 'none'}}
                   className={styles.service_container_item_dived}/>
            </div>
            <div style={{
              height: "0.4rem"
            }}>
              <div style={{
                fontWeight: selectKey === "4" ? 600 : 500,
                color: selectKey === "4" ? '#101D3D' : '#455278'
              }} onClick={selectDishMange}
                   className={styles.service_container_nav_title}>菜品管理
              </div>
              <div style={{display: selectKey === "4" && width > 1100 ? 'block' : 'none'}}
                   className={styles.service_container_item_dived}/>
            </div>
          </div>
          <div style={{display: width > 576 ? 'flex' : 'none'}}
               className={styles.service_container_nav_dived}/>
          <div>
            {showContainer}
          </div>
        </div>
      </div>
      <img className={styles.page_show_container}
           src={"https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoSystem/backgroundImage/bckground.png"}/>
    </div>
  )
}