import React, {useEffect, useState} from "react";
import styles from "./Banner.module.scss";
import enquire from 'enquire-js'
import {toRemAuto} from "../../utils/utils";

interface propsType {
  title: React.ReactNode,
  text: React.ReactNode,
  image: string,
  titleColor?: string,
  textColor?: string,
  textMargin?: number,
  titleMargin?: number,
  children?: React.ReactNode,
  bannerHeight?: number,
  padLeft?: number,
}

interface IpropsType extends React.FC<propsType> {
}

export const Banner: IpropsType = ({
                                     title, text, image,
                                     titleColor = "#ffffff",
                                     textColor = "#999999",
                                     textMargin = 0,
                                     titleMargin = 0,
                                     children,
                                     bannerHeight = 480,
                                     padLeft = 310
                                   }) => {
  const [margin, setMargin] = useState(textMargin);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    enquire.register("screen and (max-width:576px)", {
      match: function () {
        setMargin(0);
        setIsMobile(true);
      },
      unmatch: () => {
        setMargin(textMargin)
        setIsMobile(false);
      }
    });
  }, []);
  return (
    <div style={{
      backgroundImage: `url(${image})`,
      paddingLeft: toRemAuto(padLeft),
      height: isMobile ? "auto" : toRemAuto(bannerHeight)
    }}
         className={styles.banner_container}>
      <div style={{color: titleColor, marginLeft: toRemAuto(titleMargin)}}
           className={styles.banner_title}>{title}</div>
      <div style={{
        color: textColor,
        marginLeft: margin / 10 + "rem",
        letterSpacing: "3px"
      }}
           className={`${styles.banner_text} text-space-normal`}>{text}</div>
      <div>
        {children}
      </div>
    </div>
  )
}