import React from "react";
import styles from "./YiwoApp.module.scss";

export const dataSourceFunctions = [
  {
    title: "扫码用餐",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoApp/card/scan_dining.png",
    children: [
      {
        text: "使用APP中的扫码点餐功能，绑定餐盘，即可自助取餐，用餐结束，自动结算，无感免密支付。"
      }
    ]
  }, {
    title: "营养大数据",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoApp/card/nutrition_big_data.png",
    children: [
      {
        text: "在个人健康信息界面，输入身高、体重、过敏源等信息，即在营养日记界面中获得相应的体重预警和经典均衡饮食推荐，根据热量的摄入，可获得近7日的膳食报告，其中包含热量摄入分析、供能比分析及每餐的热量摄入环比图等信息。"
      }, {
        text: "分析摄入营养的成分，提供有针对性的膳食指导。",
      }
    ],
  }, {
    title: "外卖点餐",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoApp/card/waiMai.png",
    children: [
      {
        text: "搜索附近的智慧餐厅，选择菜品，选择预计送达的时间，点餐结算，下单成功后，餐厅接收到订单出餐，即等外卖小哥送餐上门。",
      }
    ],
  }, {
    title: "订单查询",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoApp/card/order_query.png",
    children: [
      {
        text: "在订单界面，可以看到账户在所有餐厅的用餐情况，每一顿的消费金额、消费菜品、摄入热量、支付方式等数据一目了然。"
      },
    ],
  },
]