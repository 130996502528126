export const scrollTo = (location) => {
  if (location === null) {
    document.body.scrollIntoView();
    return;
  }
  let getEle: any = document.getElementById(location.local);
  getEle.scrollIntoView();
}
export const randomString = (len) => {
  len = len || 32;
  const t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
    a = t.length;
  let n = "";
  for (let i = 0; i < len; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n
}

export const toRemAuto = (transFormPx) => {
  const designWidth = 192;
  return transFormPx / designWidth + "rem";
}
