import React, {useEffect, useState} from "react";
import {Banner} from "../../components/Banner/Banner";
import {DeviceList} from "../../components/DeviceList/DeviceList";
import {Col, Row} from "antd";
import styles from "./IntelligenceAccessory.module.scss"
import {Process} from "../../components/Process/Process";
import {
  dataSourceBraceletTech, dataSourceCardTech,
  dataSourceFreezerTech,
  dataSourceGuardOperation, dataSourceIslandsTech,
  dataSourceOtherTech,
  dataSourceTrayTech
} from "./dataSource";
import {Specs} from "../../components/Specs/Specs";
import {useLocation} from "react-router-dom";
import {scrollTo, toRemAuto} from "../../utils/utils";
import enquire from 'enquire-js'
import {YiWoVideo} from "../../components/YiWoVideo/YiWoVideo";

export const IntelligenceAccessory = () => {
  const [braceletShow, setBraceletShow] = useState(true);
  const [isCenter, setIsCenter] = useState(false);
  let location = useLocation().state;
  useEffect(() => {
    scrollTo(location);
    enquire.register("screen and (max-width:576px)", {
      match: function () {
        setIsCenter(true)
      },
      unmatch: () => {
        setIsCenter(false)
      }
    });
    return () => {
      enquire.unregister();
    }
  }, []);
  const btnSelectBraceletShow = () => {
    setBraceletShow(true);
  }
  const btnSelectCardShow = () => {
    setBraceletShow(false);
  }
  return (
    <div>
      <Banner image={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/banner/banner.png"}
              title={"蚁窝智能硬件设备"}
              text={"基于多年对传统团餐及传统食堂用餐模式的深度了解，蚁窝技术团队从运营及应用等多维度分析探究，研发出多款应用于智慧餐厅的智能设备，能够有效解决传统食堂的痛点，提升用餐体验。"}/>
      <div id={"intelligenceAccessoryHead"}>
        <DeviceList heightLightKey={"4"}/>
      </div>
      <Row style={{display: "flex", alignItems: "center"}}>
        <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={12}>
          <img className={styles.accessory_card_container_img}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/intelligenceAccessory/images/guard.png"}/>
        </Col>
        <Col id={"protect"} xs={24} md={12}>
          <div className={styles.accessory_card_container}>
            <div className={styles.accessory_card_container_title}>
              蚁窝智能防护罩
            </div>
            <div className={`${styles.accessory_card_container_text} text-space-small`}>
              感应式开合，起防疫卫生作用，防止飞沫传播，保障菜品卫生干净，同时对菜品起到保温及防止偷菜作用。
            </div>
          </div>
        </Col>
      </Row>
      <div className={styles.pad} style={{backgroundColor: "#F9F9F9"}}>
        <YiWoVideo width={"100%"} height={"3.5rem"}
                   poster={"intelligenceAccessory/backgroundImage/bck_operation_bind.png"}
                   videoUrl={"video/yiwoGuard_operation.mp4"}/>
        <div className={styles.operation_container}>
          <Process space={7} items={dataSourceGuardOperation} arrowWidth={96}/>
        </div>
      </div>
      <div
        style={{backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/intelligenceAccessory/backgroundImage/bck_other_accessory.png"})`}}
        className={styles.tech_container}>
        <Specs items={dataSourceOtherTech}/>
      </div>
      <Row style={{display: "flex", alignItems: "center"}}>
        <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={12}>
          <img className={styles.accessory_card_container_img}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/intelligenceAccessory/images/tray.png"}/>
        </Col>
        <Col id={"Tray"} xs={24} md={12}>
          <div className={styles.accessory_card_container}>
            <div className={styles.accessory_card_container_title}>
              蚁窝智能托盘
            </div>
            <div className={`${styles.accessory_card_container_text} text-space-normal`}>
              用于摆放碗碟（碗碟需另外购买），餐盘内嵌入了智能电子标签，用于临时绑定客户用餐消费的免密付费账户。
            </div>
            <div style={{marginTop: "0.3rem", alignItems: "baseline"}} className="flex">
              <div className={styles.accessory_card_container_bottom}>
                <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_fireWall.png"}/>
                <div className={styles.accessory_card_container_bottom_text}>托盘具有耐高温性能食品级材质</div>
              </div>
              <div className={styles.accessory_card_container_bottom} style={{marginLeft: "0.5rem"}}>
                <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_chip.png"}/>
                <div
                  className={styles.accessory_card_container_bottom_text}>RFID芯片采用耐高温材料模内注塑工艺，遇高温不变形
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div
        style={{backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/intelligenceAccessory/backgroundImage/bck_tray_accessory.png"})`}}
        className={styles.tech_container}>
        <Specs items={dataSourceTrayTech}/>
      </div>
      <Row style={{display: "flex", alignItems: "center"}}>
        <Col id={"intelWatchCard"} xs={24} md={12}>
          <div className={styles.accessory_card_container}>
            <div className={styles.accessory_card_container_title}>
              蚁窝智能手环/卡片
            </div>
            <div className={`${styles.accessory_card_container_text} text-space-normal`}>
              蚁窝智能手环和智能卡片适用于不方便使用手机的场景，通过智能手环或智能卡片绑定餐盘后，即可正常取餐，用餐结束后，费用将从与智能手环或智能卡片绑定的微信账号或支付宝账号中自动扣取。
            </div>
            <div style={{marginTop: "0.3rem", alignItems: "baseline"}} className="flex">
              <div className={styles.accessory_card_container_bottom}>
                <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_bracelet.png"}/>
                <div className={styles.accessory_card_container_bottom_text}>手环小巧灵便防丢失</div>
              </div>
              <div className={styles.accessory_card_container_bottom} style={{marginLeft: "0.5rem"}}>
                <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_plane.png"}/>
                <div
                  className={styles.accessory_card_container_bottom_text}>手环/卡片感应敏捷绑盘迅速
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={12}>
          <div
            style={{backgroundImage: `url(${braceletShow ? "https://assets.yiwoaikeji.com/prod/official-website-assets/intelligenceAccessory/images/bck_bracelet.png" : "https://assets.yiwoaikeji.com/prod/official-website-assets/intelligenceAccessory/images/bck_card.png"})`}}
            className={styles.accessory_card_container_img}>
            <div className={styles.accessory_card_container_btn}>
              <div style={{cursor: "pointer"}} onClick={btnSelectBraceletShow}
                   className={braceletShow ? styles.accessory_card_container_btn_active
                     : styles.accessory_card_container_btn_not_active}>
                智能手环
              </div>
              <div
                className={braceletShow ? styles.accessory_card_container_btn_not_active : styles.accessory_card_container_btn_active}
                onClick={btnSelectCardShow}>
                智能卡片
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div style={{
        backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/intelligenceAccessory/backgroundImage/bck_card_bracelet_tech.png"})`,
        paddingLeft: toRemAuto(240),
        paddingRight: toRemAuto(238)
      }}
           className={styles.tech_container}>
        <Row gutter={[0, 16]}>
          <Col style={{display: 'flex', justifyContent: "center"}} xs={24} md={12}>
            <div className={styles.tech_bracelet}>
              <div className={styles.tech_bracelet_title}>技术规格（手环）</div>
              <Row style={{marginTop: "0.2rem", paddingLeft: "0.4rem"}} gutter={[64, 64]}>
                {dataSourceBraceletTech.map((item, index) => {
                  return (
                    <Col style={{
                      display: "flex",
                      justifyContent: isCenter ? 'center' : '',
                      alignItems: "flex-start"
                    }}
                         key={index.toString()}
                         md={12} xs={24}>
                      <img src={item.image}/>
                      <div className={styles.tech_bracelet_card_margin}>
                        <div className={styles.tech_bracelet_card_title}>{item.title}</div>
                        <div className={styles.tech_bracelet_card_text}>{item.children.map((item, index) => {
                          return (
                            <div key={index.toString()}>{item.text}</div>
                          )
                        })}</div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </Col>
          <Col style={{display: 'flex', justifyContent: "center"}} xs={24} md={12}>
            <div className={styles.tech_bracelet}>
              <div className={styles.tech_bracelet_title}>技术规格（卡片）</div>
              <Row style={{marginTop: "0.2rem", paddingLeft: "0.1rem"}} gutter={[32, 64]}>
                {dataSourceCardTech.map((item, index) => {
                  return (
                    <Col style={{
                      display: "flex",
                      justifyContent: isCenter ? 'center' : '',
                      alignItems: "flex-start"
                    }} key={index.toString()}
                         md={12} xs={24} lg={8}>
                      <img src={item.image}/>
                      <div className={styles.tech_bracelet_card_margin}>
                        <div className={styles.tech_bracelet_card_title}>{item.title}</div>
                        <div className={styles.tech_bracelet_card_text}>{item.children.map((item, index) => {
                          return (
                            <div key={index.toString()}>{item.text}</div>
                          )
                        })}</div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <Row style={{display: "flex", alignItems: "center"}}>
        <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={12}>
          <img className={styles.accessory_card_container_img}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/intelligenceAccessory/images/freezer.png"}/>
        </Col>
        <Col id={"freezer"} xs={24} md={12}>
          <div className={styles.accessory_card_container}>
            <div className={styles.accessory_card_container_title}>
              蚁窝智能冰柜
            </div>
            <div className={`${styles.accessory_card_container_text} text-space-normal`}>
              用于放置需要冷藏和保鲜的新鲜食材，温度可智能化控制，始终让食物保持最佳存储状态。内嵌式设计可以更好地保温，减少能源损耗，增加食物储量。
            </div>
            <div style={{marginTop: "0.3rem", alignItems: "baseline"}} className="flex">
              <div className={styles.accessory_card_container_bottom}>
                <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_high_capacity.png"}/>
                <div className={styles.accessory_card_container_bottom_text}>内嵌式，大容量干净节能</div>
              </div>
              <div className={styles.accessory_card_container_bottom} style={{marginLeft: "0.5rem"}}>
                <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_fresh.png"}/>
                <div
                  className={styles.accessory_card_container_bottom_text}>
                  保鲜效果出众快速制冷可迅速锁住食物营养成分
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div
        style={{backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/intelligenceAccessory/backgroundImage/bck_freezer_tech.png"})`}}
        className={styles.tech_container}>
        <Specs items={dataSourceFreezerTech}/>
      </div>
      <Row style={{display: "flex", alignItems: "center"}}>
        <Col id={"garden"} xs={24} md={12}>
          <div className={styles.accessory_card_container}>
            <div className={styles.accessory_card_container_title}>
              蚁窝智能岛屿
            </div>
            <div className={`${styles.accessory_card_container_text} text-space-normal`}>
              用于摆放智能餐饮设备，同时通过合理地规划动线从而达到引导人流、分散人流的效果。
            </div>
            <div style={{marginTop: "0.3rem", alignItems: "baseline"}} className="flex">
              <div className={styles.accessory_card_container_bottom}>
                <img
                  src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_plane_and_design.png"}/>
                <div className={styles.accessory_card_container_bottom_text}>可根据餐厅需求调整样式匹配餐厅规划与设计</div>
              </div>
              <div className={styles.accessory_card_container_bottom} style={{marginLeft: "0.5rem"}}>
                <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_guide_people.png"}/>
                <div
                  className={styles.accessory_card_container_bottom_text}>
                  人性化规划引导人流动线，减少拥堵
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={12}>
          <img className={styles.accessory_card_container_img}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/intelligenceAccessory/images/islands.png"}/>
        </Col>
      </Row>
      <div
        style={{backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/intelligenceAccessory/backgroundImage/bck_islands_tech.png"})`}}
        className={styles.tech_container}>
        <Specs items={dataSourceIslandsTech}/>
      </div>
    </div>
  )
}