export const dataSourceNav = [
    {
        title: "产品中心",
        path: "/product_center",
        children: [
            {
                text: "蚁窝智能设备",
                path: "/intelligence_accessory",
            }, {
                text: "蚁窝软件系统",
                path: "yiwo_system",
            }, {
                text: "产品优势",
                state: {
                    local: "proAdv"
                }
            }, {
                text: "食堂对比",
                state: {
                    local: "contrast"
                }
            }
        ]
    }, {
        title: "解决方案",
        path: "/solution",
        children: [
            {
                text: "行业痛点",
                state: {
                    local: "painSpot"
                }
            }, {
                text: "自选快餐模式",
                state: {
                    local: "selfSelect"
                }
            }, {
                text: "档口售餐模式",
                state: {
                    local: "doorSale"
                }
            }, {
                text: "方案价值",
                state: {
                    local: "solutionValue"
                }
            }
        ]
    }, {
        title: "项目案例",
        path: "/case",
        children: [
            {
                text: "企事业单位实例",
                state: {
                    local: "companyInstance"
                }
            }, {
                text: "高校实例",
                state: {
                    local: "schoolInstance"
                }
            }, {
                text: "中小学实例",
                state: {
                    local: "middleSchoolInstance"
                }
            }, {
                text: "工厂实例",
                state: {
                    local: "factoryInstance"
                }
            }, {
                text: "产业园实例",
                state: {
                    local: "industryInstance"
                }
            }, {
                text: "社区实例",
                state: {
                    local: "communityInstance"
                }
            }
        ]
    }, {
        title: "渠道合作",
        path: "/cooperation",
        children: [
            {
                text: "市场前景",
                state: {
                    local: "market"
                }
            }, {
                text: "核心优势",
                state: {
                    local: "coreAdv"
                }
            }, {
                text: "合作伙伴",
                state: {
                    local: "partner"
                }
            }, {
                text: "合作流程",
                state: {
                    local: "process"
                }
            }, {
                text: "企业资讯",
                state: {
                    local: "enterpriseNews"
                }
            }
        ]
    }, {
        title: "技术支持",
        path: "/action",
        children: [
            {
                text: "售后支持",
                state: {
                    local: "afterSale"
                }
            },
            {
                text: "下载中心",
                // state: {
                //     local: "download"
                // }
            }
            ,
            {
                text: "常见问题",
                // state: {
                //     local: "question"
                // }
            }
        ]
    },
    {
        title: "关于我们",
        path: "/about",
        children: [
            {
                text: "公司简介"
            }, {
                text: "企业文化"
            }, {
                text: "品牌优势",
                state: {
                    local: "brandAdv"
                }
            }, {
                text: "荣誉资质",
                state: {
                    local: "honor"
                }
            }, {
                text: "联系我们",
                state: {
                    local: "concat"
                }
            }
        ]
    }
]