//我的去充值后返回

export class CaseListener {
    static eventName = 'CaseListener'
    caseType;

    constructor(caseType) {
        this.caseType = caseType;
    }
}
