import styles from "./Process.module.scss";
import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import enquire from 'enquire-js'
import {toRemAuto} from "../../utils/utils";

interface propsTypes {
    items: Array<object>;
    arrowWidth: number,
    space: number,
    titleColor?: string,
    textColor?: string,
}

interface IpropsTypes extends React.FC<propsTypes> {

}

export const Process: IpropsTypes = ({
                                         items, arrowWidth, space, titleColor = "#333333",
                                         textColor = "#666666"
                                     }) => {
    const [isShow, setIsShow] = useState(true);
    useEffect(() => {
        enquire.register("screen and (max-width:900px)", {
            match: function () {
                setIsShow(false)
            },
            unmatch: () => {
                setIsShow(true)
            }
        });
    }, []);
    return (
        <div>
            <div className={styles.title_container}>
                <div className={styles.vertical_line}/>
                <div style={{color: titleColor}} className={styles.title}>操作流程</div>
            </div>
            <div className={styles.step}>
                <Row gutter={[0, 16]} style={{display: "flex"}} justify={"space-between"}>
                    {items.map((item: any, index) => {
                        return (
                            <Col xs={24} md={space} key={index.toString()}
                                 style={{
                                     display: "flex",
                                     alignItems: "center",
                                 }}>
                                <div className={styles.round}>
                                    <span>{index + 1}</span>
                                </div>
                                <div style={{color: textColor}} className={styles.step_text}>
                                    {item.text}
                                </div>
                                <img
                                    className={styles.arrow}
                                    style={{
                                        display: index !== items.length - 1 && isShow ? 'block' : 'none',
                                        width: toRemAuto(arrowWidth),
                                    }}
                                    src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_process_arrow.png"}/>
                            </Col>
                        )
                    })}
                </Row>

            </div>
        </div>
    )
}