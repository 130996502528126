import styles from "./MainBanner.module.scss";
import BannerAnim, {Element} from "rc-banner-anim";
import React from "react";
import {useNavigate} from "react-router-dom";
import {Carousel} from "antd";
import {toRemAuto} from "../../utils/utils";

export const MainBanner = () => {
  const BgElement = Element.BgElement;
  const navigate = useNavigate();
  const toConcatUs = () => {
    navigate("/about", {
      state: {
        local: "concat"
      },
    })
  }
  return (
    <div>
      <Carousel dots={false} autoplaySpeed={3000} autoplay={true}>
        <div
          className={styles.banner_img}>
          <div className={styles.banner_img_text}>蚁窝智能智慧餐饮综合服务商</div>
          <img className={styles.banner_img_title}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/banner/solution01_title.png"}/>
          <div className={styles.banner_img_mode}>
            <div className="flex" style={{alignItems: "center"}}>
              <div>扫码取餐</div>
              <img style={{marginLeft: "0.1rem"}}
                   src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_Columns.png"}/>
            </div>
            <div className="flex" style={{alignItems: "center"}}>
              <div>一菜一价</div>
              <img style={{marginLeft: "0.1rem"}}
                   src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_Columns.png"}/>
            </div>
            <div className="flex" style={{alignItems: "center"}}>
              <div>无感支付</div>
              <img style={{marginLeft: "0.1rem"}}
                   src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_Columns.png"}/>
            </div>
            <div className="flex" style={{alignItems: "center"}}>
              <div>营养报告</div>
              <img style={{marginLeft: "0.1rem"}}
                   src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_Columns.png"}/>
            </div>
            <div className="flex" style={{alignItems: "center"}}>
              <div>离线模式</div>
            </div>
          </div>
          <div style={{marginLeft:toRemAuto(70)}} onClick={toConcatUs} className={styles.banner_img_btn}>
            <div>联系我们</div>
            <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/banner/icon_arrow.png"}/>
          </div>
        </div>
        <div
          style={{backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/main/banner/solution02.png"})`}}
          className={styles.banner_img}>
          <img className={styles.banner_science_line}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/banner/science_line.png"}/>
          <img className={styles.banner_solution02_title}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/banner/solution02_title.png"}/>

          <div className={styles.banner_rectangle_big}>
            <div className="flex" style={{alignItems: "center"}}>
              <div>扫码绑盘</div>
              <img style={{marginLeft: "0.1rem"}}
                   src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_arrow02.png"}/>
            </div>
            <div className="flex" style={{alignItems: "center"}}>
              <div>一菜一价</div>
              <img style={{marginLeft: "0.1rem"}}
                   src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_arrow02.png"}/>
            </div>
            <div className="flex" style={{alignItems: "center"}}>
              <div>无感支付</div>
            </div>
          </div>
          <div className={styles.banner_img_btn}>
            <div onClick={toConcatUs}>联系我们</div>
            <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/banner/icon_arrow.png"}/>
          </div>
        </div>
        <div
          style={{backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/main/banner/solution03.png"})`}}
          className={styles.banner_img}>
          <img className={styles.banner_solution03_image}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/text_container.png"}/>
        </div>
      </Carousel>
      {/*<BannerAnim arrow={false} autoPlaySpeed={3000} autoPlay={true} thumb={false} prefixCls={styles.banner_user}>*/}
      {/*  <Element*/}
      {/*    prefixCls={styles.banner_user_elem}*/}
      {/*    key="0"*/}
      {/*  >*/}
      {/*    <BgElement*/}
      {/*      key="bg"*/}
      {/*      className={`bg`}*/}
      {/*    />*/}
      {/*    */}
      {/*  </Element>*/}
      {/*  <Element*/}
      {/*    prefixCls={styles.banner_user_elem}*/}
      {/*    key="1"*/}
      {/*  >*/}
      {/*    <BgElement*/}
      {/*      key="bg"*/}
      {/*      className="bg"*/}
      {/*    />*/}
      {/*    */}
      {/*  </Element>*/}
      {/*  <Element*/}
      {/*    prefixCls={styles.banner_user_elem}*/}
      {/*    key="2"*/}
      {/*  >*/}
      {/*    <BgElement*/}
      {/*      key="bg"*/}
      {/*      className={`bg`}*/}
      {/*      style={{*/}
      {/*        backgroundColor: "#364D79",*/}
      {/*      }}*/}
      {/*    />*/}
      {/*    <div*/}
      {/*      style={{backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/main/banner/solution03.png"})`}}*/}
      {/*      className={styles.banner_img}>*/}
      {/*      <img className={styles.banner_solution03_image}*/}
      {/*           src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/text_container.png"}/>*/}
      {/*    </div>*/}
      {/*  </Element>*/}
      {/*</BannerAnim>*/}
    </div>
  )
}