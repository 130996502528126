import React, {useEffect} from "react";
import mainOutSideStyles from "../../components/MainBanner/MainBanner.module.scss";
import styles from "./ProductCenter.module.scss";
import {Col, Row} from "antd";
import {dataSourceIntelCanteen, dataSourceTraditionCanteen} from "./dataSource";
import {DeviceList} from "../../components/DeviceList/DeviceList";
import {useLocation, useNavigate} from "react-router-dom";
import {TwoSoftware} from "../../components/TwoSoftware/TwoSoftware";
import {scrollTo, toRemAuto} from "../../utils/utils";
import {Banner} from "../../components/Banner/Banner";

export const ProductCenter = () => {
  let location = useLocation().state;
  useEffect(() => {
    scrollTo(location);
  }, [])
  const navigate = useNavigate();
  const toConcatUs = () => {
    navigate("/about", {
      state: {
        local: "concat"
      }
    })
  }
  return (
    <div>
      <div
        style={{backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/main/banner/solution02.png"})`}}
        className={mainOutSideStyles.banner_img}>
        <img className={mainOutSideStyles.banner_science_line}
             src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/banner/science_line.png"}/>
        <img className={mainOutSideStyles.banner_solution02_title}
             src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/banner/solution02_title.png"}/>

        <div className={mainOutSideStyles.banner_rectangle_big}>
          <div className="flex" style={{alignItems: "center"}}>
            <div>扫码绑盘</div>
            <img style={{marginLeft: "0.1rem"}}
                 src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_arrow02.png"}/>
          </div>
          <div className="flex" style={{alignItems: "center"}}>
            <div>一菜一价</div>
            <img style={{marginLeft: "0.1rem"}}
                 src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/images/icon_arrow02.png"}/>
          </div>
          <div className="flex" style={{alignItems: "center"}}>
            <div>无感支付</div>
          </div>
        </div>
        <div onClick={toConcatUs} className={mainOutSideStyles.banner_img_btn}>
          <div>联系我们</div>
          <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/banner/icon_arrow.png"}/>
        </div>
      </div>
      <Banner
        titleColor={"#333333"}
        textColor={"#666666"}
        title={"蚁窝智能硬件设备"}
        bannerHeight={366}
        text={"基于多年对传统团餐及传统食堂用餐模式的深度了解，蚁窝技术团队从运营及应用等多维度分析探究，研发出多款应用于智慧餐厅的智能设备，能够有效解决传统食堂的痛点，提升用餐体验。"}
        image={"https://assets.yiwoaikeji.com/prod/official-website-assets/productCenter/backgroundImage/bck_yiwoAiDevice01.png"}/>
      <DeviceList/>
      <div
        style={{backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/productCenter/backgroundImage/bck_yiwoAiDevice02.png"})`}}
        className={styles.device_bck}>
        <div className={styles.device_bck_title}>蚁窝智能软件设备</div>
        <div className={styles.device_bck_text}>
          蚁窝智能软件系统包括智能后台系统以及蚁窝AI APP。 云部署管理后台系统（SAAS系统），结合网络技术、RFID技术、大数据技术、AI技术，打造了一个适配于食堂数字化经营的生态环境，与客户端应用的使用方形成完美闭环。
        </div>
      </div>
      <TwoSoftware leftTitleColor={"#333333"} rightTitleColor={"#333333"}/>
      <img className={styles.adv_container}
           src={"https://assets.yiwoaikeji.com/prod/official-website-assets/productCenter/backgroundImage/icon_product_adv.png"}/>
      <div style={{backgroundColor: "#ffffff"}} className="container container_pad">
        <div id={"contrast"} className="title-small">食堂对比</div>
        <Row justify={"center"} style={{marginTop: "0.4rem"}}>
          <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={12}>
            <div className={styles.contrast_intel}>
              <div style={{color: "#ffffff"}} className={styles.contrast_title}>智慧食堂</div>
              {dataSourceIntelCanteen.map((item, index) => {
                return (
                  <div key={index.toString()}>
                    <div style={{color: "#ffffff"}}
                         className={`${styles.contrast_text} text-space-small text-ali-fontM`}>{item.text}</div>
                    <div style={{display: index <= 3 ? 'block' : 'none', marginTop: toRemAuto(18)}}
                         className={styles.contrast_divider}/>
                  </div>
                )
              })}
              <img className="image-auto" style={{marginTop: "0.1rem"}}
                   src={"https://assets.yiwoaikeji.com/prod/official-website-assets/productCenter/card/smartCanteen.png"}/>
            </div>
          </Col>
          <Col xs={24} md={2}>
            <div className={styles.contrast_vs}>
              VS
            </div>
          </Col>
          <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={10}>
            <div className={styles.contrast_traditional}>
              <div style={{color: "#333333"}} className={styles.contrast_title}>传统食堂</div>
              {dataSourceTraditionCanteen.map((item, index) => {
                return (
                  <div key={index.toString()}>
                    <div style={{color: "#666666"}}
                         className={`${styles.contrast_text} text-space-small text-ali-fontR`}>{item.text}</div>
                    <div style={{display: index <= 3 ? 'block' : 'none', borderColor: "#DBDFE7"}}
                         className={styles.contrast_divider}/>
                  </div>
                )
              })}
              <img className={styles.contrast_traditional_img}
                   src={"https://assets.yiwoaikeji.com/prod/official-website-assets/productCenter/card/traditionalCanteen.png"}/>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}