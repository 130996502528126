import styles from './Solution.module.scss';
import {Card, Col, Row} from "antd";
import React, {useEffect} from "react";
import {OverPack} from "rc-scroll-anim";
import QueueAnim from "rc-queue-anim/lib/QueueAnim";
import 'rc-banner-anim/assets/index.css';
import {TweenOneGroup} from "rc-tween-one";
import {
  dataSourceAdv,
  dataSourcePainPoints,
  dataSourceSteps,
  dataSourceModeAdv,
  dataSourcePlanValue, dataSourceGatePro, dataSourceBgd
} from "./dataSource";
import {useLocation} from "react-router-dom";
import {scrollTo, toRemAuto} from "../../utils/utils";
import {AntdCard} from "../../components/AntdCard/AntdCard";
import {dataSourceCusExample} from "../Main/dataSource";
import {assetsUrl} from "../../constants/constants";


const Solution = () => {
  let location = useLocation().state;
  useEffect(() => {
    scrollTo(location);
  }, [])
  return (
    <div style={{display: "flex", flexDirection: "column"}}>
      <div
        style={{backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/solution/banner/banner.png"})`}}
        className="banner-img">
                <span className="banner-title">
                    蚁窝智能餐饮综合解决方案
                </span>
        <span className="banner-text">
                    解决行业痛点，实现利润翻倍增长
                </span>
      </div>
      <div style={{backgroundColor: "#ffffff"}} className={`container ${styles.pain_ped}`}>
                <span id={"painSpot"} className="title-small">
                    行业痛点
                </span>
        <OverPack playScale={0.2}
                  style={{marginTop: "30px", width: "100%"}}>
          <QueueAnim
            type="bottom"
            key="block"
            component={Row as React.ClassType<any, any, any>}
            componentProps={{gutter: [16, 32]}}
            leaveReverse
          >
            {dataSourcePainPoints.map((item, index) => {
              return (
                <Col key={index.toString()} className={styles.pain_card}
                     xs={12}
                     md={8}>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <img src={item.image}/>
                  </div>
                  <div style={{textAlign: "center"}} className="title-tiny text-ali-fontB">{item.title}</div>
                  <div style={{textAlign: "center", lineHeight: "30px"}}
                       className="text-small text-space-normal text-ali-fontR">{item.text}</div>
                </Col>
              )
            })}
          </QueueAnim>
        </OverPack>
      </div>
      <div style={{background: "#FFFBF3"}} className={styles.title_bck}>
        <span id={"selfSelect"} className={styles.big_title_orange}>“自选”快餐模式</span>
      </div>
      <div style={{background: "#ffffff"}} className={`container ${styles.optional_pad}`}>
        <div style={{display: "flex", alignItems: "center"}}>
          <span style={{marginLeft: toRemAuto(180)}} className="title-small">模式优势</span>
          <img style={{marginLeft: "0.1rem", height: toRemAuto(17)}}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/vertical_orange.png"}/>
        </div>
        <div className={styles.adv_container}>
          <OverPack
            playScale={0.05}
            style={{marginTop: "20px", width: "100%"}}>
            <TweenOneGroup
              component={Row as React.ClassType<any, any, any>}
              componentProps={{gutter: [16, 16], justify: "center"}}
              key="ul"
              enter={{
                x: '+=30',
                opacity: 0,
                type: 'from',
                ease: 'easeInOutQuad',
              }}
              leave={{x: '+=30', opacity: 0, ease: 'easeInOutQuad'}}
            >
              {dataSourceAdv.map((item, index) => {
                return (
                  <Col style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}
                       key={index.toString()}
                       className={styles.pain_card}
                       xs={12}
                       xl={4}>
                    <div style={{display: "flex", justifyContent: "center"}}>
                      <img className={styles.mode_adv_img} src={item.image}/>
                    </div>
                    <div style={{textAlign: "center"}} className="title-tiny">{item.title}</div>
                    <div className="text-space-normal text-line-small"
                         style={{width: "160px", textAlign: "center", color: "#666666"}}>{item.text}</div>
                  </Col>
                )
              })}
            </TweenOneGroup>
          </OverPack>
        </div>
        <div style={{display: "flex", marginTop: "0.4rem", alignItems: "center"}}>
          <span style={{marginLeft: toRemAuto(180)}} className="title-small">用餐流程</span>
          <img style={{marginLeft: "0.1rem", height: toRemAuto(17)}}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/vertical_orange.png"}/>
        </div>
        <div style={{display: "flex", justifyContent: "center"}} className={styles.adv_container}>
          <OverPack
            playScale={0.1}>
            <TweenOneGroup
              component={Row as React.ClassType<any, any, any>}
              componentProps={{gutter: [16, 16], justify: "center"}}
              key="ul"
              enter={{
                x: '-=30',
                opacity: 0,
                type: 'from',
                ease: 'easeInOutQuad',
              }}
              leave={{x: '-=30', opacity: 0, ease: 'easeInOutQuad'}}
            >
              {dataSourceSteps.map((item, index) => {
                return (
                  <div key={index.toString()} style={{display: "flex", flexDirection: "column"}}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                      <img className={styles.step_img} src={item.image}/>
                      <img src={assetsUrl + "solution/images/icon_step_line.png"}
                           style={{display: index === dataSourceSteps.length - 1 ? 'none' : 'block'}}
                           className={styles.line}/>
                    </div>
                    <div style={{display: "flex", marginLeft: toRemAuto(-15)}}>
                      <img style={{
                        width: toRemAuto(10),
                        minWidth: 10,
                        minHeight: 10,
                        height: toRemAuto(10),
                        marginTop: toRemAuto(35)
                      }} src={assetsUrl + "solution/images/icon_step_dot.png"}/>
                      <div className={styles.step_text}>{item.text}</div>
                    </div>
                  </div>
                )
              })}
            </TweenOneGroup>
          </OverPack>
        </div>
      </div>
      <div style={{background: "#FFFBF3"}} className={styles.title_bck}>
        <span id={"doorSale"} className={styles.big_title_orange}>“档口”售餐模式</span>
      </div>
      <div style={{background: "#ffffff"}} className={`container ${styles.optional_pad}`}>
        <div style={{display: "flex", alignItems: "center"}}>
          <span style={{marginLeft: toRemAuto(180)}} className="title-small">模式优势</span>
          <img style={{marginLeft: "0.1rem", height: toRemAuto(17)}}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/vertical_orange.png"}/>
        </div>
        <div className={styles.stall_adv}>
          <OverPack
            style={{marginTop: "0.5rem"}}
            playScale={0}>
            <TweenOneGroup
              component={Row as React.ClassType<any, any, any>}
              componentProps={{gutter: [16, 16], justify: "center"}}
              key="ul"
              enter={{
                x: '+=30',
                opacity: 0,
                type: 'from',
                ease: 'easeInOutQuad',
              }}
              leave={{x: '+=30', opacity: 0, ease: 'easeInOutQuad'}}
            >
              {dataSourceModeAdv.map((item, index) => {
                return (
                  <Col key={index.toString()} xs={24} md={12}>
                    <div className={styles.mode_adv_card}>
                      <img src={item.image}/>
                      <span style={{textAlign: "center"}} className="title-tiny text-ali-fontB">{item.title}</span>
                      <span style={{textAlign: "center"}}
                            className="text-small text-space-small text-line-normal text-ali-fontR">{item.text}</span>
                    </div>
                  </Col>
                )
              })}
            </TweenOneGroup>
          </OverPack>
        </div>
        <div style={{display: "flex", marginTop: "0.5rem", alignItems: "center"}}>
          <span style={{marginLeft: toRemAuto(180)}} className="title-small">用餐流程</span>
          <img style={{marginLeft: "0.1rem", height: toRemAuto(17)}}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/vertical_orange.png"}/>
        </div>
        <div className={styles.meal_process}>
          <OverPack
            style={{marginTop: "0.5rem"}}
            playScale={0}>
            <TweenOneGroup
              component={Row as React.ClassType<any, any, any>}
              componentProps={{gutter: [64, 0], justify: "center"}}
              key="ul"
              enter={{
                y: '+=30',
                opacity: 0,
                type: 'from',
                ease: 'easeInOutQuad',
              }}
              leave={{y: '+=30', opacity: 0, ease: 'easeInOutQuad'}}
            >
              {dataSourceGatePro.map((item, index) => {
                return (
                  <Col style={{display: "flex", justifyContent: "center"}} key={index.toString()}
                       xs={24} sm={12}>
                    <div style={{backgroundImage: `url(${dataSourceBgd[index].backgroundImage})`}}
                         className={styles.gate_step_bck}>
                      <img className={styles.gate_step_img} src={item.image}/>
                      <div style={{marginLeft: "0.3rem"}}
                           className="title-tiny">{item.titles.map((item, index) => {
                        return (
                          <div key={index.toString()}>{item.title}</div>
                        )
                      })}</div>
                    </div>
                  </Col>
                )
              })}
            </TweenOneGroup>
          </OverPack>
        </div>
      </div>
      <div style={{background: "#F9F9F9"}} className={`container ${styles.solution_value}`}>
        <span id={"solutionValue"} className="title-small">方案价值</span>
        <span style={{color: "#999999", marginTop: "0.1rem"}}
              className="text-small text-space-normal">餐饮业与数字化智慧经营的双向融合，结合多年团餐信息化研发经验，为实体餐饮打造线上线下闭环，铸就高品质智慧餐饮标杆</span>
        <OverPack playScale={0.1}
                  style={{marginTop: "0.5rem", width: "100%"}}>
          <QueueAnim
            type="bottom"
            key="block"
            component={Row as React.ClassType<any, any, any>}
            componentProps={{gutter: [36, 32], justify: "center"}}
            leaveReverse
          >
            {dataSourcePlanValue.map((item, index) => {
              return (
                <Col style={{display: "flex", justifyContent: "center"}} key={index.toString()}
                     xs={12}
                     md={6}>
                  <div className={styles.program_value_card}>
                    <img src={item.image}/>
                    <div className="title-tiny">{item.title}</div>
                  </div>
                </Col>
              )
            })}
          </QueueAnim>
        </OverPack>
      </div>
      <div style={{background: "#ffffff"}} className={`container ${styles.classic_case}`}>
        <span className="title-small">经典案例</span>
        <OverPack playScale={0.1}
                  style={{marginTop: "0.5rem", width: "100%"}}>
          <TweenOneGroup
            component={Row as React.ClassType<any, any, any>}
            componentProps={{gutter: [32, 16], justify: "center"}}
            key="ul"
            enter={{
              y: '+=30',
              opacity: 0,
              type: 'from',
              ease: 'easeInOutQuad',
            }}
            leave={{y: '+=30', opacity: 0, ease: 'easeInOutQuad'}}
          >
            {dataSourceCusExample.map((item, index) => {
              return (
                <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                     key={index.toString()} md={12} lg={8} xs={24} sm={12} xl={8}>
                  <AntdCard titles={item.titles} text={item.text} imagePath={item.image}/>
                </Col>
              )
            })}
          </TweenOneGroup>
        </OverPack>
      </div>
    </div>
  )
}
export default Solution;