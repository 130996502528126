import React from "react";

export const dataSourceTech = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size.png",
    title: "尺寸",
    children: [
      {
        text: "1226.4*765.7*260.4mm",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_weight.png",
    title: "重量",
    children: [
      {
        text: "11kg（含底座）",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_resolution_ratio.png",
    title: "分辨率",
    children: [
      {
        text: "3840 x 2160",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_memory2.png",
    title: "内存",
    children: [
      {
        text: "1.5GB",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_cpu.png",
    title: "CPU",
    children: [
      {
        text: "四核64位处理器",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_wireless.png",
    title: "无线配置",
    children: [
      {
        text: "WIFI、红外、蓝牙",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_ electric_tension.png",
    title: "电压",
    children: [
      {
        text: "220V～50/60Hz",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_power2.png",
    title: "功率",
    children: [
      {
        text: "130W",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_interface.png",
    title: "外部接口",
    children: [
      {
        text: "HDMI x2"
      }, {
        text: "AV x1"
      }, {
        text: "ATV/DTMB x1"
      }, {
        text: "S/PDIF x1"
      }, {
        text: "USB x2"
      }, {
        text: "以太网 x1"
      }
    ],
  },
]