export const dataSourceIntelCanteen = [
    {
        text: "菜式丰富，自主取餐，顾客体验较好"
    }, {
        text: "按需取菜，光盘行动，杜绝浪费"
    }, {
        text: "全智能化安全管理标准，人工成本大大降低"
    }, {
        text: "用餐数据实时健康，顾客健康饮食管理推荐"
    }, {
        text: "满意度高"
    }, {
        text: "光盘行动"
    }, {
        text: "智能标准管理"
    }
];
export const dataSourceTraditionCanteen = [
    {
        text: "卖方主导消费，顾客满意度不高"
    }, {
        text: "定量取餐，厨余浪费较多"
    }, {
        text: "需要员工打餐，人力成本较高"
    }, {
        text: "无法评估顾客喜好，给予顾客合理的健康膳食推荐"
    }, {
        text: "满意度低"
    }, {
        text: "浪费率高"
    }, {
        text: "经营成本上涨"
    }
]