import {Banner} from "../../components/Banner/Banner";
import React, {useEffect, useState} from "react";
import {DeviceList} from "../../components/DeviceList/DeviceList";
import {Col, Row} from "antd";
import styles from "./HardwareDevice.module.scss"
import enquire from 'enquire-js'
import {
  dataSourceCollectionBox,
  dataSourceCollectionBoxFunc,
  dataSourceCollectionBoxTech,
  dataSourceExample,
  dataSourceFlat,
  dataSourceFlatFunc,
  dataSourceFlatTech,
  dataSourceFuncCharacter,
  dataSourceSaleTech,
  dataSourceScale,
  dataSourceScaleFunc,
  dataSourceWeighOperation
} from "./dataSource";
import {Process} from "../../components/Process/Process";
import {Specs} from "../../components/Specs/Specs";
import {useLocation} from "react-router-dom";
import {scrollTo, toRemAuto} from "../../utils/utils";
import {AntdCard} from "../../components/AntdCard/AntdCard";
import {OverPack} from "rc-scroll-anim";
import {TweenOneGroup} from "rc-tween-one";
import {YiWoVideo} from "../../components/YiWoVideo/YiWoVideo";

export const HardwareDevice = () => {
  const [showArrow, setShowArrow] = useState(true);
  let location = useLocation().state;
  useEffect(() => {
    scrollTo(location);
    enquire.register("screen and (min-width:1024px)", {
      match: function () {
        setShowArrow(true)
      },
      unmatch: () => {
        setShowArrow(false)
      }
    });
  }, []);

  const getElement: any = (dataSource, space) => {
    return (
      <Row style={{display: "flex", alignItems: "baseline", marginTop: "0.2rem"}} gutter={[16, 16]}>
        {dataSource.map((items, index) => {
          return (
            <Col style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }} xs={24} sm={space} key={index.toString()}>
              <img src={items.image}/>
              {items.child.map((item, index) => {
                return (
                  <div style={{marginTop: index === 0 ? toRemAuto(24) : 0}} key={index.toString()}
                       className={`text-small ${styles.banner_card_text}`}>{item.text}</div>
                )
              })}

            </Col>
          )
        })}
      </Row>
    )
  }

  return (
    <div>
      <Banner image={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/banner/banner.png"}
              title={"蚁窝智能硬件设备"}
              text={"基于多年对传统团餐及传统食堂用餐模式的深度了解，蚁窝技术团队从运营及应用等多维度分析探究，研发出多款应用于智慧餐厅的智能设备，能够有效解决传统食堂的痛点，提升用餐体验。"}/>
      <div id={"intelMachineV5_device"}>
        <DeviceList heightLightKey={"0"}/>
      </div>
      <div id={"intelMachineV5"}>
        <Banner
          bannerHeight={620}
          image={"https://assets.yiwoaikeji.com/prod/official-website-assets/hardwareDevice/banner/banner_hardware_device.png"}
          title={"蚁窝智能机V5.0"}
          text={"蚁窝智能机V5.0是由智能平板、智能秤、采集盒组成的智能一体化取餐结算设备，将RFID技术与高精度称重技术完美集成。可实现智能称重计量、无感免密支付、上传订单至个人账号、根据订单生成营养报告等功能。分离式设计可满足多场景的不同需求，安装灵便，操作简洁，能最大程度适应餐厅模式和用餐者取餐习惯。"}/>
      </div>
      <div style={{backgroundColor: "#1C1C1D"}} className={`container ${styles.func_character}`}>
        <div style={{color: "#ffffff"}} className="title-small">功能及特点</div>
        {dataSourceFuncCharacter.map((item: any, index) => {
          return (
            <div key={index.toString()}>
              <OverPack
                playScale={0.1}>
                <TweenOneGroup
                  key="ul"
                  enter={{
                    x: index % 2 ? '+=30' : '-=30',
                    opacity: 0,
                    type: 'from',
                    ease: 'easeInOutQuad',
                  }}
                  leave={{x: index % 2 ? '+=30' : '-=30', opacity: 0, ease: 'easeInOutQuad'}}
                >
                  <div key={index.toString()}>
                    <Row gutter={[16, 16]}
                         style={{
                           display: "flex",
                           alignItems: "center",
                           marginTop: "0.2rem",
                         }}>
                      <Col style={{display: "flex", flexDirection: "column"}} xs={24} sm={22} lg={20}>
                        <div className="flex">
                          <img
                            src={"https://assets.yiwoaikeji.com/prod/official-website-assets/hardwareDevice/images/icon_line.png"}/>
                          <div className={styles.func_character_title}>
                            {item.title}
                          </div>
                        </div>
                        <Row>
                          {item.children.map((item, index) => {
                            return (
                              <Col
                                key={index.toString()}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }} xs={12} md={12}>
                                <div className={styles.func_character_round}/>
                                <div className={`${styles.func_character_text}`}>{item.text}</div>
                              </Col>
                            )
                          })}
                        </Row>
                      </Col>
                      <Col style={{display: "flex", justifyContent: "center"}} xs={24} sm={2} lg={3}>
                        <img className={styles.func_character_img} src={item.image}/>
                      </Col>
                    </Row>
                    <div style={{display: index === dataSourceFuncCharacter.length - 1 ? 'none' : 'block'}}
                         className={styles.func_character_dived}/>
                  </div>
                </TweenOneGroup>
              </OverPack>
            </div>
          )
        })}
      </div>
      <div className={styles.pad} style={{backgroundColor: "#0F0F0F"}}>
        <div style={{color: "#ffffff", textAlign: "center", marginBottom: "0.3rem"}} className="title-small">操作流程</div>
        <YiWoVideo width={"100%"} height={"3rem"} videoUrl={"video/AIMechine_pick_up.mp4"}
                   poster={"hardwareDevice/backgroundImage/bck_operation.png"}/>
        <div className={styles.operation_container}>
          <Process titleColor={"#ffffff"} textColor={"#B5B5B5"} items={dataSourceWeighOperation}
                   arrowWidth={96} space={6}/>
          <img style={{display: showArrow ? 'block' : 'none'}}
               className={styles.operation_container_arrow_short}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_arrow_short.png"}/>
          <Row gutter={[0, 16]} style={{marginLeft: showArrow ? "0.5rem" : "0", marginTop: toRemAuto(20)}}>
            <Col xs={12} md={12} lg={12} style={{
              minWidth: "250px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
              <div className={styles.round}>
                <span>5</span>
              </div>
              <div style={{color: "#B5B5B5"}} className={styles.operation_container_text}>
                大健康数据管理，为消费者提供合理的膳食建议
              </div>
              <img
                style={{display: showArrow ? "block" : "none"}}
                className={styles.left_arrow}
                src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_process_arrow.png"}/>
            </Col>
            <Col xs={12} md={8} lg={8} style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "0.3rem"
            }}>
              <div className={styles.round}>
                <span>6</span>
              </div>
              <div style={{color: "#B5B5B5"}} className={styles.operation_container_text}>
                取餐15分钟后，支付宝/微信无感免密支付
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div id={"intelFlat"}>
        <Banner bannerHeight={620}
                title={(
                  <div className="text-ali-fontH"><span>蚁窝</span><span style={{color: "#FFB119"}}>智能平板</span></div>)}
                text={getElement(dataSourceFlat, 8)}
                image={"https://assets.yiwoaikeji.com/prod/official-website-assets/hardwareDevice/banner/banner_flat.png"}/>
      </div>
      <div style={{backgroundColor: "#0F0F0F"}} className={styles.func_character_pad}>
        <div style={{color: "#ffffff"}} className="title-small">产品功能</div>
        <Row style={{marginTop: "0.2rem"}} gutter={[0, 16]}>
          <Col style={{display: "flex", flexDirection: "column", justifyContent: "center"}} xs={24} md={12}>
            {dataSourceFlatFunc.map((item, index) => {
              return (
                <div>
                  <div className="flex" style={{alignItems: "center", marginTop: "0.1rem"}}
                       key={index.toString()}>
                    <img src={item.image}/>
                    <div style={{color: "#DBDFE7", marginLeft: "0.1rem"}}
                         className="text-small">{item.text}</div>
                  </div>
                  <div style={{display: index === dataSourceFlatFunc.length - 1 ? 'none' : 'block'}}
                       className={styles.flat_func_item_dived}/>
                </div>
              )
            })}
          </Col>
          <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={12}>
            <YiWoVideo
              width={"100%"}
              poster={"hardwareDevice/backgroundImage/bck_flat_operation.png"}
              videoUrl={"video/flat_show_confirm.mp4"}/>
          </Col>
        </Row>
      </div>
      <div style={{
        backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/hardwareDevice/backgroundImage/bck_flat_tech.png"})`,
        backgroundSize: "cover"
      }} className={styles.func_character_pad}>
        <Specs techTitleColor={"#ffffff"} titleColor={"#FFFFFF"} textColor={"#B5B5B5"}
               items={dataSourceFlatTech}/>
      </div>
      <div id={"intelScale"}>
        <Banner
          padLeft={265}
          titleMargin={50}
          bannerHeight={620}
          title={(<div><span>蚁窝</span><span style={{color: "#FFB119"}}>智能秤</span></div>)}
          text={getElement(dataSourceScale, 8)}
          image={"https://assets.yiwoaikeji.com/prod/official-website-assets/hardwareDevice/banner/banner_scale.png"}/>
      </div>
      <div style={{backgroundColor: "#0F0F0F"}} className={styles.func_character_pad}>
        <div style={{color: "#ffffff"}} className="title-small">产品功能</div>
        <Row style={{marginTop: "0.2rem"}} gutter={[16, 16]}>
          <Col style={{display: "flex", flexDirection: "column", justifyContent: "center"}} xs={24} md={12}>
            {dataSourceScaleFunc.map((item, index) => {
              return (
                <div>
                  <div className="flex" style={{alignItems: "center", marginTop: "0.1rem"}}
                       key={index.toString()}>
                    <img src={item.image}/>
                    <div style={{color: "#DBDFE7", marginLeft: "0.1rem"}}
                         className="text-small">{item.text}</div>
                  </div>
                  <div style={{display: index === dataSourceScaleFunc.length - 1 ? 'none' : 'block'}}
                       className={styles.flat_func_item_dived}/>
                </div>
              )
            })}
          </Col>
          <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={12}>
            <video className={styles.operation_video_small} controls
                   poster={"https://assets.yiwoaikeji.com/prod/official-website-assets/hardwareDevice/backgroundImage/video_scale.png"}>
              <source src={"https://assets.yiwoaikeji.com/prod/official-website-assets/video/yiwo_scale_show.mp4"}
                      type="video/mp4"/>
            </video>
          </Col>
        </Row>
      </div>
      <div style={{
        backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/hardwareDevice/backgroundImage/bck_scale_tech.png"})`,
        backgroundSize: "cover"
      }} className={styles.func_character_pad}>
        <Specs techTitleColor={"#ffffff"} titleColor={"#FFFFFF"} textColor={"#B5B5B5"}
               items={dataSourceSaleTech}/>
      </div>
      <div id={"intelBox"}>
        <Banner
          padLeft={225}
          bannerHeight={620}
          titleMargin={90}
          title={(<div><span>蚁窝</span><span style={{color: "#FFB119"}}>采集盒</span></div>)}
          text={getElement(dataSourceCollectionBox, 12)}
          image={"https://assets.yiwoaikeji.com/prod/official-website-assets/hardwareDevice/banner/banner_collection_box.png"}/>
      </div>
      <div style={{backgroundColor: "#0F0F0F"}} className={styles.func_character_pad}>
        <div style={{color: "#ffffff"}} className="title-small">产品功能</div>
        <Row style={{marginTop: "0.2rem"}} gutter={[16, 16]}>
          <Col style={{display: "flex", flexDirection: "column", justifyContent: "center"}} xs={24} md={12}>
            {dataSourceCollectionBoxFunc.map((item, index) => {
              return (
                <div key={index.toString()}>
                  <div className="flex" style={{alignItems: "center", marginTop: "0.1rem"}}>
                    <img src={item.image}/>
                    <div style={{color: "#DBDFE7", marginLeft: "0.1rem"}}
                         className="text-small">{item.text}</div>
                  </div>
                  <div
                    style={{display: index === dataSourceCollectionBoxFunc.length - 1 ? 'none' : 'block'}}
                    className={styles.flat_func_item_dived}/>
                </div>
              )
            })}
          </Col>
          <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={12}>
            <YiWoVideo
              width={"100%"}
              poster={"hardwareDevice/backgroundImage/video_collection_box.png"}
              videoUrl={"video/collectionBox.mp4"}/>
          </Col>
        </Row>
      </div>
      <div style={{
        backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/hardwareDevice/backgroundImage/bck_collection_box.png"})`,
        backgroundSize: "cover"
      }}
           className={styles.func_character_pad}>
        <Specs techTitleColor={"#ffffff"} titleColor={"#FFFFFF"} textColor={"#B5B5B5"}
               items={dataSourceCollectionBoxTech}/>
      </div>
      <div style={{backgroundColor: "#0F0F0F"}} className="container container_pad">
        <div style={{color: "#ffffff"}} className="title-small">应用场景</div>
        <div style={{marginTop: "0.2rem"}}
             className="text-small">蚁窝智能机V5.0目前已经应用在政企校社等多地域、多领域，距第一个项目落地，已经平稳运行超过5年，受到来自各界的肯定与赞赏
        </div>
        <Row
          style={{marginTop: "0.3rem"}}
          gutter={[32, 16]}>
          {dataSourceExample.map((item: any, index) => {
            return (
              <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                   key={index.toString()} xs={24} sm={12} lg={8}>
                <AntdCard titles={item.titles}
                          titleColor={"#FFFFFF"}
                          text={item.text}
                          textColor={"#B5B5B5"}
                          imagePath={item.image}
                          backgroundColor={"#222222"}
                          hoverable={false}
                          border={false}/>
              </Col>
            )
          })}
        </Row>
      </div>
    </div>
  )
}