import {assetsUrl} from "../../constants/constants";

export const dataSourceNewTrends = [
  {
    id: "1",
    year: 2023,
    month: "07月",
    day: "18",
    image: assetsUrl + "news/card/image01.png",
    title: "从“鼠头鸭脖”到“鸭眼球膜”，食堂安全问题频出，看智慧餐饮如何应对",
    abstract: "7月17日，网传有人在广州一所职校食堂饭菜中吃出胶制异物，送检结果为“送检样品排除橡胶制品和一次性手套可能，高度可能为鸭眼球巩膜结构。而就在此前，6月1日，江西工业职业技术学院学生在食堂吃出疑似为“鼠头”的异物，但学校认定其为“鸭脖”，最后的检测结果确为“鼠头”。",
  }, {
    id: "2",
    year: 2023,
    month: "07月",
    day: "14",
    image: assetsUrl + "news/card/image02.png",
    title: "【热烈欢迎】广东省投资发展促进会领导莅临广州蚁窝考察交流",
    abstract: "2023年7月13日上午11点，广东省投资发展促进会领导一行莅临广州蚁窝智能科技有限公司开展考察交流活动。我司总经理何厚明、副总经理姚欣蓉、财务经理陈柏艳等陪同参与此次交流活动。",
  }, {
    id: "3",
    year: 2023,
    month: "07月",
    day: "01",
    image: assetsUrl + "news/card/image03.png",
    title: "稳步前进 | 36家智慧餐厅开业，蚁窝智能2023第二季度精彩回顾",
    abstract: "2023年第二季度，蚁窝智能继续取得了令人鼓舞的成绩，我们在全国各地相继成功打造了36家智能团餐综合项目，覆盖了华东、华南、华中以及西南地区的多个城市。这些智慧餐厅都采用了蚁窝智能最先进的智慧餐饮设备和软件技术，为用餐者提供了便捷、高效、个性化的用餐体验，受到了广泛的好评和欢迎。"
  }, {
    id: "4",
    year: 2023,
    month: "06月",
    day: "16",
    title: "热烈庆祝江苏蚁窝进驻京源·睿谷生态科技园",
    abstract: "6月9日上午，由南通京源环保产业发展有限公司投资兴建的京源·睿谷生态科技园盛大开园。中国环境科学学会、南通市政府、南通市生态环境局等组织的领导及重要嘉宾出席了本次“科创中国”创新基地揭牌仪式暨京源·睿谷生态科技园开园庆典。"
  }, {
    id: "5",
    year: 2023,
    month: "06月",
    day: "12",
    title: "在这里，一个人吃饭也可以有很多选择",
    abstract: "在现代社会，人们的生活节奏越来越快，工作压力越来越大，很多时候没有时间和同事、朋友一起吃饭。还有时候是因为想一个人静静地享受美食。此外，现代社会的人际关系也变得越来越疏离，很多人都不愿意和陌生人一起吃饭。"
  }, {
    id: "6",
    year: 2023,
    month: "05月",
    day: "26",
    title: "广东立案查处341件餐饮浪费案件，智慧餐饮如何应对？",
    abstract: "自3月部署开展制止餐饮浪费专项行动以来，广东省市场监管局坚决贯彻习近平总书记关于制止餐饮浪费的一系列重要指示批示精神，聚焦餐饮浪费重点领域、重点问题精准发力，以多举措多层次推动制止餐饮浪费行动落实落地，取得初步成效。"
  }, {
    id: "7",
    year: 2023,
    month: "05月",
    day: "19",
    title: "小微餐饮成为新风口，打造品牌不再是大厂专利",
    abstract: "在当下市场上，品牌的塑造是每个企业都十分重视的问题，对于餐饮企业来说，品牌的重要性更是不言而喻。同时，大众常常对塑造品牌有一个认知误区，那就是认为只有大企业才需要做品牌或是认为只有大企业才能打造出品牌，其实不然。打造品牌实质上也是一种营销手段，其目的仍然是为了让产品被更多消费者接受并主动去消费。所以，品牌不是只有大企业才能做，而是每一个想要长久发展的企业在建立初期就应该考虑的东西。"
  },
]