import React from "react";

export const dataSourceServices = [
  {
    title: "取餐设备",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_food_pickup.png",
    path: "/hardware_device",
    state: {local: "intelMachineV5_device"}
  }, {
    title: "绑盘设备",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_bind_device.png",
    path: "/binding_device",
    state: {local: "bindBoard_device"}
  }, {
    title: "收银设备",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_pos.png",
    path: "/pos",
    state: {local: "pos_device"}
  }, {
    title: "智能后厨看板",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_kitchen_signage.png",
    path: "/kitchen_signage",
    state: {local: "kitchenBoard_device"}
  }, {
    title: "智能配件",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_other_device.png",
    path: "/intelligence_accessory",
    state: {local: "intelligenceAccessoryHead"}
  }
];