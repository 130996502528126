import React, {useEffect} from "react";
import {Banner} from "../../components/Banner/Banner";
import {DeviceList} from "../../components/DeviceList/DeviceList";
import styles from "./KitchenSignage.module.scss";
import {Specs} from "../../components/Specs/Specs";
import {dataSourceTech} from "./dataSource";
import {useLocation} from "react-router-dom";
import {scrollTo} from "../../utils/utils";

export const KitchenSignage = () => {
  let location = useLocation().state;
  useEffect(() => {
    scrollTo(location);
  }, []);

  return (
    <div>
      <Banner image={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/banner/banner.png"}
              title={"蚁窝智能硬件设备"}
              text={"基于多年对传统团餐及传统食堂用餐模式的深度了解，蚁窝技术团队从运营及应用等多维度分析探究，研发出多款应用于智慧餐厅的智能设备，能够有效解决传统食堂的痛点，提升用餐体验。"}/>
      <div id={"kitchenBoard_device"}>
        <DeviceList heightLightKey={"3"}/>
      </div>
      <div id={"kitchenBoard"} className={styles.banner_smart}>
        <div className={styles.banner_smart_title}>蚁窝智能后厨看板</div>
        <div
          className={`${styles.banner_smart_text} text-space-normal`}>主要由智能看板系统软件和高清显示屏硬件两部分组成，功能特征主要实现准确收集餐厅菜品销量情况数据，并实时传送至后台系统，后厨根据看板显示的信息进行备菜或调整。可视化管理方式让数据、情报等信息一目了然，极大提高了餐线与后厨的沟通效率。
        </div>
        <div style={{marginTop: "0.3rem", alignItems: "baseline"}} className="flex">
          <div className={styles.banner_smart_card_container_bottom}>
            <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_feedback.png"}/>
            <div className={styles.banner_smart_card_container_bottom_text}>高清大屏数据情况清晰显示</div>
          </div>
          <div className={styles.banner_smart_card_container_bottom} style={{marginLeft: "0.5rem"}}>
            <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_big_screen.png"}/>
            <div
              className={styles.banner_smart_card_container_bottom_text}>软件系统实时反馈前线状况有效规避误差与延时
            </div>
          </div>
        </div>
      </div>
      <div className={`container ${styles.tech_container}`}>
        <Specs items={dataSourceTech}/>
      </div>
    </div>
  )
}