import React, {useEffect} from "react";
import {Banner} from "../../components/Banner/Banner";
import {TwoSoftware} from "../../components/TwoSoftware/TwoSoftware";
import styles from "./YiwoApp.module.scss";
import {dataSourceFunctions} from "./dataSource";
import {IntroduceCard} from "../../components/IntroduceCard/IntroduceCard";
import {OverPack} from "rc-scroll-anim";
import {TweenOneGroup} from "rc-tween-one";

export const YiwoApp = () => {
  useEffect(() => {
    document.body.scrollIntoView();
  }, [])
  return (
    <div>
      <Banner image={"https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoApp/banner/banner01.png"}
              title={"蚁窝智能软件系统"} titleColor={"#333333"}
              text={"蚁窝智能软件系统包括智能后台系统以及蚁窝AI APP。云部署管理后台系统（SAAS系统），结合网络技术、RFID技术、大数据技术、AI技术，打造了一个适配于食堂数字化经营的生态环境，与客户端应用的使用方形成完美闭环。"}/>
      <TwoSoftware leftTitleColor={"#333333"} rightTitleColor={"#FFB119"}/>
      <Banner textColor={"#B55D2D"} title={""} text={
        (<div>
          <span className={styles.banner_head_title}>“蚁窝AI”App </span>是广州蚁窝智能科技有限公司开发、运营、管理的一款智慧食堂服务软件，致力为政企校舍的食堂提供一站式智慧餐饮解决方案。
        </div>)
      } image={"https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoApp/banner/banner02.png"}/>
      <Banner textMargin={45}
              textColor={"#B55D2D"}
              image={"https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoApp/banner/banner03.png"}
              title={""}
              text={"通过“蚁窝AI”App，用户可以体验餐厅用餐、记录饮食情况、外卖点餐等功能，提升就餐效率的同时还能实时追踪饮食情况，运用大健康数据管理，为消费者提供合理的膳食建议。"}/>
      <div style={{backgroundColor: "#F9F9F9"}} className={`container ${styles.function_pad}`}>
        <div style={{color: "#3F1607"}} className="title-small">功能介绍</div>
          {dataSourceFunctions.map((item: any, index) => {
            return (
              <div key={index.toString()}>
                <OverPack
                  playScale={0.2}>
                  <TweenOneGroup
                    key="ul"
                    enter={{
                      x: index % 2 ? '+=30' : '-=30',
                      opacity: 0,
                      type: 'from',
                      ease: 'easeInOutQuad',
                    }}
                    leave={{x: index % 2 ? '+=30' : '-=30', opacity: 0, ease: 'easeInOutQuad'}}
                  >
                    <div style={{marginTop: "0.1rem"}} key={index.toString()}>
                      <IntroduceCard title={item.title} image={<img className={styles.function_img} src={item.image}/>}
                                     items={item.children}
                                     isImageLeft={index % 2 !== 0}/>
                    </div>
                  </TweenOneGroup>
                </OverPack>
              </div>
            )
          })}
      </div>
      <img className={styles.page_show_container}
           src={"https://assets.yiwoaikeji.com/prod/official-website-assets/yiwoApp/backgroundImage/bck_page_show.png"}/>
    </div>
  )
}