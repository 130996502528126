export const dataSourcePosOperation = [
  {
    text: "收银员在收银机主屏选择菜品输入金额或直接使用固定金额，也可在套餐界面选择收费套餐"
  }, {
    text: "消费者即可在副屏通过刷卡/刷脸/扫码支付"
  }
];
export const dataSourcePosTech = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size.png",
    title: "尺寸",
    children: [
      {
        text: "382*184*359mm",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_screen_size.png",
    title: "屏幕尺寸",
    children: [
      {
        text: "上屏15.6寸，副屏10.1寸"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_cpu.png",
    title: "CPU",
    children: [
      {
        text: "Coclex-A17",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_reservoir.png",
    title: "储存器",
    children: [
      {
        text: "2GB RAM，8GB ROM",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_power.png",
    title: "电源适配器",
    children: [
      {
        text: "INPUT：AC100-240V/1.5A",
      }, {
        text: "OUIPUT：DC24V/1.5A",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_os.png",
    title: "OS",
    children: [
      {
        text: "Android 7.1+",
      },
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_touch_screen.png",
    title: "触摸屏",
    children: [
      {
        text: "电客多点触摸屏",
      },
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_sound.png",
    title: "音响",
    children: [
      {
        text: "1.2W",
      },
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_memory.png",
    title: "MIcroSD（TF）",
    children: [
      {
        text: "≤64G",
      },
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_interface.png",
    title: "外部接口",
    children: [
      {
        text: "USB TypeA口 x4",
      }, {
        text: "RJ11串口 x1",
      }, {
        text: "RJ412钱箱口 x1",
      }, {
        text: "RJ45 LAN口 x1",
      }, {
        text: "耳机口 x1",
      }, {
        text: "电源口 x1",
      }, {
        text: "Micro-USB调试口 x1",
      },
    ],
  },
];
export const dataSourceK4Tech = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size_white.png",
    title: "尺寸",
    children: [
      {
        text: "110*140*80mm",
      },
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_screen_size_white.png",
    title: "打印方式",
    children: [
      {
        text: "行式热敏打印",
      },
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_weight_white.png",
    title: "重量",
    children: [
      {
        text: "0.5kg",
      },
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_ fold_paper_white.png",
    title: "切纸方式",
    children: [
      {
        text: "手动撕纸",
      },
    ],
  },
];
export const dataSourceK7Tech = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size_white.png",
    title: "尺寸",
    children: [
      {
        text: "122*108*104mm",
      },
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_screen_size_white.png",
    title: "打印方式",
    children: [
      {
        text: "横向热敏打印",
      },
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_weight_white.png",
    children: [
      {
        text: "514g",
      },
    ],
    title: "重量",
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_ fold_paper_white.png",
    title: "切纸方式",
    children: [
      {
        text: "自动切刀",
      },
    ],
  },
];
export const dataSourceSameTech = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_print_density_white.png",
    title: "打印密度",
    children: [
      {
        text: "354点/行或者8点/毫米",
      },
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_printing_speed_white.png",
    title: "打印速度",
    children: [
      {
        text: "90mm/s",
      },
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_paper_width_white.png",
    title: "装纸宽度",
    children: [
      {
        text: "57mm热敏纸",
      },
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_ electric_tension_white.png",
    title: "电压",
    children: [
      {
        text: "AC110V/220V",
      },
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_printing_width_white.png",
    title: "打印宽度",
    children: [
      {
        text: "48mm",
      },
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_sound_white.png",
    title: "语音",
    children: [
      {
        text: "固定语音",
      },
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_interface_white.png",
    title: "外部接口",
    children: [
      {
        text: "GPRS、WI-FI、网口、USB接口",
      },
    ],
  },
]