import React from "react";
import {assetsUrl} from "../../constants/constants";

export const dataSourceUsers = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/card/enterprise.png",
    number: 80,
    text: "企事业单位",
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/card/university.png",
    number: 200,
    text: "高校",
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/card/school.png",
    number: 50,
    text: "中小学",
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/card/factory.png",
    number: 130,
    text: "工厂",
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/card/Industrial_Park.png",
    number: 180,
    text: "产业园",
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/card/community.png",
    number: 80,
    text: "社区",
  }
];
export const dataSourceCoreAdv = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/image/icon_lighting.png",
    text: "智慧团餐市场前景广阔，回本速度快"
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/image/icon_tailor.png",
    text: "支持定制，根据您的场景和需求提供个性化改造方案"
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/image/icon_offline_mode.png",
    text: "独家离线模式，解决断网等困扰"
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/image/icon_AliPay.png",
    text: "支付宝团餐行业战略合作伙伴"
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/image/icon_service.png",
    text: "7×24小时售后服务，工程师定期上门检修"
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/image/icon_more_pay.png",
    text: "整合多种支付方式，包括人脸识别、支付宝、微信、APP等"
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/image/icon_train.png",
    text: "专业团队现场培训，系统设备操作包教包会"
  },

];
export const dataSourceSteps = [
  {
    title: assetsUrl + "cooperation/image/num_01.png",
    text: "需求对接"
  }, {
    title: assetsUrl + "cooperation/image/num_02.png",
    text: "方案推荐"
  }, {
    title: assetsUrl + "cooperation/image/num_03.png",
    text: "合作签订"
  }, {
    title: assetsUrl + "cooperation/image/num_04.png",
    text: "安装设施"
  }, {
    title: assetsUrl + "cooperation/image/num_05.png",
    text: "上线验收"
  }, {
    title: assetsUrl + "cooperation/image/num_06.png",
    text: "跟餐培训"
  }
];
export const dataSourceCertificate = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/image/credit.png"
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/image/enterprise_integrity_management.png"
  },
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/image/quality_control.png"
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/image/computer1.png"
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/image/computer2.png"
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/cooperation/image/computer3.png"
  },
];
export const dataSourceCooperationData = [
  {
    number: "120+",
    unit: "个",
    text: "覆盖省市级地区"
  }, {
    number: "1000+",
    unit: "个",
    text: "服务项目"
  }, {
    number: "40亿+",
    unit: "次",
    text: "已服务人次"
  },
];