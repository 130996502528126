import routes from "../../routes"
import React, {useState, useEffect, useRef} from "react";
import {NavLink, useLocation, useNavigate} from 'react-router-dom'
import styles from "./Nav.module.scss"
import {Dropdown, Menu} from "antd";
import {dataSourceDropdownDevice, dataSourceDropDownSoftWare, dataSourceProductPath} from "./datSource";
import {scrollTo, toRemAuto} from "../../utils/utils";

const Nav = (props: any) => {
  const {isMobile} = props;
  const elements = routes;
  //鼠标悬停与点击事件所选中的导航栏目互斥
  const [hoverState, setHoverState] = useState<any>('/'); //鼠标悬停时选中的导航栏Key
  const [clickState, setClickState] = useState<any>('/'); //点击事件选择的导航栏Key
  const [showMask, setShowMask] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openKeys, setOpenKeys] = useState<any>(undefined);
  const navigate = useNavigate();
  //解决刷新后导航栏错误指向
  useEffect(() => {
    //资讯详情也是资讯中心
    if (window.location.pathname.indexOf("news") !== -1) {
      setHoverState("/news");
      setClickState("/news");
      return;
    }
    setHoverState(window.location.pathname);
    setClickState(window.location.pathname);
  }, [window.location.pathname])

  useEffect(() => {
    if (showMask) {
      setOpenMenu(true)
    } else {
      setOpenMenu(false)
    }
  }, [showMask])

  const onPointOver = (key: string) => {
    setHoverState(key);
    if (key === '/product_center') {
      setShowMask(true);
    }
  }
  const getItem = (label: any, key: string, icon: any, children: any) => {
    return {
      key,
      icon,
      children,
      label,
    };
  };

  const btnProductScroll = (path: string, state: object) => {
    if (path === window.location.pathname) {
      scrollTo(state)
    }
  };

  const menuSelect = (path: string, state: object) => {
    if (path === window.location.pathname) {
      scrollTo(state);
      return;
    }
    navigate(path, {state: state})
  }


  const items = [
    getItem('', 'sum', <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/yiwo.png"}/>, [
      getItem(<div>
        <NavLink to={"/"}>
          首页
        </NavLink>
      </div>, 'a1', null, null),
      getItem(<div>
        <NavLink to={"/product_center"}>
          产品中心
        </NavLink>
      </div>, 'b1', null, [
        getItem(<div>
          <NavLink to={"/hardware_device"}>
            蚁窝智能硬件设备
          </NavLink>
        </div>, 'bb1', null, [
          getItem(<div>
            <NavLink to={"/hardware_device"}>
              取餐设备
            </NavLink>
          </div>, 'bbb1', null, [
            getItem(<div onClick={() => {
              menuSelect("/hardware_device", {local: "intelMachineV5"})
            }}>
              智能机V5.0
            </div>, 'bbb11', null, null),
            getItem(<div onClick={() => {
              menuSelect("/hardware_device", {local: "intelFlat"})
            }}>
              智能平板
            </div>, 'bbb12', null, null),
            getItem(<div onClick={() => {
              menuSelect("/hardware_device", {local: "intelScale"})
            }}>
              智能秤
            </div>, 'bbb13', null, null),
            getItem(<div onClick={() => {
              menuSelect("/hardware_device", {local: "intelBox"})
            }}>
              智能采集盒子
            </div>, 'bbb14', null, null),
          ]),
          getItem(<div>
            <NavLink to={"/binding_device"}>
              绑盘设备
            </NavLink>
          </div>, 'bbb2', null, [
            getItem(<div onClick={() => {
              menuSelect("/binding_device", {local: "faceBoard"})
            }}>
              人脸绑盘机
            </div>, 'bbb21', null, null),
            getItem(<div onClick={() => {
              menuSelect("/binding_device", {local: "cardBoard"})
            }}>
              刷卡绑盘机
            </div>, 'bbb22', null, null),
            getItem(<div onClick={() => {
              menuSelect("/binding_device", {local: "queryBoard"})
            }}>
              自助查询机
            </div>, 'bbb23', null, null),
          ]),
          getItem(<div>
            <NavLink to={"/pos"}>
              收银设备
            </NavLink>
          </div>, 'bbb3', null, [
            getItem(<div onClick={() => {
              menuSelect("/pos", {local: "pos"})
            }}>
              双面屏收银机
            </div>, 'bbb31', null, null),
            getItem(<div onClick={() => {
              menuSelect("/pos", {local: "outlet"})
            }}>
              外卖打单机
            </div>, 'bbb32', null, null),
          ]),
          getItem(<div>
            <NavLink to={"/kitchen_signage"}>
              智能后厨看板
            </NavLink>
          </div>, 'bbb4', null, []),
          getItem(<div>
            <NavLink to={"/intelligence_accessory"}>
              智能配件
            </NavLink>
          </div>, 'bbb5', null, [
            getItem(<div onClick={() => {
              menuSelect("/intelligence_accessory", {local: "protect"})
            }}>
              智能防护罩
            </div>, 'bbb51', null, null),
            getItem(<div onClick={() => {
              menuSelect("/intelligence_accessory", {local: "Tray"})
            }}>
              智能托盘
            </div>, 'bbb52', null, null),
            getItem(<div onClick={() => {
              menuSelect("/intelligence_accessory", {local: "intelWatchCard"})
            }}>
              智能手环/卡片
            </div>, 'bbb53', null, null),
            getItem(<div onClick={() => {
              menuSelect("/intelligence_accessory", {local: "freezer"})
            }}>
              智能冰柜
            </div>, 'bbb54', null, null),
            getItem(<div onClick={() => {
              menuSelect("/intelligence_accessory", {local: "garden"})
            }}>
              智能岛屿
            </div>, 'bbb55', null, null)
          ]),
        ]),
        getItem(<div>
          <NavLink to={"/yiwo_system"}>
            蚁窝智能软件系统
          </NavLink>
        </div>, 'bb2', null, null),
        getItem(<div>
          <NavLink to={"/yiwo_app"}>
            蚁窝AI app
          </NavLink>
        </div>, 'bb3', null, null)
      ]),
      getItem(<div>
        <NavLink to={"/solution"}>
          解决方案
        </NavLink>
      </div>, 'c1', null, null),
      getItem(<div>
        <NavLink to={"/case"}>
          项目案例
        </NavLink>
      </div>, 'd1', null, null),
      getItem(<div>
        <NavLink to={"/news"}>
          资讯中心
        </NavLink>
      </div>, 'e1', null, null),
      getItem(<div>
        <NavLink to={"/cooperation"}>
          渠道合作
        </NavLink>
      </div>, 'f1', null, null),
      getItem(<div>
        <NavLink to={"/action"}>
          技术支持
        </NavLink>
      </div>, 'g1', null, null),
      getItem(<div>
        <NavLink to={"/about"}>
          关于我们
        </NavLink>
      </div>, 'h1', null, null)

    ]),
  ];
  const onPointerOut = () => {
    setHoverState(null);
    setShowMask(false);
  }

  const btnClickShowMenu = () => {
    setShowMask(false);
    setOpenMenu(false);
  }

  const dropdownRender = () => {
    return (
      <div style={{display: isMobile ? 'none' : 'flex'}} className={styles.nav_product_items}>
        <div style={{width: "4rem"}}>
          <NavLink onClick={btnClickShowMenu} to={"/hardware_device"}>
            <div className="text-ali-fontB"
                 style={{fontSize: "0.12rem", fontFamily: "AlibabaPuHuiTiB", color: "#FFB119"}}>
              蚁窝智能硬件设备
            </div>
          </NavLink>
          <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
            {dataSourceDropdownDevice.map((items: any, index) => {
              return (
                <div style={{
                  height: "1.3rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }} key={index.toString()}>
                  <div className={styles.nav_product_device_list}>
                    <NavLink onClick={btnClickShowMenu} to={items.path}>
                      <div className={styles.nav_product_device_title}>{items.title}</div>
                    </NavLink>
                    {items.children && items.children.map((item, index) => {
                      return (
                        <NavLink onClick={btnClickShowMenu} key={index.toString()} to={items.path} state={item.state} style={{color: "#666666"}}
                                 className={styles.nav_product_device_list_span}>
                                                    <span
                                                      onClick={() => {
                                                        btnProductScroll(items.path, item.state)
                                                      }}>
                                                    {item.text}
                                                     </span>
                        </NavLink>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className={styles.nav_product_line}/>
        <div className={styles.nav_product_device}>
          <NavLink onClick={btnClickShowMenu} to={"/yiwo_system"}>
            <div style={{fontSize: "0.12rem", fontFamily: "AlibabaPuHuiTiB", color: "#FFB119"}}>蚁窝智能软件系统</div>
          </NavLink>
          <div className={styles.nav_product_software}>
            {dataSourceDropDownSoftWare.map((item, index) => {
              return (
                <div style={{
                  height: "1.3rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }} key={index.toString()}>
                  <div className={styles.nav_product_device_list}>
                    <NavLink onClick={btnClickShowMenu} to={item.path}>
                      <div className={styles.nav_product_device_title}>{item.title}</div>
                    </NavLink>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.nav}>
      <img className={styles.nav_logo}
           src={"https://assets.yiwoaikeji.com/prod/official-website-assets/main/logo/yiwo.png"}/>
      <div style={{display: isMobile ? 'none' : 'flex'}} className={styles.nav_item_list}>
        {elements.map(item => {
          return (
            <div style={{display: item.isShowNav ? 'flex' : 'none',height:toRemAuto(70)}}
                 onPointerOver={() => {
                   onPointOver(item.path)
                 }} onPointerOut={onPointerOut}
                 key={item.path}>
              {item.path !== '/product_center' &&
                  <NavLink onClick={btnClickShowMenu} to={item.path}>
                      <div className={styles.nav_item} style={{color: "black"}}>
                          <div
                              className={`${item.path === clickState ?
                                styles.nav_hover_text_selected : styles.nav_hover_text_no_selected}
                                             ${styles.nav_item_text}`}>{item.label}</div>
                          <div style={{display: hoverState === item.path ? 'block' : 'none'}}
                               className={styles.nav_hover_selected}/>
                        {/*互斥*/}
                          <div
                              style={{
                                display: clickState === item.path ? hoverState === item.path ?
                                  'none' : 'block' : 'none'
                              }}
                              className={styles.nav_hover_selected}/>
                      </div>
                  </NavLink>
              }
              {item.path === '/product_center' &&
                  <div className={styles.nav_item} style={{color: "black"}}>
                      <Dropdown
                          autoFocus={true}
                          open={openMenu}
                          overlayStyle={{width: "100%"}}
                          placement="bottom"
                          dropdownRender={dropdownRender}>
                          <NavLink onClick={btnClickShowMenu} to={"/product_center"}>
                              <div className={"flex"} style={{flexDirection: "column", alignItems: "center"}}>
                                  <div
                                      className={`${dataSourceProductPath.includes(clickState) ?
                                        styles.nav_hover_text_selected : styles.nav_hover_text_no_selected}
                                             ${styles.nav_item_text}`}>{item.label}</div>
                                  <div style={{display: hoverState === item.path ? 'inline-block' : 'none'}}
                                       className={styles.nav_hover_selected}/>
                                {/*互斥*/}
                                  <div
                                      style={{
                                        display: dataSourceProductPath.includes(clickState) ? hoverState === item.path ?
                                          'none' : 'block' : 'none'
                                      }}
                                      className={styles.nav_hover_selected}/>
                              </div>
                          </NavLink>
                      </Dropdown>
                  </div>
              }
            </div>
          )
        })}
      </div>
      <div style={{
        display: isMobile ? 'block' : 'none',
        width: "100%",
        position: "absolute",
        top: "0",
        zIndex: "999",
      }}>
        <Menu openKeys={openKeys} style={{width: "100%"}} theme={"dark"} mode={"inline"}
              items={items}>
        </Menu>
      </div>
      <div style={{display: showMask ? "block" : 'none'}} className={styles.mask}/>
    </div>
  )
}
export default Nav;