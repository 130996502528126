import React from "react";
import {Card} from "antd";
import styles from "./AntdCard.module.scss";
import Meta from "antd/es/card/Meta";
import {toRemAuto} from "../../utils/utils";

interface propsType {
  titles: Array<object>;
  text: string;
  imagePath: string;
  textColor?: string;
  titleColor?: string;
  border?: boolean;
  backgroundColor?: string;
  height?: number;
  hoverable?: boolean;
}

interface IpropsTypes extends React.FC<propsType> {

}

export const AntdCard: IpropsTypes = ({
                                        titles,
                                        text,
                                        imagePath,
                                        textColor = "#999999",
                                        titleColor = "#333333",
                                        border = true,
                                        backgroundColor = "#FFFFFF",
                                        height = toRemAuto(646),
                                        hoverable = true,
                                      }) => {
  return (
    <Card hoverable={hoverable} style={{backgroundColor: backgroundColor, height: height}} bordered={border}
          className={styles.example_classic_card}
          cover={<img className={styles.card_img} src={imagePath}/>}>
      <Meta style={{wordBreak: "normal"}} title={
        <div className="example-card-title">
          <div style={{color: titleColor}}
               className={`example-card-title-text ${styles.card_title}`}>{titles.map((item: any, index) => {
            return (
              <div key={index.toString()}>{item.title}</div>
            )
          })}</div>
          <div className="example-card-title-dived"/>
        </div>
      } description={<div className={styles.card_text} style={{color: textColor}}>{text}</div>}/>
    </Card>
  )
}