import styles from './Solution.module.scss';
import React from "react";
import {title} from 'process';

export const dataSourcePainPoints = [
  {
    title: '人力成本高',
    text: '人力成本过高，工作效率低下',
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_high_people.png"
  }, {
    title: '食堂管理难度大',
    text: '纯人工管理方式既消耗成本也不高效',
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_manage_hard.png"
  }, {
    title: '排队时间长',
    text: '就餐排队时间过长客流高峰期体验感不佳',
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_long_time.png"
  }, {
    title: '结算效率低',
    text: '收银结算人工效率慢支付方式多元难聚合',
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_low_efficiency.png"
  }, {
    title: '食物浪费严重',
    text: '销售数据及菜品分析未系统化管理 ，造成大量食材损耗及餐余垃圾浪费',
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_save_food.png"
  }, {
    title: '忽略饮食健康重要性',
    text: '传统食堂无法配比均衡的饮食方案',
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_health_no.png"
  }
];
export const dataSourceAdv = [
  {
    title: "自主取餐",
    text: "顾客安需求取餐，吃多少打多少，杜绝浪费",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_self_service.png"
  }, {
    title: "智能称重",
    text: "一菜一价，按克计费，杜绝人工计算失误",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_ai_weigh.png"
  }, {
    title: "无感支付",
    text: "支持微信/支付宝免密支付，无需排队结算",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_pay.png"
  }, {
    title: "营养分析",
    text: "与权威机构合作，根据用餐情况提供合理的膳食建议",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_nutrition.png"
  }, {
    title: "个性化定制",
    text: "支持多场景不同补贴，卡劵等需求，根据实际情况提供信息化整体解决方案",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_customized.png"
  },
];
export const dataSourceSteps = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/scan.png",
    text: "支付宝/微信扫码绑定托盘"
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/pickup2.png",
    text: "丰富菜品选择，自主取餐"
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/dish.png",
    text: "单菜单价，智能称重"
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/pickup.png",
    text: "取餐15分钟后，支付宝/微信无感免密支付"
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/data.png",
    text: "大健康数据管理，为消费者提供合理的膳食建议",
  }
];
export const dataSourceModeAdv = [
  {
    title: "线上预订 高效备餐",
    text: "线上提前预订餐，厨房接单预备餐，出餐效率大大提高",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/card/icon_schedule.png"
  }, {
    title: "智能化管理 精细化运营",
    text: "后台实时记录下单、出餐数据，运营管理更轻",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/card/icon_ai_manage.png"
  }, {
    title: "支付方式多元化",
    text: "支持员工补贴、活动卡券、系统钱包余额支付",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/card/icon_pay_diversification.png"
  }, {
    title: "线上线下同步运营",
    text: "支持线上订餐、 外卖送餐，增加餐厅营收方",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/card/icon_sync.png"
  }
];
export const dataSourcePlanValue = [
  {
    title: "智能化",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_ai.png"
  }, {
    title: "互联化",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_intel.png"
  }, {
    title: "数字化",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_number.png"
  }, {
    title: "协同化",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_cooperation.png"
  }, {
    title: "平台化",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_platform.png"
  }, {
    title: "服务化",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_service.png"
  }, {
    title: "生态化",
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/icon_ecology.png"
  },
];
export const dataSourceGatePro = [
  {
    titles: [
      {
        title: "线上预订餐"
      }, {
        title: "线下面对面点单"
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/card/gate01.png",
  }, {
    titles: [
      {
        title: "确定选餐"
      }, {
        title: "人脸/扫码支付"
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/card/gate02.png",
  }, {
    titles: [
      {
        title: "小票出单"
      }, {
        title: "后厨备餐"
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/card/gate03.png",
  }, {
    titles: [
      {
        title: "按号取餐"
      }, {
        title: "预约就餐"
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/card/gate04.png",
  },
];
export const dataSourceBgd = [
  {
    backgroundImage: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/gate_process01.png"
  }, {
    backgroundImage: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/gate_process02.png"
  }, {
    backgroundImage: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/gate_process03.png"
  }, {
    backgroundImage: "https://assets.yiwoaikeji.com/prod/official-website-assets/solution/images/gate_process04.png"
  },
]