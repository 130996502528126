import React from 'react';
import logo from './logo.svg';
import './App.scss';
import {useRoutes} from 'react-router-dom'
import MainLayout from "./layouts/MainLayouts";
import routes from './routes';

function App() {
  const element: any = useRoutes(routes);
  return (
    <div>
      <div style={{overflowX: "hidden"}}>
        <MainLayout>
          {element}
        </MainLayout>
      </div>
    </div>
  )
}

export default App;
