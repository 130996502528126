import React, {useEffect, useState} from "react";
import styles from "./New.module.scss";
import {Card, Col, ConfigProvider, Pagination, PaginationProps, Row} from "antd";
import QueueAnim from "rc-queue-anim/lib/QueueAnim";
import {OverPack} from "rc-scroll-anim/es";
import 'rc-banner-anim/assets/index.css';
import {dataSourceNewTrends} from "./dataSource";
import Meta from "antd/es/card/Meta";
import {useNavigate} from "react-router-dom";
import {toRemAuto} from "../../utils/utils";

const News = () => {
  const [pageNum, setPageNum] = useState(1);
  const totalNum = dataSourceNewTrends.length;
  const [showNews, setShowNews] = useState<Array<object>>(dataSourceNewTrends.slice(0, 4));
  const navigate = useNavigate();
  useEffect(() => {
    document.body.scrollIntoView();
  }, []);

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <div style={{display: "flex", marginRight: "0.2rem", marginTop: "0.05rem"}}>
          <img style={{width: "18px", height: "18px"}}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/case/icon/icon_left_arrow.png"}/>
          <div className={styles.page_arrow}>上一页</div>
        </div>
      )
    }
    if (type === 'next') {
      return (
        <div style={{display: "flex", marginLeft: "0.2rem", marginTop: "0.05rem"}}>
          <img style={{width: "18px", height: "18px"}}
               src={"https://assets.yiwoaikeji.com/prod/official-website-assets/case/icon/icon_right_arrow.png"}/>
          <div className={styles.page_arrow}>下一页</div>
        </div>
      )
    }
    return originalElement;
  };


  const onChange: PaginationProps['onChange'] = (page) => {
    setPageNum(page);
    const toShow = dataSourceNewTrends.slice((page - 1) * 4, (page - 1) * 4 + 4);
    setShowNews(toShow)
  };

  const toDetail = (id) => {
    navigate('/news_detail', {
      state: {
        id: id,
      },
    })
  };

  return (
    <div>
      <div style={{flexDirection: "column"}}>
        <div
          style={{backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/news/banner/banner.png"})`}}
          className="banner-img">
                <span className="banner-title">
                    资讯中心
                </span>
          <span className="banner-text">
                    跟踪时讯，聚焦热点
                </span>
        </div>
        <div style={{backgroundColor: "#ffffff"}} className={styles.news_card_container}>
          <div style={{display: "flex", alignItems: "center"}}>
            <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_yiwo_head.png"}/>
            <span className={styles.news_big_title}>最新动态</span>
          </div>
          <OverPack playScale={0.2}
                    style={{marginTop: toRemAuto(37), width: "100%"}}>
            <QueueAnim
              type="bottom"
              key="block"
              component={Row as React.ClassType<any, any, any>}
              componentProps={{gutter: [8, 8]}}
              leaveReverse
            >
              {dataSourceNewTrends.slice(0, 3).map((item, index) => {
                return (
                  <Col onClick={() => {
                    toDetail(item.id)
                  }} style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                       md={8} xs={24} key={index.toString()}>
                    <Card hoverable className={styles.example_classic_card}
                          cover={<img className={styles.example_classic_card_img} src={item.image}/>}>
                      <Meta description={<div className="text-space-small">{item.abstract}</div>}/>
                    </Card>
                  </Col>
                )
              })}
            </QueueAnim>
          </OverPack>
        </div>
        <div style={{backgroundColor: "#FAFAFA"}} className={styles.new_list_container}>
          <div style={{display: "flex", alignItems: "center"}}>
            <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_yiwo_head.png"}/>
            <span className={styles.news_big_title}>最新动态</span>
          </div>
          {showNews.map((item: any, index) => {
            return (
              <div key={index.toString()}>
                <div className={styles.new_list_item}>
                  <div className={styles.news_date}>
                    <div className={styles.news_day}>{item.day}/</div>
                    <div>
                      <div className={styles.new_year_month_text}>{item.month}</div>
                      <div className={styles.new_year_month_text}>{item.year}</div>
                    </div>
                  </div>
                  <div className={styles.new_vertical_line}/>
                  <div style={{marginLeft: "0.2rem"}}>
                    <div onClick={() => {
                      toDetail(item.id)
                    }} className={styles.news_item_title}>
                      {item.title}
                    </div>
                    <div className={`${styles.news_item_abstract} text-space-normal`}>
                      {item.abstract}
                    </div>
                  </div>
                </div>
                <div className={styles.new_line}/>
              </div>
            )
          })}
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#FFB119",
              }
            }}
          >
            <Pagination onChange={onChange}
                        style={{marginTop: "0.4rem"}}
                        className={styles.news_pagination}
                        itemRender={itemRender}
                        current={pageNum}
                        pageSize={4}
                        defaultCurrent={1} total={totalNum}/>
          </ConfigProvider>
        </div>
      </div>
    </div>
  )
};
export default News;