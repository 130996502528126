import React, {useEffect} from "react";
import styles from "./DeviceList.module.scss";
import {OverPack} from "rc-scroll-anim/es";
import {TweenOneGroup} from "rc-tween-one";
import {Col, Row} from "antd";
import {dataSourceServices} from "./dataSource";
import {NavLink} from "react-router-dom";

interface propsTypes {
  backgroundColor?: string;
  titleColor?: string;
  heightLightKey?: string;
}

interface IpropsTypes extends React.FC<propsTypes> {

}

export const DeviceList: IpropsTypes = ({backgroundColor = "#ffffff", titleColor = "#333333", heightLightKey}) => {
  useEffect(() => {
    window.scrollTo(0,0) //滚动保证动画执行
  }, [])
  return (
    <div style={{backgroundColor: backgroundColor}} className={`${styles.device_pad}`}>
      <OverPack playScale={0.1}>
        <TweenOneGroup
          component={Row as React.ClassType<any, any, any>}
          componentProps={{gutter: [64, 16], justify: "center"}}
          key="ul"
          enter={{
            x: '+=30',
            opacity: 0,
            type: 'from',
            ease: 'easeInOutQuad',
          }}
          leave={{x: '+=30', opacity: 0, ease: 'easeInOutQuad'}}
        >
          {dataSourceServices.map((item: any, index) => {
            return (
              <Col style={{display: "flex", justifyContent: "center"}} key={index.toString()} xs={12}
                   md={8} lg={4}>
                <NavLink to={item.path} state={item.state}>
                  <div>
                    <img src={item.image}/>
                    <div style={{color: heightLightKey === index.toString() ? "#FFB119" : titleColor}}
                         className={styles.device_title}>{item.title}</div>
                  </div>
                </NavLink>
              </Col>
            )
          })}
        </TweenOneGroup>
      </OverPack>
    </div>
  )
}