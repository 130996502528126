import React from "react";

export const dataSourceOperationPro = [
  {
    text: "在采集盒上放置餐盘"
  }, {
    text: "面对平板摄像头刷脸"
  }, {
    text: "绑定餐盘，自助取餐"
  }
]
export const dataSourceFaceTech = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_model.png",
    title: "型号",
    children: [
      {
        text: "v5.0"
      }
    ]
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size.png",
    title: "尺寸",
    children: [
      {
        text: "135*130*302mm"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_weight.png",
    title: "重量",
    children: [
      {
        text: "710g"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_material.png",
    title: "材质",
    children: [{text: "ABS"}],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_screen_size.png",
    title: "屏幕尺寸",
    children: [
      {
        text: "≥8寸"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_os.png",
    title: "操作系统",
    children: [
      {
        text: "Android系统"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_network_electricity.png",
    title: "供电供网一体",
    children: [
      {
        text: "poe"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_sound.png",
    title: "音响",
    children: [
      {
        text: "3w"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_interface.png",
    title: "外部接口",
    children: [
      {
        text: "USB串口 x3"
      }, {
        text: "Micro USB接口 x1"
      }, {
        text: "RJ45网络接口 x1"
      }
    ],
  },
];
export const dataSourceOperationCard = [
  {
    text: "在采集盒上放置餐盘"
  }, {
    text: "面对平板摄像头刷脸"
  }, {
    text: "绑定餐盘，自助取餐"
  }
];
export const dataSourceCardTech = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_model.png",
    title: "型号",
    children: [
      {
        text: "v5.0"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size.png",
    title: "尺寸",
    children: [
      {
        text: "135*130*302mm"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_weight.png",
    title: "重量",
    children: [
      {
        text: "710g"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_material.png",
    title: "材质",
    children: [
      {
        text: "ABS"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_screen_size.png",
    title: "屏幕尺寸",
    children: [
      {
        text: "28寸"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_os.png",
    title: "操作系统",
    children: [
      {
        text: "Android系统"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_network_electricity.png",
    title: "供电供网一体",
    children: [
      {
        text: "poe"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_sound.png",
    title: "音响",
    children: [
      {
        text: "3w"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_interface.png",
    title: "外部接口",
    children: [
      {
        text: "USB串口 x3"
      }, {
        text: "Micro USB接口 x1"
      }, {
        text: "RJ45网络接口 x1"
      }
    ],
  },
];
export const dataSourceAutoOperation = [
  {
    text: "在采集盒上放置已绑定的餐盘，或直接通过平板刷卡/刷脸/刷手环"
  }, {
    text: "在平板上查询账户及订单信息"
  }
];
export const dataSourceAutoTech = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_model.png",
    title: "型号",
    children: [
      {
        text: "v5.0"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size.png",
    title: "尺寸",
    children: [
      {
        text: "135*130*302mm"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_weight.png",
    title: "重量",
    children: [
      {
        text: "710g"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_material.png",
    title: "材质",
    children: [
      {
        text: "ABS"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_screen_size.png",
    title: "屏幕尺寸",
    children: [
      {
        text: "≥8寸"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_os.png",
    title: "操作系统",
    children: [
      {
        text: "Android系统"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_network_electricity.png",
    title: "供电供网一体",
    children: [
      {
        text: "poe"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_sound.png",
    title: "音响",
    children: [
      {
        text: "3w"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_interface.png",
    title: "外部接口",
    children: [
      {
        text: "USB串口 x3"
      }, {
        text: "Micro USB接口 x1"
      }, {
        text: "RJ45网络接口 x1"
      }
    ]
  },
];