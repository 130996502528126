import React from "react";
import styles from "./IntroduceCard.module.scss";
import {Col, Row} from "antd";

interface propsTypes {
  title: string;
  image: React.ReactNode;
  items: Array<any>,
  isImageLeft: boolean,
}

interface IpropsTypes extends React.FC<propsTypes> {

}

export const IntroduceCard: IpropsTypes = ({image, items, isImageLeft, title}) => {
  return (
    <div className={styles.container}>
      <Row style={{
        marginTop: "auto",
        marginBottom: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }} gutter={[16, 16]}>
        <Col xs={24} md={12} style={{display: isImageLeft ? 'flex' : 'none', justifyContent: "center"}}>
          {image}
        </Col>
        <Col style={{display: "flex", flexDirection: "column", justifyContent: "center"}} xs={24} md={12}>
          <div className={styles.title}>{title}</div>
          {items.map((item, index) => {
            return (
              <div key={index.toString()} style={{marginTop: "0.1rem"}} className="flex">
                <div className={styles.round}/>
                <div className={`${styles.text} text-space-normal`}>{item.text}</div>
              </div>
            )
          })}
        </Col>
        <Col xs={24} md={12} style={{display: isImageLeft ? 'none' : 'flex', justifyContent: "center"}}>
          {image}
        </Col>
      </Row>
    </div>

  )
}