import React from "react";

export const dataSourceNavText = [
  {
    type: "1",
    text: "全部"
  }, {
    type: "2",
    text: "企业单位"
  }, {
    type: "3",
    text: "高校"
  }, {
    type: "4",
    text: "中小学"
  }, {
    type: "5",
    text: "工厂"
  }, {
    type: "6",
    text: "产业园"
  }, {
    type: "7",
    text: "社区"
  }
];
export const dataSourceBusiness: Array<object> = [
  {
    type: "2",
    titles: [
      {
        title: "浙江中控技术股份有限公司"
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/zheJiangzhongkong/image01.png",
    text: "浙江中控技术股份有限公司成立于1999年，是高新技术企业和国家技术创新示范企业。2022年3月，广州蚁窝智能科技有限公司旗下智慧餐饮品牌“蚁窝智慧餐厅”携手浙江中控技术股份有限公司，将内部食堂升级改造为智慧餐饮模式，加速浙江中控技术股份有限公司后勤业务的数字化管理转型和智能化经营升级。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/zheJiangzhongkong/image02.png"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/zheJiangzhongkong/image03.png"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/zheJiangzhongkong/image04.png"
      }
    ]
  }, {
    type: "2",
    titles: [{title: "惠州市市民服务中心"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image01.png",
    text: "为应对职工的就餐需求，惠州市市民服务中心选择与智能团餐模式的领导品牌——“蚁窝智慧餐厅”进行深度合作，通过自助取餐和智能支付结算等功能，让就餐者按需点餐、精准点餐，进而避免粮食的铺张浪费。每天约有1000人次就餐，餐厅每天可供应100多道菜式，通过精密的数据汇总分析，每位职工每天预计用餐重量约为350克，从源头中避免餐厅管理中产生的浪费，提高了餐厅按需购材的能力，切实响应“光盘行动”的号召！",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image02.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image03.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image04.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image05.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image06.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image07.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image08.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image09.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image10.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouCitizenServiceCenter/image11.JPG"
      },
    ]
  }, {
    type: "2",
    titles: [{title: "惠阳区行政机关"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiyangAdministration/image01.png",
    text: "2021年9月，惠阳区行政机关正式引进智能团餐模式的领导品牌——“蚁窝智慧餐厅”，结合信息化管理系统平台及用餐大数据，全面助力惠阳区政府食堂提升就餐品质，帮助食堂降低人力成本，减少就餐差错率，落实并践行光盘行动！惠阳区行政机关智慧餐厅每天能同时容纳1500人就餐，日均接待量接近3000人次，餐厨智慧岛完全替代传统后厨，节省约1/2的后厨空间，6人即可应对1000人就餐。无感支付，无需等待和排队结算，无需排队结算，足足提高3倍的用餐效率。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiyangAdministration/image02.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiyangAdministration/image04.jpg"
      },
    ]
  }, {
    type: "2",
    titles: [{title: "惠阳区行政服务中心"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiyangServiceCenter/image01.png",
    text: "围绕广大干部职工的就餐需求，惠阳区行政服务中心携同“蚁窝智慧餐厅”打造了一家简洁温馨的智慧餐厅，设备数超过100台，每天可吸引800人同时就餐，日均接待量多达2000人次，为用餐者提供了高效便捷、营养健康的用餐模式。",
    carousel: [
      // {
      //   image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiyangServiceCenter/image01.jpg"
      // },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiyangServiceCenter/image02.jpg"
      },
    ]
  }, {
    type: "2",
    titles: [{title: "广东海德集团"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangdongHaiDe/image01.png",
    text: "蚁窝智慧餐厅于2021年8月正式入驻广东海德集团，每天可满足企业员工200余人次就餐，主要供应丰盛的早午餐。拥有超1500款的标准化菜品；同时涵盖面点、面食、饮品等多种菜式。食堂采用扫码取餐、人脸识别2种用餐模式，无需排队结算，足足提高3倍的用餐效率。",
    carousel: [
      // {
      //   image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangdongHaiDe/image01.jpg"
      // },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangdongHaiDe/image02.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangdongHaiDe/image03.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangdongHaiDe/image04.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangdongHaiDe/image05.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangdongHaiDe/image06.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangdongHaiDe/image07.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangdongHaiDe/image08.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangdongHaiDe/image09.jpg"
      },
    ]
  }, {
    type: "2",
    titles: [{title: "惠州胜宏科技"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhoushenghong/image01.png",
    text: "针对企业员工的就餐需求，2020年9月，胜宏科技引入蚁窝智能餐饮设备及一系列的食堂管理运作体系，每天约有1500人在升级后的食堂就餐，食堂每天供应120多道菜式。经过数据汇总分析，每位员工每天预计用餐重量约350克，这一举措能进一步节约粮食，避免出现粮食浪费的现象，还得到企业员工的高度认同！",
    carousel: [
      // {
      //   image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhoushenghong/image01.JPG"
      // },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhoushenghong/image02.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhoushenghong/image03.JPG"
      },
    ]
  }, {
    type: "2",
    titles: [{title: "东莞都市丽人总部"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/cosmoLady/image01.png",
    text: "东莞都市丽人总部蚁窝智慧餐厅全天可供500-600人次用餐，出餐时间控制在半个小时以内补菜频率10-15分钟之间必须完成。同时，食堂也为企业员工提供早中晚餐供餐服务，完全可以满足大型企业的用餐需求。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/cosmoLady/image02.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/cosmoLady/image03.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/cosmoLady/image04.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/cosmoLady/image05.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/cosmoLady/image06.JPG"
      },
    ]
  },
];
export const dataSourceUniversity = [
  {
    type: "3",
    titles: [{title: "广州新华学院"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/xinhuaUniversity/image01.png",
    text: "广州新华学院是一所综合性全日制普通高等院校，是中国医学数字教育项目示范基地学校。随着广州新华学院的“蚁窝智慧餐厅”的全新升级，菜品研发、配置的方式成为最大的亮点。通过大数据的汇总分析，在保留原有菜式的基础上，定期会自创特色菜系。通过自取菜品的方式，既能满足师生们的就餐需求，又能在就餐环节上进一步地节约粮食。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/xinhuaUniversity/image02.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/xinhuaUniversity/image03.jpg"
      },
    ]
  }, {
    type: "3",
    titles: [{title: "山东齐鲁工业大学"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/qiluUniversity/image01.png",
    text: "蚁窝智慧餐厅于2021年9月正式入驻山东齐鲁工业大学，可满足师生上千人就餐。100平方米的智慧岛替代传统后厨，约1/2的后厨空间，大大节约食堂空间，6人即可应对1000人就餐。无感支付，无需等待和排队结算，无需排队结算，足足提高3倍的用餐效率。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/qiluUniversity/image02.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/qiluUniversity/image03.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/qiluUniversity/image04.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/qiluUniversity/image05.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/qiluUniversity/image06.jpg"
      },
    ]
  }, {
    type: "3",
    titles: [{title: "江西理工大学"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/jiangXiScienceUniversity/image01.png",
    text: "围绕全校师生的饮食安全需求，江西理工大学引入蚁窝智能餐饮设备及一系列的食堂管理运作体系，全面打造了一家面积达上千平方米的智慧食堂，每天最多可容纳6000人次就餐，多次得到了学生家长和在校师生的高度认可。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/jiangXiScienceUniversity/image02.png"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/jiangXiScienceUniversity/image03.png"
      },
    ]
  }, {
    type: "3",
    titles: [{title: "广西外国语学校"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangxiForeignUniversity/image01.png",
    text: "广西外国语学校于2021年9月将传统食堂智慧升级，蚁窝智慧餐厅入驻广西外国语学院后，日均接待量近3000人次，能满足全校师生个性化的用餐需求，提供智能化的取餐服务，实现智能、自助、支付的功能。该食堂能够根据菜品销售数据的分析，每月实时汰换5%，优化菜品结构，助力餐厅降低菜品研发成本，全力提升在校师生的就餐幸福感。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangxiForeignUniversity/image02.png"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangxiForeignUniversity/image03.png"
      },
    ]
  }, {
    type: "3",
    titles: [{title: "南昌师范学院"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/nanchangTeacherUniversity/image01.png",
    text: "南昌师范学院位于江西南昌，是经江西省人民政府批准、国家教育部备案而设立的一所的综合性本科层次高等院校。蚁窝智慧餐厅中简洁的用餐环境和充满科技感的就餐方式吸引了众多师生前来体验。食堂涵盖了上百种菜式，特别在菜式种类方面，既满足了全校师生的个性化需求，又注重了营养和减少粮食浪费。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/nanchangTeacherUniversity/image03.png"
      },
    ]
  }, {
    type: "3",
    titles: [{title: "广州华立学院"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangzhouHuaLiUniversity/image01.png",
    text: "广州华立学院（原广东工业大学华立学院）建于1999年，2004年经教育部批准，成为广东省第一批独立学院，全面实施本科教育。2021年经教育部同意，转设为广州华立学院。该校引入蚁窝智慧餐厅，采用自助就餐的模式，根据食物的重量收费，杜绝海吃胡塞的浪费行为。价格明确，每天供应100多道菜肴，餐饮浪费减少了40%以上，新的消费观和生活观已蔚然成风。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangzhouHuaLiUniversity/image02.png"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangzhouHuaLiUniversity/image03.png"
      },
    ]
  }, {
    type: "3",
    titles: [{title: "佛山科学技术学院"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/foShanScienceUniversity/image01.png",
    text: "佛山科学技术学院是经国家教育部批准设立的全日制公办本科普通高校，是一所具有60多年办学历史、拥有硕士学位授予权的广东省高水平理工科大学建设高校。蚁窝智慧餐厅的引入，让传统食堂升级为智慧餐厅，餐厅设置多条餐线，日均接待师生8000多人，大大提高了用餐效率。结合简洁的空间设计，构成宽敞舒适的就餐环境，集餐饮、休闲为一体。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/foShanScienceUniversity/image02.png"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/foShanScienceUniversity/image03.png"
      },
    ]
  }, {
    type: "3",
    titles: [{title: "广州商学院"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangzhouBusinessUniversity/image01.png",
    text: "蚁窝智慧餐厅于2020年9月正式入驻广州商学院，食堂总面积1600平方米，可满足师生3000余人就餐。100平方米的智慧岛替代传统后厨，约1/2的后厨空间，大大节约食堂空间，6人即可应对1000人就餐。无感支付，无需等待和排队结算，无需排队结算，足足提高3倍的用餐效率。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangzhouBusinessUniversity/image02.png"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangzhouBusinessUniversity/image03.png"
      },
    ]
  }, {
    type: "3",
    titles: [{title: "广东创新科技职业学院"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangdongInnovateUniversity/image01.png",
    text: "围绕全校师生的饮食安全需求，广东创新科技职业学院引入蚁窝智能餐饮设备及一系列的食堂管理运作体系，全面打造了一家面积达4500平方米的智慧食堂，就餐座位多达2000个，每天最多可容纳6000人次就餐，多次得到了学生家长和在校师生的高度认可。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangdongInnovateUniversity/image02.png"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/guangdongInnovateUniversity/image03.png"
      },
    ]
  }, {
    type: "3",
    titles: [{title: "顺德职业技术学院"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shundeCareeruniversity/image01.png",
    text: "围绕全校师生的饮食安全需求，广东创新科技职业学院引入蚁窝智能餐饮设备及一系列的食堂管理运作体系，全面打造了一家面积达4500平方米的智慧食堂，就餐座位多达2000个，每天最多可容纳6000人次就餐，多次得到了学生家长和在校师生的高度认可。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shundeCareeruniversity/image02.png"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shundeCareeruniversity/image03.png"
      },
    ]
  },

];
export const dataSourceSchool = [
  {
    type: "4",
    titles: [{title: "韶关实验中学"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shaoguanSchool/image01.png",
    text: "采用自助就餐的模式，根据食物的重量收费，价格明确，杜绝海吃胡塞的浪费行为，餐饮浪费减少了50%以上。目前，韶关实验中学携手蚁窝智慧餐厅共同推动校园食堂餐饮质量的不断提升，减少“舌尖上的浪费”，践行“光盘行动”！",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shaoguanSchool/image02.png"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shaoguanSchool/image03.png"
      },
    ]
  }, {
    type: "4",
    titles: [{title: "深圳市南方科技大学附属中学"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shenZhengunivesitySchool/image01.png",
    text: "2021年8月，深圳市南方科技大学附属中学成功引进蚁窝智慧餐厅，把师生们餐饮数据、生产链数据与现场加工信息进行精准匹配，通过“互联网+”的技术减少粮食浪费的情况。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shenZhengunivesitySchool/image02.png"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shenZhengunivesitySchool/image03.png"
      },
    ]
  }, {
    type: "4",
    titles: [{title: "山西省吕梁市第一中学"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shanxiLuliangSchool/image01.png",
    text: "蚁窝智慧餐厅为山西省吕梁市第一中学原有食堂做智慧升级，耗时的排队打餐、结算付款情况将一去不复返，便捷的自选模式、拿起放下式计算餐费、后台大数据健康管理将成为新的用餐潮流。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shanxiLuliangSchool/image02.png"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shanxiLuliangSchool/image03.png"
      },
    ]
  },
];
export const dataSourceFactory = [];
export const dataSourceIndusPark = [
  {
    type: "6",
    titles: [{title: "中天联科产业园"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/zhongTianIndustrialPark/image01.png",
    text: "东莞中天联科产业园是蚁窝智能餐厅承接的最早的项目之一，从2018年开业，至今已经运行四年多，累计消费人次超过61万。餐厅总面积920㎡，可容纳380人次就餐。自助就餐、智能结算、菜品丰富，极大地提高了职员的用餐效率。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/zhongTianIndustrialPark/image02.png"
      }
    ]
  }, {
    type: "6",
    titles: [{title: "松湖智谷产业园"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/songHuZhiGuIndustrialPark/image01.png",
    text: "松湖智谷以产业生态新城为核心理念，围绕园区企业和员工的需求，引入的蚁窝智能餐饮设备打造了一家面积达5000㎡的智慧餐厅，设备数超过400台，可容纳3000人次就餐，开业至今累计消费人次接近70万。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/songHuZhiGuIndustrialPark/image02.png"
      }
    ]
  }, {
    type: "6",
    titles: [{title: "启迪协信科技园"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/qiDiXieXinIndustrialPark/image01.JPG",
    text: "2020年11月，启迪协信科技园引入蚁窝智能餐饮设备，打造了园区内第一家智慧餐厅，餐厅面积800㎡，累计用户数量超过10000人，累计消费人次超过20万，为用餐者提供了高效便捷、营养健康的用餐模式。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/qiDiXieXinIndustrialPark/image02.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/qiDiXieXinIndustrialPark/image03.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/qiDiXieXinIndustrialPark/image04.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/qiDiXieXinIndustrialPark/image05.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/qiDiXieXinIndustrialPark/image06.jpg"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/qiDiXieXinIndustrialPark/image07.JPG"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/qiDiXieXinIndustrialPark/image08.JPG"
      }
    ]
  }
];
export const dataSourceCommunity = [
  {
    type: "7",
    titles: [
      {
        title: "华南红智慧食堂"
      }, {
        title: "（湖南省株洲百货店）"
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/HuaNanWisdomCanteen/image01.png",
    text: "湖南株洲百货作为株洲的商业旗舰，组建于1997年，它的前身株洲百货大楼始建于1958年。作为株洲人最主要的消费场所，是本土最大的零售商业企业。湖南省株洲百货店的华南红智慧食堂面积多达400平方米，用餐座位多达120多个，每天最多能容纳将近800人次就餐。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/HuaNanWisdomCanteen/image02.png"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/HuaNanWisdomCanteen/image03.png"
      }
    ]
  }, {
    type: "7",
    titles: [
      {
        title: "华南红智慧食堂"
      }, {
        title: "（湖南省株洲百货天元店）"
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huaNanWisdomCanteen2/image01.png",
    text: "株百超市天元店是株洲百货股份有限公司下属的一家大型生活超市，营业面积4000余平米，经营3万余种商品。以全方位的三“心”服务：服务贴心、品质放心、价格动心，打动了不少株洲市民。湖南省株洲百货天元店的华南红智慧食堂面积为300平方米，用餐座位多达100多个，提供30多部智能用餐设备，每天最多能容纳将近600人次就餐。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huaNanWisdomCanteen2/image02.png"
      },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huaNanWisdomCanteen2/image03.png"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huaNanWisdomCanteen2/image04.png"
      },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huaNanWisdomCanteen2/image05.png"
      },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huaNanWisdomCanteen2/image06.png"
      }
    ]
  }, {
    type: "7",
    titles: [
      {
        title: "华南红智慧食堂"
      }, {
        title: "（湖南长沙五一广场店）"
      }
    ],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huaNanWisdomCanteen3/image01.jpg",
    text: "民以食为天，为满足数千名员工及消费者“干饭”的需求，湖南长沙五一广场引入了华南红智慧餐厅的智能餐饮设备及系统，40余台智能餐饮设备正式投入使用，可同时服务600多人。食材鲜、出菜快、味道正、样式多、管理智，将员工福利保障到“胃”！",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huaNanWisdomCanteen3/image02.jpg"
      },
    ]
  }, {
    type: "7",
    titles: [{title: "惠州大亚湾壹窝蜂农贸市场"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouDaYaWanMarket/image01.png",
    text: "为顺利对接对口帮扶资源，精准聚焦农产品产销对接短板弱项，着力加大消费扶贫助推“黔货出山”助力乡村振兴。2022年1月，该农贸市场正式引入广州蚁窝智能科技有限公司的智慧餐饮信息技术设备及系统，以移动互联、物联网、云计算等方面为核心驱动，充分借助系统汇总集成的信息资源，结合互联网智能科技和现代化农业的相互融合，共同打造出一个智能农贸的现代化生活市场！",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouDaYaWanMarket/image02.jpg"
      },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouDaYaWanMarket/image03.jpg"
      },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouDaYaWanMarket/image04.jpg",
      },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouDaYaWanMarket/image05.jpg"
      },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouDaYaWanMarket/image06.jpg"
      },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouDaYaWanMarket/image07.jpg",
      },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouDaYaWanMarket/image08.jpg"
      },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouDaYaWanMarket/image09.jpg"
      },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/huiZhouDaYaWanMarket/image10.jpg",
      },
    ]
  }, {
    type: "7",
    titles: [{title: "西安李家村万达广场智慧餐厅"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/xiAnLiWanDaGround/image01.png",
    text: "2021年11月，“蚁窝智慧餐厅”正式进驻西安李家村万达广场，拔得西北地区的发展头筹，为西安李家村万达广场提供全方位的智慧餐饮服务。不但能进一步完善该区的城市配套，也极大提升了商圈的品质！",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/xiAnLiWanDaGround/image02.png"
      }, {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/xiAnLiWanDaGround/image03.png"
      },
    ]
  }, {
    type: "7",
    titles: [{title: "山东东营安泰社区"}],
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shanDongTaiAnCommunity/image01.png",
    text: "山东东营安泰社区，蚁窝智慧餐厅能为不同年龄的用户提供多样化的菜品选择，结合《中国居民膳食指南（2022）》的要求，每天的膳食搭配种类不少于12种，每周提供不少于25种食材，用户可以通过手机查看菜品营养摄入情况及每餐营养摄入建议，关爱老年人的就餐健康，真正做到社区食堂的食养结合。",
    carousel: [
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shanDongTaiAnCommunity/image02.jpg",
      },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shanDongTaiAnCommunity/image03.jpg"
      },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shanDongTaiAnCommunity/image04.jpg"
      },
      {
        image: "https://assets.yiwoaikeji.com/prod/official-website-assets/case/image/caseCard/shanDongTaiAnCommunity/image05.jpg",
      },
    ]
  },
];
export const dataSourceAllExamples = dataSourceBusiness
  .concat(dataSourceUniversity)
  .concat(dataSourceSchool)
  .concat(dataSourceFactory)
  .concat(dataSourceIndusPark).concat(dataSourceCommunity)