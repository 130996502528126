export const dataSourceGuardOperation = [
  {
    text: "在采集盒上放置餐盘"
  }, {
    text: "防护罩自动开启"
  }, {
    text: "取菜后拿走餐盘，防护罩关闭"
  }
];
export const dataSourceOtherTech = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_power.png",
    title: "电源",
    children: [
      {
        text: "12V-5A"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size.png",
    title: "尺寸",
    children: [
      {
        text: "540*185*190mm"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_weight.png",
    title: "重量",
    children: [
      {
        text: "1.3kg"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_material.png",
    title: "材质",
    children: [
      {
        text: "PC"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_screen_size.png",
    title: "屏幕尺寸",
    children: [
      {
        text: "≥8寸"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_os.png",
    title: "操作系统",
    children: [
      {
        text: "Android系统",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_network_electricity.png",
    title: "供电供网一体",
    children: [
      {
        text: "poe"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_sound.png",
    title: "音响",
    children: [
      {
        text: "3W"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_interface.png",
    title: "外部接口",
    children: [
      {
        text: "USB串口 x3"
      }, {
        text: "2.5mmDC公头 x1"
      }
    ],
  },
];
export const dataSourceTrayTech = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size.png",
    title: "尺寸",
    children: [
      {
        text: "417*304*27mm",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_weight.png",
    title: "重量",
    children: [
      {
        text: "0.5kg",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_material.png",
    title: "材质",
    children: [
      {
        text: "食品级ABS",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_screen_size.png",
    title: "感应方式",
    children: [
      {
        text: "RFID",
      }
    ],
  },
];
export const dataSourceBraceletTech = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size.png",
    title: "尺寸",
    children: [
      {
        text: "245*222*5mm",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_weight.png",
    title: "重量",
    children: [
      {
        text: "20g",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_material.png",
    title: "材质",
    children: [
      {
        text: "50度硅胶",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_distance.png",
    title: "读卡距离",
    children: [
      {
        text: "不小于20mm",
      }
    ],
  },
];
export const dataSourceCardTech = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size.png",
    title: "尺寸",
    children: [
      {
        text: "85*54*0.8mm",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_weight.png",
    title: "重量",
    children: [
      {
        text: "5g",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_work_rate.png",
    title: "工作频率",
    children: [
      {
        text: "13.65MHz",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_card_type.png",
    title: "卡片类型",
    children: [
      {
        text: "MIFareI S50",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_distance.png",
    title: "读卡距离",
    children: [
      {
        text: "不小于20mm",
      }
    ],
  },
];
export const dataSourceFreezerTech = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size.png",
    title: "尺寸",
    children: [
      {
        text: "1600*1000*800mm（外）",
      }, {
        text: "670*541*270mm（内）"
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_material.png",
    title: "材质",
    children: [
      {
        text: "不锈钢",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_refrigeration.png",
    title: "制冷温度",
    children: [
      {
        text: "2-8°C",
      }
    ],
  },
];
export const dataSourceIslandsTech = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_size.png",
    title: "尺寸",
    children: [
      {
        text: "900*900*800mm（单个机位建议尺寸）",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_material.png",
    title: "材质",
    children: [
      {
        text: "岗石",
      }
    ],
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_cabinet_material.png",
    title: "柜体材质",
    children: [
      {
        text: "颗粒板、多层板（可选）",
      }
    ],
  },
]