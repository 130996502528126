export const dataSourceDropdownDevice = [
    {
        title: "取餐设备",
        path: "/hardware_device",
        children: [
            {
                text: "智能机V5.0",
                state: {
                    local: "intelMachineV5",
                }
            }, {
                text: "智能平板",
                state: {
                    local: "intelFlat",
                }
            }, {
                text: "智能秤",
                state: {
                    local: "intelScale",
                }
            }, {
                text: "智能采集盒",
                state: {
                    local: "intelBox",
                }
            }
        ]
    }, {
        title: "绑盘设备",
        path: "/binding_device",
        children: [
            {
                text: "人脸绑盘机",
                state: {
                    local: "faceBoard",
                }
            }, {
                text: "刷卡绑盘机",
                state: {
                    local: "cardBoard",
                }
            }, {
                text: "自助查询机",
                state: {
                    local: "queryBoard",
                }
            },
        ]
    }, {
        title: "收银设备",
        path: "/pos",
        children: [
            {
                text: "双面屏收银机",
                state: {
                    local: "pos",
                }
            }, {
                text: "外卖打单机",
                state: {
                    local: "outlet",
                }
            },
        ]
    }, {
        title: "智能后厨看板",
        path: "/kitchen_signage",
    }, {
        title: "智能配件",
        path: "/intelligence_accessory",
        children: [
            {
                text: "智能防护罩",
                state: {
                    local: "protect",
                }
            }, {
                text: "智能托盘",
                state: {
                    local: "Tray",
                }
            }, {
                text: "智能手环/卡片",
                state: {
                    local: "intelWatchCard"
                }
            }, {
                text: "智能冰柜",
                state: {
                    local: "freezer"
                }
            }, {
                text: "智能岛屿",
                state: {
                    local: "garden"
                }
            },
        ]
    },
];
export const dataSourceDropDownSoftWare = [
    {
        title: "蚁窝智能软件系统",
        path: "/yiwo_system",
    }, {
        title: "蚁窝AI App",
        path: "/yiwo_app",
    },
];
export const dataSourceProductPath = ["/product_center", "/binding_device", "/intelligence_accessory", "/pos", "/yiwo_app", "/yiwo_system", "/kitchen_signage", "/hardware_device"]
