import styles from "./NewsDetail.module.scss";
import React, {useEffect, useState} from "react";
import {useLocation} from 'react-router-dom'
import {dataSourceText} from "./dataSource";
import {Button, Col, Row} from "antd";
import QueueAnim from "rc-queue-anim/lib/QueueAnim";
import {OverPack} from "rc-scroll-anim";

export const NewsDetail = () => {
  let {
    state: {
      id: id,
    }
  } = useLocation();
  const maxLength = dataSourceText.length;
  const [text, setTextDetail] = useState<any>({});
  const [nextTitle, setNextTitle] = useState("");
  const [prevTitle, setPrevTitle] = useState("");
  useEffect(() => {
    if (sessionStorage.getItem("textKey") === null) {
      sessionStorage.setItem("textKey", id);
      searchText(id);
    } else {
      searchText(sessionStorage.getItem("textKey"));
    }
    document.body.scrollIntoView();
    return () => {
      sessionStorage.removeItem("textKey");
    }
  }, []);

  const prevText = () => {
    if (Number(text.key) - 1 === 0) {
      return;
    } else {
      searchText(Number(text.key) - 1)
      document.body.scrollIntoView();
      sessionStorage.setItem("textKey", String(Number(text.key) - 1));
    }
  }

  const nextText = () => {
    console.log(text.key);
    const maxLength = dataSourceText.length;
    if (Number(text.key) + 1 > maxLength) {
      return;
    } else {
      sessionStorage.setItem("textKey", String(Number(text.key) + 1));
      searchText(Number(text.key) + 1)
      document.body.scrollIntoView();
    }
  }

  const searchText = (id) => {
    setNextTitle(dataSourceText[Number(id) >= maxLength ? maxLength - 1 : Number(id)].title)
    setPrevTitle(dataSourceText[Number(id) - 2 <= 0 ? 0 : Number(id) - 2].title)
    dataSourceText.forEach(item => {
      if (item.key == id) {
        setTextDetail(item);
      }
    })
  }

  return (
    <div className={styles.news_detail_container}>
      <div
        style={{backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/news/banner/banner.png"})`}}
        className="banner-img">
                <span className="banner-title">
                    蚁窝智能餐饮综合解决方案
                </span>
        <span className="banner-text">
                    解决行业痛点，实现利润翻倍增长
                </span>
      </div>
      <div className={styles.news_article_container}>
        <div className={styles.news_detail_title}>
          {text.title}
        </div>
        <div className={styles.news_date}>
          <div className={styles.news_day}>{text.day}/</div>
          <div>
            <div className={styles.new_year_month_text}>{text.month}</div>
            <div className={styles.new_year_month_text}>{text.year}</div>
          </div>
        </div>
        <div className={styles.new_list_item}>
          <div className={styles.news_item_text} style={{marginTop: "0.1rem"}}>
            {text.dom}
          </div>
        </div>
      </div>
      <OverPack playScale={0}
                style={{marginTop: "20px", width: "100%"}}>
        <QueueAnim
          type="bottom"
          key="block"
          component={Row as React.ClassType<any, any, any>}
          componentProps={{gutter: [16, 16], className: styles.news_detail_btn_group}}
          leaveReverse
        >
          <Col key={"1"} style={{display: "flex", justifyContent: "center"}} xs={24} md={24} lg={12}>
            <Button onClick={prevText}>上一篇：{prevTitle}</Button>
          </Col>
          <Col key={"2"} style={{display: "flex", justifyContent: "center"}} xs={24} md={12} lg={12}>
            <Button onClick={nextText}>下一篇：{nextTitle}</Button>
          </Col>
        </QueueAnim>
      </OverPack>
    </div>
  )
}