import React, {useEffect, useState} from "react";
import {assetsUrl} from "../../constants/constants";

interface propsTypes {
  id?: string;
  videoUrl: string;
  poster?: string;
  width?: string;
  height?: string;
}

interface IpropsTypes extends React.FC<propsTypes> {

}

export const YiWoVideo: IpropsTypes = ({
                                         id = "video",
                                         videoUrl, poster,
                                         width = "auto",
                                         height = "auto",
                                       }) => {
  const [start, setStart] = useState(false);
  let video;
  useEffect(() => {
    video = document.getElementById(id);
    video.addEventListener('play', play);
    video.addEventListener('ended', end);
    return () => {
      video.removeEventListener('play', play);
      video.removeEventListener('ended', end);
    }
  }, []);

  const play = () => {
    setStart(true);
  }
  const end = () => {
    setStart(false);
  }

  return (
    <div>
      <video id={id}
             preload={"auto"}
             style={{objectFit: start ? 'contain' : 'cover', width: width, minWidth: "270px", height: height}}
             poster={assetsUrl + poster}
             autoPlay={start} controls>
        <source src={assetsUrl + videoUrl}
                type="video/mp4"/>
      </video>
    </div>
  )
}