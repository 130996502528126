import React, {ReactNode, useEffect, useState} from "react";
import styles from "./Action.module.scss";
import QueueAnim from "rc-queue-anim/lib/QueueAnim";
import {OverPack} from "rc-scroll-anim";
import {Col, Row} from "antd";
import {dataSourceAfterSales, dataSourceServiceRepair} from "./dataSource";
import {useLocation} from "react-router-dom";
import {scrollTo, toRemAuto} from "../../utils/utils";
import {Banner} from "../../components/Banner/Banner";

export const Action = () => {
  const [selectKey, setSelectKey] = useState("1");
  const [showContainer, setShowContainer] = useState<ReactNode>(<div/>);
  const [width, setWidth] = useState(document.documentElement.clientWidth);
  let location = useLocation().state;
  useEffect(() => {
    selectResponse();
    window.addEventListener('resize', () => {
      const width = document.documentElement.clientWidth;
      setWidth(width);
    }, false);
    if (location === null) {
      document.body.scrollIntoView()
      return;
    }
    scrollTo(location);
  }, []);


  const selectResponse = () => {
    setSelectKey("1");
    const show = (
      <div className={styles.service_container_body}>
        <div>
          <div className={styles.service_container_res_title}>
            · 自检报障
          </div>
          <div style={{letterSpacing: "3px"}} className={`${styles.service_container_res_text}`}>
            自检报障餐厅工作人员发现设备故障，线上沟通客服，了解故障设备、故障情况及问题所在。
          </div>
        </div>
        <img className={styles.service_container_res_image}
             src={"https://assets.yiwoaikeji.com/prod/official-website-assets/action/card/icon_service_response.png"}/>
      </div>
    );
    setShowContainer(show);
  };

  const selectMaintenance = () => {
    setSelectKey("2");
    const show = (
      <Row style={{marginTop: "0.2rem"}} gutter={[32, 32]}>
        {dataSourceServiceRepair.map((item, index) => {
          return (
            <Col style={{display: "flex", justifyContent: "center"}} key={index.toString()} xs={24} sm={24}
                 lg={12} xl={8}>
              <div className={styles.service_container_repair_card}>
                <img src={item.image}/>
                <div className={styles.service_container_repair_card_title}>{item.title}</div>
                <div className={`${styles.service_container_repair_card_text} text-space-normal`}>{item.text}</div>
              </div>
            </Col>
          )
        })}
      </Row>
    )
    setShowContainer(show)
  }

  const selectTrain = () => {
    setSelectKey("3");
    const show = (
      <div className={styles.service_container_body}>
        <div style={{width: "3rem", marginRight: "0.5rem"}}
             className={`${styles.service_container_res_text} text-space-large text-line-normal`}>
          我方将安排在验收前对用户的工作人员进行免费培训，使其掌握硬件产品的维护、保养方法及注意事项，同时应使工作人员熟悉掌握操作方法。对用户的工作人员进行所有的测试操作和维修方面的培训。培训包括讲课操作示范、参观和其他必要指导，以保证用户的工作人员能对所有设备的特性、结构操作要求和维修要求获得全面的了解和掌握。
        </div>
        <img className={styles.service_container_repair_image}
             src={"https://assets.yiwoaikeji.com/prod/official-website-assets/action/card/train.png"}/>
      </div>
    );
    setShowContainer(show)
  }

  return (
    <div>
      <Banner
        title={"售后无忧"}
        text={"专业  及时  高效"}
        image={"https://assets.yiwoaikeji.com/prod/official-website-assets/action/banner/banner.png"}/>
      {/*<div*/}
      {/*  style={{backgroundImage: `url(${"https://assets.yiwoaikeji.com/prod/official-website-assets/action/banner/banner.png"})`}}*/}
      {/*  className="banner-img">*/}
      {/*          <span className="banner-title">*/}
      {/*              售后无忧*/}
      {/*          </span>*/}
      {/*  <span className="banner-text">*/}
      {/*              专业  及时  高效*/}
      {/*          </span>*/}
      {/*</div>*/}
      <div style={{backgroundColor: "#ffffff"}} className="container container_pad">
        <div className={styles.title_container}>
          <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_yiwo_head.png"}/>
          <div id={"afterSale"} className={styles.title}>售后支持</div>
          <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/vertical_orange.png"}/>
        </div>
        <OverPack
          className={styles.after_sales_container}
          playScale={0.2}>
          <QueueAnim
            type="bottom"
            key="block"
            component={Row as React.ClassType<any, any, any>}
            componentProps={{gutter: [32, 64]}}
            leaveReverse
          >
            {dataSourceAfterSales.map((item, index) => {
              return (
                <Col key={index.toString()}
                     style={{display: "flex", justifyContent: "center"}}
                     md={8} xs={24} sm={12}>
                  <div className={styles.after_sales_card}>
                    <img src={item.image}/>
                    <div className={`${styles.after_sales_card_text} text-space-normal`}>{item.text}</div>
                  </div>
                </Col>
              )
            })}
          </QueueAnim>
        </OverPack>
      </div>
      <div style={{backgroundColor: "#F9F9F9"}} className="container container_pad">
        <div className={styles.title}>我们竭诚为您提供以下三种服务</div>
        <div className={styles.service_container}>
          <div className={styles.service_container_nav}>
            <div style={{
              height: "0.4rem"
            }}>
              <div style={{color: selectKey === "1" ? '#101D3D' : '#455278'}}
                   onClick={selectResponse}
                   className={`${styles.service_container_nav_title} ${selectKey === '1' ? 'text-ali-fontM' : ''}`}>
                服务响应
              </div>
              <div style={{display: selectKey === "1" && width > 1100 ? 'block' : 'none'}}
                   className={styles.service_container_item_dived}/>
            </div>
            <div style={{
              height: "0.4rem"
            }}>
              <div style={{color: selectKey === "2" ? '#101D3D' : '#455278'}}
                   onClick={selectMaintenance}
                   className={`${styles.service_container_nav_title} ${selectKey === '2' ? 'text-ali-fontM' : ''}`}>
                维修服务
              </div>
              <div style={{display: selectKey === "2" && width > 1100 ? 'block' : 'none'}}
                   className={styles.service_container_item_dived}/>
            </div>
            <div style={{
              height: "0.4rem"
            }}>
              <div style={{color: selectKey === "3" ? '#101D3D' : '#455278'}} onClick={selectTrain}
                   className={`${styles.service_container_nav_title} ${selectKey === '3' ? 'text-ali-fontM' : ''}`}>
                技术培训
              </div>
              <div style={{display: selectKey === "3" && width > 1100 ? 'block' : 'none'}}
                   className={styles.service_container_item_dived}/>
            </div>
          </div>
          <div className={styles.service_container_nav_dived}/>
          <div>
            {showContainer}
          </div>
        </div>
      </div>
      {/*设备检修指导视频----待做*/}
      <div style={{backgroundColor: "#F7FAFD"}} className={`container ${styles.concat_pad}`}>
        <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/action/images/concat.png"}/>
        <Row gutter={[16, 16]}
             style={{
               marginTop: "0.3rem",
             }}>
          <Col xs={24} sm={24}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <div className={styles.concat}>
                  <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/action/images/icon_phone.png"}/>
                  <div style={{marginLeft: "0.1rem"}}>
                    <div className={styles.concat_title}>技术热线电话</div>
                    <div className={styles.concat_dived}/>
                    <div className={styles.concat_text}>400-8922-177</div>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className={styles.concat}>
                  <img
                    src={"https://assets.yiwoaikeji.com/prod/official-website-assets/action/images/icon_weChat.png"}/>
                  <div style={{marginLeft: "0.1rem"}}>
                    <div className={styles.concat_title}>网络服务支持</div>
                    <div className={styles.concat_dived}/>
                    <div className={styles.concat_text}>yw17727161065（微信客服）</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24}>
            <div className={styles.concat_big}>
              <img src={"https://assets.yiwoaikeji.com/prod/official-website-assets/action/images/icon_address.png"}/>
              <div style={{marginLeft: "0.1rem"}}>
                <div className={styles.concat_title}>总部收件信息</div>
                <div className={styles.concat_dived_big}/>
                <div className={styles.concat_text}>地址：广州越秀区广州大道中599号泰兴606室</div>
                <div className={styles.concat_text}>收件人：陈芳芳&nbsp;&nbsp;联系电话：18922214596</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}