import React from "react";
import styles from "./NewsDetail.module.scss"
import {Col, Row} from "antd";
import {assetsUrl} from "../../constants/constants";

export const dataSourceText = [
  {
    key: '1',
    year: 2023,
    month: "07月",
    day: "18",
    title: "从“鼠头鸭脖”到“鸭眼球膜”，食堂安全问题频出，看智慧餐饮如何应对",
    dom: (
      <div className={styles.new_list_item}>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_07_28/image01.png"}/>
        <p>1 ► 7月17日，网传有人在广州一所职校食堂饭菜中吃出胶制异物，送检结果为“送检样品排除橡胶制品和一次性手套可能，高度可能为鸭眼球巩膜结构”。</p>
        <p>2 ► 而就在此前，6月1日，江西工业职业技术学院学生在食堂吃出疑似为“鼠头”的异物，但学校认定其为“鸭脖”，最后的检测结果确为“鼠头”。</p>
        <p>3 ► 6月19日，有网友爆料称在焦作师范高等专科学校食堂的烤鱼里发现很多蛆虫正在蠕动，调查后证明情况属实。</p>
        <p>事发后，涉事企业、相关责任单位和责任人也依法依规受到了严肃处理。但社会公众对于高校食堂食品安全问题的信心已经被极大地损耗了，尤其是在“鼠头鸭脖”事件中，涉事单位欲盖弥彰不作为的态度更是让人心寒。</p>
        <p>对此，高校食堂食品安全问题、团餐行业规范等问题也被推到了风口浪尖，这些事件的出现也让大众知道了餐饮问题不是一个孤立的问题，而是连接采购、卫生、储存、加工、出菜五个环节为一体的多维度餐饮管理体系。</p>
        <img className={styles.news_detail_img}
             src={assetsUrl + "newsDetail/textImage/2023_07_28/image02.png"}/>
        <p style={{marginTop: 0}}>而作为一种新型的团餐模式——智慧餐饮，对食品安全的把控则更加规范和严格。</p>
        <div>1.食材溯源</div>
        <p style={{marginTop: 0}}>智慧食堂可以通过区块链等技术，实现对食品来源、生产流程及流通环节的可追溯性，保障食品的安全和可信度，从而提供消费者更加可靠的食品来源信息，提高消费者的食品选择信心。
        </p>
        <div>2.菜品检测</div>
        <p
          style={{marginTop: 0}}>智慧食堂能够通过传感器和监控设备，实时检测和记录食品所处的环境条件，比如温度、湿度等，保证食品的安全性和质量，让顾客吃得放心。同时，也可以通过农残检测、菜品留样等功能，严格监督菜品的卫生标准。</p>
        <div>3.信息透明</div>
        <p
          style={{marginTop: 0}}>智慧食堂可以通过线上平台，实时展示菜品的名称、图片、价格、原料、营养成分等信息，让消费者在订餐前就能了解菜品的详细情况，同时也可以查看其他消费者的评价和反馈，提高菜品的信任度。</p>
        <div>4.营养分析</div>
        <p style={{marginTop: 0}}>智慧食堂还可以通过大数据分析，为消费者提供菜品的营养成分、热量、膳食结构等信息，帮助消费者合理选择和搭配菜品。</p>
        <div>食品安全无小事，蚁窝智能餐饮模式可以有效提高食堂食品的质量和安全，降低食物浪费和成本，提升用餐者的满意度和信任度。目前，蚁窝智能餐饮模式已经在多个学校、企业、产业园等场所成功应用，广受好评。</div>
      </div>
    )
  }, {
    key: '2',
    year: 2023,
    month: "07月",
    day: "14",
    title: "【热烈欢迎】广东省投资发展促进会领导莅临广州蚁窝考察交流",
    dom: (
      <div className={styles.new_list_item}>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_07_14/image03.png"}/>
        <p>2023年7月13日上午11点，广东省投资发展促进会领导一行莅临广州蚁窝智能科技有限公司开展考察交流活动。我司总经理何厚明、副总经理姚欣蓉、财务经理陈柏艳等陪同参与此次交流活动。</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_07_14/image04.png"}/>
        <p>来宾一行首先参观了广州蚁窝研发中心的展览厅，由何厚明总经理对公司的发展历程、产品迭代及智慧餐厅用餐模式进行介绍，投促会领导等人也表示在何总的介绍下对智慧餐饮行业有了初步的了解。</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_07_14/image05.png"}/>
        <p>座谈会上，何厚明总经理对投促会领导一行的到来表示热烈欢迎，详细介绍了广州蚁窝发展历程和业务开展情况，随后双方观看了广州蚁窝公司宣传片，进一步了解广州蚁窝的创办理念、品牌优势、专业态度以及人文关怀，并就广州蚁窝的创新业务模式、行业解决方案及品牌建设成果进行了经验分享。</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_07_14/image06.png"}/>
        <p>广东省投促会领导一行对广州蚁窝在智慧餐饮行业的地位和业务专业能力给予了充分肯定，双方希望通过这次考察交流，能够共享餐饮方面的经验和成果，加强双方的合作和交流，共同推动智慧餐饮产业的发展和创新。</p>
      </div>
    )
  }, {
    key: "3",
    year: 2023,
    month: "07月",
    day: "01",
    title: "稳步前进 | 36家智慧餐厅开业，蚁窝智能2023第二季度精彩回顾",
    dom: (
      <div className={styles.new_list_item}>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_07_01/image07.png"}/>
        <p>2023年第二季度，蚁窝智能继续取得了令人鼓舞的成绩，我们在全国各地相继成功打造了36家智能团餐综合项目，覆盖了华东、华南、华中以及西南地区的多个城市。这些智慧餐厅都采用了蚁窝智能最先进的智慧餐饮设备和软件技术，为用餐者提供了便捷、高效、个性化的用餐体验，受到了广泛的好评和欢迎。</p>
        <p style={{fontWeight: "bold"}}>开业餐厅</p>
        <Row style={{marginTop: 0}}>
          <Col xs={24} md={12}>
            <img className={styles.news_detail_col_img}
                 src={assetsUrl + "newsDetail/textImage/2023_07_01/image08.png"}/>
          </Col>
          <Col xs={24} md={12}>
            <img className={styles.news_detail_col_img}
                 src={assetsUrl + "newsDetail/textImage/2023_07_01/image09.png"}/>
          </Col>
          <Col xs={24} md={12}>
            <img className={styles.news_detail_col_img}
                 src={assetsUrl + "newsDetail/textImage/2023_07_01/image10.jpg"}/>
          </Col>
          <Col xs={24} md={12}>
            <img className={styles.news_detail_col_img}
                 src={assetsUrl + "newsDetail/textImage/2023_07_01/image11.jpg"}/>
          </Col>
        </Row>
        <p style={{marginTop: 0}}>参展回顾</p>
        <p>在今年上半年，蚁窝智能还积极参与了行业内的各种展会和活动，共计5场，包括第二届中国团餐博览会暨第三届中国团餐企业家峰会、第五届深圳餐饮博览会、第五届中国教育后勤展览会、重庆国际餐饮食材博览会、中国团餐产业博览会。</p>
        <p>我们在这些展会上展示了蚁窝智能的智慧餐厅解决方案，与业内同行和专家进行了深入的交流和合作，进一步提升了品牌知名度和影响力。</p>
        <a target={"_blank"}
           href={"https://mp.weixin.qq.com/s?__biz=MzAxMDEyMDI0Ng==&mid=2247491575&idx=1&sn=9fb750e2b70e7ff4fed5ea10eeb46d17&chksm=9b5462aeac23ebb81fefa8507641833cbcae526bb380165c73d6e5b3b4f24c66c31ecca57858&scene=21#wechat_redirect"}>
          展会回顾 | 第五届深圳餐饮博览会圆满闭幕，蚁窝智能参展迎完美收官</a>
        <a target={"_blank"}
           href={"https://mp.weixin.qq.com/s?__biz=MzAxMDEyMDI0Ng==&mid=2247491575&idx=1&sn=9fb750e2b70e7ff4fed5ea10eeb46d17&chksm=9b5462aeac23ebb81fefa8507641833cbcae526bb380165c73d6e5b3b4f24c66c31ecca57858&scene=21#wechat_redirect"}>
          蚁窝智能亮相第二届中国团餐博览会，荣获“2022年度中国团餐供应链优秀服务商”称号</a>
        <a target={"_blank"}
           href={"https://mp.weixin.qq.com/s?__biz=MzAxMDEyMDI0Ng==&mid=2247491759&idx=1&sn=8b2a3c9a4f22750bd12fda45ad5e8975&chksm=9b579df6ac2014e0c57e14ec39067c50f7665e7faf2f0215af7362abe62d73d09cdf345099cf&scene=21#wechat_redirect"}>
          第五届中国教育后勤展圆满落幕，蚁窝智能积极推进教育后勤数字化转型</a>
        <a target={"_blank"}
           href={"https://mp.weixin.qq.com/s?__biz=MzAxMDEyMDI0Ng==&mid=2247491897&idx=1&sn=379d66dae96679ef0a56d96268644609&chksm=9b579c60ac20157695674521eb96bb0f33639a636b4ad1455b3c8429f55633c7f643c6f975ac&scene=21#wechat_redirect"}>双展回顾
          | 蚁窝智能获“2022年度安徽团餐信息化服务商”称号</a>
        <p style={{fontWeight: "bold"}}>逆势扩张</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_07_01/image12.png"}/>
        <p>此外，于2023年4月，江苏蚁窝智能科技有限公司由广州蚁窝智能科技有限公司投资成立，旨在在江苏省及全国更多地区推广和落地我们的智慧餐厅项目，为当地的餐饮业带来创新和变革。</p>
        <p style={{fontWeight: "bold"}}>巩固与发展</p>
        <p>在2023年第二季度蚁窝智能继续取得了良好的发展成绩，我们的产品和服务也得到了持续的优化和升级，同时推出了更多新的功能和模块，为我们的客户和用餐者提供了更多的价值和更好服务体验。</p>
        <p>未来，我们将继续坚持以客户为中心，以技术为驱动，以创新为动力，为餐饮行业提供更优质、更高效、更智能的解决方案。</p>
        <p>我们相信，通过蚁窝智能，可以让每一次用餐都变得更美好。</p>
      </div>
    )
  }, {
    key: '4',
    year: 2023,
    month: "06月",
    day: "16",
    title: "热烈庆祝江苏蚁窝进驻京源·睿谷生态科技园",
    dom: (
      <div className={styles.new_list_item}>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_06_16/image13.png"}/>
        <p>6月9日上午，由南通京源环保产业发展有限公司投资兴建的京源·睿谷生态科技园盛大开园。中国环境科学学会、南通市政府、南通市生态环境局等组织的领导及重要嘉宾出席了本次“科创中国”创新基地揭牌仪式暨京源·睿谷生态科技园开园庆典。</p>
        <p style={{fontWeight: "bold"}}>江苏蚁窝入驻京源·睿谷</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_07_01/image12.png"}/>
        <p>江苏蚁窝智能科技有限公司由广州蚁窝智能科技有限公司投资成立。</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_06_16/image15.png"}/>
        <p>江苏蚁窝智能科技有限公司在京源·睿谷生态科技园开园当天顺利进驻，南通市政府副市长等一行领导也来到了蚁窝智能展厅进行参观和指导，并对蚁窝智能餐饮模式表示高度的肯定和期望。</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_06_16/image16.png"}/>
        <p>园区开业当天，也引来了众多媒体争相报道，蚁窝智能受南通电视台邀请接受了采访，向观众介绍蚁窝智能餐饮模式，并对京源·睿谷生态科技园开园表示祝贺与感谢，日后，蚁窝智能将为科技创新行业输出更多先进技术与优质产品。</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_06_16/image17.png"}/>
        <p>蚁窝智能专注于新一代团餐信息技术设备系统的开发与研究，创始团队围绕移动互联、物联网、云计算、人脸识别、SaaS软件系统等方面为核心驱动，自2017年首个智能餐饮项目成功落地至今，自研的智能餐饮设备及系统已安全稳定运营6年多，6年期间不论是数据运算、系统稳定性，还是设备质量都经受住了长期运行的考验！</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_06_16/image18.png"}/>
        <p>目前，“蚁窝智能餐饮系统及设备”的业务范围遍及全国多个省市及地区，为500多万人群提供蚁窝智慧餐厅一站式综合解决方案。服务人次达30多亿，提供7*24小时免费线上服务，现已成功打造了600多个智能团餐项目，涵盖学校、产业园、政府机关、写字楼和工厂企业等多个业态。</p>
        <p style={{fontWeight: "bold"}}>京源·睿谷生态科技园</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_06_16/image19.png"}/>
        <p>京源·睿谷生态科技园，以“未来、生态、科技、家园”为发展理念，是南通首个以能源环保为核心的都市工业综合体新标杆及智能制造产业示范园。</p>
        <p>园区占地约137亩，总建筑面积超20万㎡。秉承持续发展战略，围绕“碳达峰、碳中和”的设计理念，突出“节能、绿色、低碳、循环”，将以能源环保全产业链装备研发与高端智能制造为主要业态推动产业发展，打造“智慧能源综合利用建设示范项目”。园区建成运营达产后，预计实现年应税销售收入18亿元，纳税总额超6500万元，提供超5000个就业岗位。</p>
        <p style={{fontWeight: "bold"}}>携手江苏蚁窝 共创新发展</p>
        <p>未来，蚁窝智能将借京源睿谷地缘优势，在科技领域、餐饮领域绽放更多异彩。广州蚁窝将携手江苏蚁窝，共同打造更多智能餐饮解决方案，不断探索科技创新的可能性，为智能餐饮领域带来更多的价值和机遇。</p>
      </div>
    )
  }, {
    key: '5',
    year: 2023,
    month: "06月",
    day: "12",
    title: "在这里，一个人吃饭也可以有很多选择",
    dom: (
      <div className={styles.new_list_item}>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_06_12/image20.png"}/>
        <p>在现代社会，人们的生活节奏越来越快，工作压力越来越大，很多时候没有时间和同事、朋友一起吃饭。还有时候是因为想一个人静静地享受美食。此外，现代社会的人际关系也变得越来越疏离，很多人都不愿意和陌生人一起吃饭。</p>
        <p>因此，一个人吃饭已经成为了现代人生活中的一种常态。无论是什么原因，一个人吃饭并不代表孤单，反而可以让人更好地享受美食和自己的时间。</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_06_12/image21.png"}/>
        <p>然而一个人吃饭也经常会遇到一些困扰，例如一个人做饭不好控制用量，容易做多吃不完，而浪费粮食；一个人在餐馆点菜一般也只能点一到两个菜，无法品味更多美食；一个人点外卖似乎是更合理便捷地解决一日三餐的选择，但长期吃外卖食品并不利于身体健康。</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_06_12/image22.png"}/>
        <p>诸如此类种种因素，一个人想要吃到既健康营养又种类丰富的餐食似乎是难以实现的事情。为了提高现代人的用餐幸福感，蚁窝智能倾力打造蚁窝智慧餐厅，让一个人吃饭这件事情也变得更加合理和便利。</p>
        <p style={{fontWeight: "bold"}}>智慧餐厅模式 提升就餐体验</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_06_12/image23.png"}/>
        <p>蚁窝智慧餐饮模式，通过“自主选菜、称重计费、智能结算、无感支付”重构食堂消费场景，告别排队，无需收银台，取餐结束，自动结算。重量感应精确到1克，多吃少吃自己把控。真正实现公开透明，迅速高效，灵活自主的用餐体验。</p>
        <p style={{fontWeight: "bold"}}>三步就餐 轻松便捷</p>
        <p>第一步 微信/支付宝扫码绑定托盘</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/gif/scan_code.gif"}/>
        <p>第二步 智能设备取餐：智能称重/按克计费</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/gif/get_dish.gif"}/>
        <p>第三步 无感支付，即时结算</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/gif/nutrition.gif"}/>
        <p>一人就餐 多种选择</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_06_12/image24.png"}/>
        <p>电子显示屏将菜式和价格一并展示，以按需取量的方式替代传统的按份取量，按克计费，单菜单价，就餐者根据个人喜好和饭量，挑选自己喜欢的菜品。蚁窝智慧餐厅后厨拥有上百份标准菜单，包含各种菜系和口味，菜品天天不重样，就算是一个人吃饭也可以轻松品尝多种美食，也不用担心食品浪费问题。</p>
        <p style={{fontWeight: "bold"}}>智能分析饮食结构 传递健康饮食理念</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_06_12/image25.png"}/>
        <p>蚁窝智慧餐厅借助智能系统将汇总所有菜式的热量、蛋白质、脂肪等营养数据，分析个人就餐喜好、消费数据变化趋势，让食堂能及时对菜品结构做出科学性调整，为就餐者提供针对性的健康饮食建议和个性化的消费导向。一个人的就餐也可以如此安全、健康且丰盛。</p>
        <p>一个人吃饭，让时间慢下来，让心灵沉静下来，让味蕾更好地感受食物香气，让美食治愈疲惫的灵魂。蚁窝智能希望，在每一个奋斗的日子里努力的你能够吃出健康、吃得幸福。</p>
      </div>
    )
  }, {
    key: '6',
    year: 2023,
    month: "05月",
    day: "26",
    title: "广东立案查处341件餐饮浪费案件，智慧餐饮如何应对？",
    dom: (
      <div className={styles.new_list_item}>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_05_26/image26.png"}/>
        <p>1 ►
          “自3月部署开展制止餐饮浪费专项行动以来，广东省市场监管局坚决贯彻习近平总书记关于制止餐饮浪费的一系列重要指示批示精神，聚焦餐饮浪费重点领域、重点问题精准发力，以多举措多层次推动制止餐饮浪费行动落实落地，取得初步成效。”</p>
        <p>2 ► “据广东省市场监管局消息，今年以来，全省共开展“随机查餐厅”748次，检查餐饮服务单位5030家，发现问题主体37家，下达责令改正通知书18份，立案1宗，宣传报送163次，网络点击量达413.3万。”</p>
        <p>3 ► “截至目前，全省市场监管部门共检查相对人121516个，对相对人责令改正、给予警告864个，立案查处案件341件，公布典型案例224件。”</p>
        <p>在这样的背景下，蚁窝智能的智慧餐饮模式，能够有效节约粮食，提高餐饮质量和效率。</p>
        <p style={{fontWeight: "bold"}}>按需取餐 智能称重</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_05_26/image27.png"}/>
        <p>蚁窝智慧餐厅支持用户自由拿取菜品，并清晰显示价格，智能称重设备按克计费，吃多少付多少；同时，智慧食堂管理系统能够随时动态统计菜品的销售量，让后厨以数据为根本精准备餐、精准补餐，避免因“经验估量、不换样、不可口”等原因带来的浪费。</p>
        <p style={{fontWeight: "bold"}}>精准备餐 源头节约</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_05_26/image28.png"}/>
        <p>蚁窝智慧餐厅通过自研的智能化系统，全方位对食堂进行数据化管理，对菜品结构进行智能化分析。定期变更菜品列表，满足就餐者的个性化用餐需求，真正实现精准备餐，既能减少厨余的输出，又能有效解决食堂剩餐问题。</p>
        <p>蚁窝智能的智慧餐饮模式，不仅符合国家对制止餐饮浪费行为的要求 ，也符合消费者对绿色、健康、便捷的用餐需求。蚁窝智能致力于打造一个节约型、环保型、智慧型的新型餐饮生态系统，为社会创造更多价值。</p>
        <p style={{fontSize: "15px"}}>资料参考：</p>
        <p style={{fontSize: "15px"}}>央视新闻客户端、</p>
        <p style={{fontSize: "15px"}}>广东市场监管公众号推文《聚焦重点领域问题精准发力！广东制止餐饮浪费行动取得初步成效》</p>
      </div>
    )
  }, {
    key: '7',
    year: 2023,
    month: "05月",
    day: "19",
    title: "小微餐饮成为新风口，打造品牌不再是大厂专利",
    dom: (
      <div className={styles.new_list_item}>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_05_26/image26.png"}/>
        <p>在当下市场上，品牌的塑造是每个企业都十分重视的问题，对于餐饮企业来说，品牌的重要性更是不言而喻。同时，大众常常对塑造品牌有一个认知误区，那就是认为只有大企业才需要做品牌或是认为只有大企业才能打造出品牌，其实不然。打造品牌实质上也是一种营销手段，其目的仍然是为了让产品被更多消费者接受并主动去消费。所以，品牌不是只有大企业才能做，而是每一个想要长久发展的企业在建立初期就应该考虑的东西。</p>
        <p style={{fontWeight: "bold"}}>01 为什么要打造品牌</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_05_19/image29.png"}/>
        <p>对于小企业来说，打造品牌的意义是多方面的。首先，品牌可以帮助小企业在市场上获得更多的关注和认可，提高企业的知名度和美誉度。其次，品牌可以帮助小企业树立起自己的形象和风格，让消费者更容易记住和认同。此外，品牌还可以帮助小企业建立起自己的竞争优势，提高产品或服务的附加值，从而获得更多的市场份额和利润。</p>
        <p>打造品牌固然重要，但阻碍小微型企业形成品牌效应的痛点也是显而易见的。此处以小微型餐饮企业为探讨对象。</p>
        <p style={{fontSize: "15px", fontWeight: "bold"}}>02 小微型餐饮企业打造品牌的痛点</p>
        <p style={{fontWeight: "bold"}}>成本三高：人力·房租·营销</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_05_19/image30.png"}/>
        <p>许多小微型餐饮店首先要面对的三座大山就是人力成本、房租成本以及营销成本。餐饮行业属于劳动密集型服务业，人力成本以及人员流动性大等问题一直都在困扰着餐饮店，同时，高昂的房租也时常成为压死骆驼的最后一根稻草。与人力、房租等高额固定支出相比，营销的费用则更可控一些，但想要达到好的运营效果，必然也要痛下血本。</p>
        <p style={{fontWeight: "bold"}}>能力三低：营销·竞争·数字化</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_05_19/image31.png"}/>
        <p>随着餐饮业的复苏，一批又一批黑马横空出世，吸引了更多的人踏入该行业，竞争已经十分激烈。从原本的只要好吃就行，到如今各个商家变着花样推出新品和活动来看，餐饮业也已经变成了用户流量争夺的一个重要阵地。涉及流量和营销的领域，必然也少不了数字化转型，而很多餐饮商家正是由于不懂得抓住机遇转型而错失风口。</p>
        <p style={{fontWeight: "bold"}}>实力三缺：思维·方法·工具</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_05_19/image32.png"}/>
        <p>时代在改变，科技发展更是日新月异，餐饮店如果依旧按照旧模式经营，可能会变得十分吃力。如今的消费主力群体正在年轻化，且有明确的互联网消费习惯，如果餐饮店不改变传统的经营模式和方法，拥抱数字化转型升级，则会失去大量的市场。除此以外，借力工具也是一个有效的办法，如今市面上就有许多针对中小型餐饮企业的服务商，如：蚁窝智能，帮助商家实现数字化转型，提高效益，打造品牌。</p>
        <p style={{fontWeight: "bold"}}>03 蚁窝智能助力小微型餐饮店打造品牌</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_06_16/image18.png"}/>
        <p>蚁窝智能在智慧餐饮行业深耕多年，已形成成熟的解决方案和技术研发能力，拥有丰富的行业资源和技术经验，目前已有成熟的智能硬件、智能软件、系统集成、数据分析等全链条产品，更拥有完善的服务体系。</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_05_19/image33.png"}/>
        <p>针对小微型餐饮店，蚁窝智能推出了“赋能餐饮连锁品牌”解决方案。以智慧餐厅为入口，把餐厅从传统的用餐模式升级为堂食自选+称重计费+无感支付+便捷外卖+新零售等多元化模式，并能解决餐厅成本高、管理难、营销难等问题。而囿于场地面积不够的商家也不必担心，蚁窝智能的外卖模式可以让商家轻松接单。同时通过智慧餐厅提供的
          SaaS系统和配套硬件产品，帮助小微型餐饮店实现数字化管理、线上营销等功能，以此来提高经营效率。</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_05_19/image34.png"}/>
        <p style={{fontWeight: "bold"}}>04 蚁窝赋能智慧餐饮品牌成功案例</p>
        <p style={{fontWeight: "bold"}}>壹飯</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_05_19/image35.png"}/>
        <p>悦品汇集团旗下的智慧餐饮品牌“壹飯”以自由、智能、丰富、时尚、健康、品质、匠心、蕴味为理念，为企业、商务区和社区共同解决员工和居民工作期间健康餐饮的普遍需求，提供都市生活美食服务。</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_05_19/image36.png"}/>
        <p>以写字楼为主要经营阵地的“壹飯”，除了提供全天候的餐饮美食外，还常常作为社交平台，举办各类高端主题活动，为社会各界人士提供了一个舒适、可靠的社交途径。</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_05_19/image37.png"}/>
        <p>2021年，“壹飯”广州环汇店引入70台蚁窝智能餐饮设备，而后2022年又在华新大厦和江南大道分别新开了两家连锁店，引入50台蚁窝智能餐饮设备，打造了时尚、高端的餐饮品牌形象，营业额十分可观，许多写字楼白领成为“壹飯”的忠实用户。</p>
        <p style={{fontWeight: "bold"}}>华南红</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_05_19/image38.png"}/>
        <p>华南红餐饮是长沙知名餐饮连锁品牌，2020年，华南红启动团膳业务，在蚁窝智能全系列智能餐饮设备的助力下，打造AI智慧食堂，用科技赋能传统餐饮，帮助企业改善员工就餐条件，助力企业员工的幸福感提升。</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_05_19/image39.png"}/>
        <p>华南红餐饮致力于打造具有川湘特色的智慧餐饮品牌，配合蚁窝智能的软硬件设施以及独具一格的装修风格，集休闲、美食于一体，成为众多顾客选择的网红打卡点。</p>
        <img className={styles.news_detail_img} src={assetsUrl + "newsDetail/textImage/2023_05_19/image40.png"}/>
        <p>2021年湖南株洲百货引入了华南红智慧餐厅的智能餐饮设备及系统，40余台智能餐饮设备正式投入使用，可同时服务600多人。在2022年，华南红智慧餐厅又相继在长沙五一广场、株洲百货天元店开业，形成连锁品牌效应，收益可观。</p>
        <p style={{fontWeight: "bold"}}>05 总结</p>
        <p>所以，对于小微型餐饮店应该树立品牌吗？答案是肯定的。但做品牌不是最终目的，持续的盈利能力才是，所以树立品牌也并非一劳永逸的做法，对于餐饮店经营者来说，时刻保持对新趋势、新市场、新技术的敏锐嗅觉是十分必要的。如今，已经有许多小微型餐饮店通过蚁窝智能的新餐饮模式打造出了自己的品牌，不用怀疑，你也一样可以！</p>
      </div>
    )
  },
];