import {Banner} from "../../components/Banner/Banner";
import React, {useEffect} from "react";
import styles from "./BindingDevice.module.scss"
import {Col, Row} from "antd";
import {
  dataSourceOperationPro,
  dataSourceFaceTech,
  dataSourceOperationCard,
  dataSourceCardTech,
  dataSourceAutoOperation, dataSourceAutoTech
} from "./dataSource"
import {Process} from "../../components/Process/Process";
import {Specs} from "../../components/Specs/Specs";
import {DeviceList} from "../../components/DeviceList/DeviceList";
import {useLocation} from "react-router-dom";
import {scrollTo} from "../../utils/utils";
import {YiWoVideo} from "../../components/YiWoVideo/YiWoVideo";

export const BindingDevice = () => {
  let location = useLocation().state;
  useEffect(() => {
    scrollTo(location)
  }, []);
  return (
    <div>
      <Banner title={"蚁窝智能硬件设备"}
              text={"基于多年对传统团餐及传统食堂用餐模式的深度了解，蚁窝技术团队从运营及应用等多维度分析探究，研发出多款应用于智慧餐厅的智能设备，能够有效解决传统食堂的痛点，提升用餐体验。"}
              image={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/banner/banner.png"}/>
      <div id={"bindBoard_device"}>
        <DeviceList heightLightKey={"1"}/>
      </div>
      <div id={"bindBoard"} style={{backgroundColor: "#DEF0FF"}} className={styles.device_title_container}>
        <div id={"faceBoard"} style={{color: "#333C4F"}} className={styles.device_title_container_title}>蚁窝智能</div>
        <div className={styles.face_device_title_container_text}>“刷脸”绑盘机</div>
      </div>
      <div style={{background: "#F0F5F9"}} className={styles.device_container}>
        <Row style={{display: "flex", alignItems: "center"}} justify={"center"} gutter={[64, 16]}>
          <Col style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }} xs={24} md={18}>
            <div className={`${styles.device_popover} ${styles.face_pop_yellow}`}>
              <div>仅需0.8秒</div>
            </div>
            <div className="flex">
                        <span className={`${styles.device_popover} ${styles.face_pop_orange_red}`}>
                            <div>无手机就餐场景</div>
                        </span>
              <span className={`${styles.device_popover} ${styles.face_pop_green}`}>
                            <div>人脸识别绑定</div>
                        </span>
            </div>
            <div
              className={`${styles.device_text_container} text-space-normal`}>
              适用于无法使用手机就餐的场景，就餐者通过人脸识别绑定餐盘进行消费，0.8秒即可完成识别。
            </div>
          </Col>
          <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={6}>
            <img className={styles.device_container_device}
                 src={"https://assets.yiwoaikeji.com/prod/official-website-assets/bindDevice/images/bind_device.png"}/>
          </Col>
        </Row>
      </div>
      <div>
        <YiWoVideo id={"bindDeviceByFace"} width={"10rem"} height={"3rem"}
                   videoUrl={"video/bind_device_by_face.mp4"}
                   poster={"bindDevice/backgroundImage/bck_operation_bind01.png"}/>
      </div>
      <div className={`${styles.process_pad}`}>
        <Process space={7} items={dataSourceOperationPro} arrowWidth={176}/>
      </div>
      <div className={`container ${styles.bind_plate_device_bck}`}>
        <Specs items={dataSourceFaceTech}/>
      </div>
      <div style={{backgroundColor: "#FFF5E0"}} className={styles.device_title_container}>
        <div id={"cardBoard"} style={{color: "#6D180D"}} className={styles.device_title_container_title}>蚁窝智能</div>
        <div className={styles.card_device_title_container_text}>“刷卡”绑盘机</div>
      </div>
      <div style={{background: "#FFFBF3"}} className={styles.device_container}>
        <Row style={{display: "flex", alignItems: "center"}} justify={"center"} gutter={[64, 16]}>
          <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={6}>
            <img className={styles.device_container_device}
                 src={"https://assets.yiwoaikeji.com/prod/official-website-assets/bindDevice/images/card_bind_device.png"}/>
          </Col>
          <Col style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }} xs={24} md={18}>
            <div className="flex">
                        <span className={`${styles.device_popover} ${styles.orange_red_card}`}>
                            <div>
                                <div>无手机就</div>
                                <div>餐场景</div>
                            </div>
                        </span>
              <span className={`${styles.device_popover} ${styles.yellow_card}`}>
                                <div>
                                <div>智能刷</div>
                                <div>卡绑定</div>
                            </div>
                        </span>
              <span className={`${styles.device_popover} ${styles.green_card}`}>
                            <div>NFC</div>
                        </span>
            </div>
            <div
              className={`${styles.device_text_container} text-space-normal`}>
              适用于少部分用卡/手环用户的场景，部分没有手机的就餐者可通过刷卡绑定托盘进行消费。
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <YiWoVideo id={"bindDeviceByCard"} width={"10rem"} height={"3rem"} videoUrl={"video/bind_device_by_card.mp4"}
                   poster={"bindDevice/backgroundImage/bck_operation_bind02.png"}/>
      </div>
      <div className={`${styles.process_pad}`}>
        <Process space={7} items={dataSourceOperationCard} arrowWidth={176}/>
      </div>
      <div className={`container ${styles.bind_plate_device_bck}`}>
        <Specs items={dataSourceCardTech}/>
      </div>
      <div style={{backgroundColor: "#EDEBFF"}} className={styles.device_title_container}>
        <div id={"queryBoard"} style={{color: "#5C4268"}} className={styles.device_title_container_title}>蚁窝智能</div>
        <div className={styles.auto_device_title_container_text}>“自助”查询机</div>
      </div>
      <div style={{background: "#F7F6FF"}} className={styles.device_container}>
        <Row style={{display: "flex", alignItems: "center"}} justify={"center"} gutter={[64, 16]}>
          <Col style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }} xs={24} md={18}>
            <div className="flex">
                            <span className={`${styles.device_popover} ${styles.orange_red_auto}`}>
                            <div>刷脸</div>
                        </span>
              <span className={`${styles.device_popover} ${styles.yellow_auto}`}>
                            <div>手环</div>
                        </span>
              <span className={`${styles.device_popover} ${styles.green_auto}`}>
                            <div>刷卡</div>
                        </span>
            </div>
            <div
              className={`${styles.device_text_container} text-space-normal`}>
              适用于无法使用手机的场景，通过人脸识别、刷卡或手环进行余额查询或订单查询。
            </div>
          </Col>
          <Col style={{display: "flex", justifyContent: "center"}} xs={24} md={6}>
            <img className={styles.device_container_device}
                 src={"https://assets.yiwoaikeji.com/prod/official-website-assets/bindDevice/images/auto_check_device.png"}/>
          </Col>
        </Row>
      </div>
      <YiWoVideo id={"auto_check_operation"} width={"100%"} height={"3rem"} videoUrl={"video/auto_check_operation.mp4"}
                 poster={"bindDevice/backgroundImage/bck_operation_bind03.png"}/>
      <div className={`${styles.process_pad}`}>
        <Process space={9} items={dataSourceAutoOperation} arrowWidth={176}/>
      </div>
      <div className={`container ${styles.bind_plate_device_bck}`}>
        <Specs items={dataSourceAutoTech}/>
      </div>
    </div>
  )
}