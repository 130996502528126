import styles from "./ModeCard.module.scss";
import React, {FC} from "react";

interface propsType {
  image: any;
  title: React.ReactNode;
  text: string;
  style?: object;
  titleMargin?: string;
  imageMargin?: string;
}

const ModeCard: FC<propsType> = (props) => {
  const {image, title, text, imageMargin, titleMargin,} = props
  return (
    <div className={styles.container}>
      <div className={styles.mode_img_container}>
        <img className={styles.mode_img} src={image}/>
      </div>
      <div className={styles.title}>
        <div>{title}</div>
      </div>
      <div className={styles.text}>
        {text}
      </div>
    </div>
  )
};
export default ModeCard;