import styles from "./TwoSoftWare.module.scss";
import {Col, Row} from "antd";
import React from "react";
import {NavLink} from "react-router-dom";

interface propsType {
  leftTitleColor: string;
  rightTitleColor: string;
}

interface IPropsType extends React.FC<propsType> {

}

export const TwoSoftware: IPropsType = ({
                                          leftTitleColor = "#333333",
                                          rightTitleColor = "#333333"
                                        }) => {
  return (
    <div className={`${styles.device_pad}`}>
      <Row justify={"center"} style={{display: "flex", justifyContent: "flex-start",alignItems:"baseline"}}>
        <Col style={{display: "flex", justifyContent: "center"}} xs={12}
             md={12}>
          <NavLink to={"/yiwo_system"}>
            <div>
              <img style={{marginTop: "0.21rem"}}
                   src={"https://assets.yiwoaikeji.com/prod/official-website-assets/productCenter/card/icon_AIBackEnd.png"}/>
              <div style={{color: leftTitleColor}} className={styles.device_title}>智能后台系统</div>
            </div>
          </NavLink>
        </Col>
        <Col style={{display: "flex", justifyContent: "center"}} xs={12}
             md={12}>
          <NavLink to={"/yiwo_app"}>
            <div>
              <img
                src={"https://assets.yiwoaikeji.com/prod/official-website-assets/productCenter/card/icon_yiwoAI_app.png"}/>
              <div style={{color: rightTitleColor}} className={styles.device_title}>蚁窝AI APP</div>
            </div>
          </NavLink>
        </Col>
      </Row>
    </div>
  )
}