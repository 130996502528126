import React, {useEffect, useState} from "react";
import {Layout} from 'antd';
import Nav from "../components/Nav/Nav";
import {enquireScreen} from "enquire-js";
import Footer from "../components/Footer/Footer";
import enquire from 'enquire-js'
import styles from "./MainLayouts.module.scss";
import {assetsUrl} from "../constants/constants";

const MainLayout = (props: any) => {
  const {Content} = Layout;
  const [isMobile, setIsMobile] = useState(undefined);
  const [isVertical, setIsVertical] = useState(false);
  const [isExpand, setIsExpand] = useState(undefined);
  const [showMask, setShowMask] = useState(true);
  const {children} = props;
  useEffect(() => {
    enquire.register("screen and (max-width:576px)", {
      match: function () {
        setIsVertical(true)
      },
      unmatch: () => {
        setIsVertical(false)
      }
    });
    enquireScreen((b: any) => {
      setIsMobile(b);
    })

  }, []);

  const onCancelMask = () => {
    setShowMask(false);
  }

  return (
    <div>
      <div onClick={onCancelMask} style={{display: isVertical && showMask ? 'block' : 'none'}}>
        <div
          className={styles.mask}/>
        <div className={styles.cubic}>
          <img className={styles.onMove}
               src={assetsUrl + "public/mobile.png"}/>
          <p style={{color: "#ffffff"}}>横屏观看，体验更佳</p>
        </div>
      </div>
      <Layout className="layout">
        <Nav isExpand={isExpand} isMobile={isMobile}/>
        <div style={{marginTop: 82 / 192 + "rem"}}>
          <Content onClick={() => {
            setIsExpand(undefined)
          }} style={{overflowX: "hidden", overflowY: "hidden"}}>
            {children}
          </Content>
        </div>
        <Footer/>
      </Layout>
    </div>
  )
};
export default MainLayout;