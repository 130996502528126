import React, {useEffect, useState} from "react";
import {OverPack} from "rc-scroll-anim";
import QueueAnim from "rc-queue-anim/lib/QueueAnim";
import styles from "./Main.module.scss";
import 'rc-banner-anim/assets/index.css';
import {Col, Row, ConfigProvider, Collapse, Button} from "antd";
import ModeCard from "../../components/ModeCard/ModeCard";
import TweenOne from "rc-tween-one";
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import {enquireScreen} from "enquire-js";
import {
  dataSourceModeCard,
  dataSourceSolutionCard,
  dataSourceSolutionModeCard,
  dataSourceAdvDataLeft,
  dataSourceAdvRight,
  dataSourceSteps,
  dataSourceMonMess, dataSourceCusExample
} from "./dataSource";
import {MainBanner} from "../../components/MainBanner/MainBanner";
import {NavLink, useNavigate} from "react-router-dom";
import {AntdCard} from "../../components/AntdCard/AntdCard";
import {assetsUrl} from "../../constants/constants";
import {toRemAuto} from "../../utils/utils";

TweenOne.plugins.push(Children);
const Main = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(undefined);
  useEffect(() => {
    enquireScreen((b: any) => {
      setIsMobile(b);
    })
  }, []);

  const dataMonthCollapseFunc = (data) => {
    return data.map((item, index) => {
      return {
        key: item.key,
        label: (
          <div
            style={{display: "flex", alignItems: "center", marginLeft: index === 0 ? toRemAuto(-67) : toRemAuto(-50)}}>
            {index === 0 &&
              <img className={styles.collapse_img}
                   src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_yiwo_head.png"} />
            }
            {
              index !== 0 &&
              <div className={styles.monthly_mess_dot} />
            }
            <div className={styles.monthly_mess_title}>{item.label}</div>
          </div>
        ),
        children: (
          <span onClick={() => toNewsDetail(item.key)} className={styles.monthly_mess_text}>{item.text}</span>
        )
      }
    })
  }

  const toNewsDetail = (key) => {
    navigate("/news_detail", {
      state: {
        id: key
      }
    })
  }

  return (
    <div className={styles.main_container}>
      <MainBanner />
      <div style={{backgroundColor: "#ffffff"}} className={styles.mode_header}>
        <div className={styles.mode_title}>智慧餐饮模式</div>
        <div style={{marginTop: "20px"}} className={styles.mode_text}>蚁窝智慧餐饮模式，解决餐饮行业痛点，实现利润翻倍增长</div>
        <OverPack playScale={0.1}
                  style={{marginTop: toRemAuto(80), width: "100%"}}>
          <QueueAnim
            type="bottom"
            key="block"
            component={Row as React.ClassType<any, any, any>}
            componentProps={{gutter: [32, 8]}}
            leaveReverse
          >
            {dataSourceModeCard.map((item, index) => {
              return (
                <Col style={{display: "flex", justifyContent: "center"}} md={12}
                     xl={6} xxl={6} xs={24} key={index.toString()}>
                  <ModeCard imageMargin={item.imageMargin} titleMargin={item.titleMargin} image={item.image}
                            title={item.title} text={item.text} />
                </Col>
              )
            })}
          </QueueAnim>
        </OverPack>
      </div>
      <div style={{backgroundColor: "#FAFAFA"}} className={styles.solution_mode}>
        <div className={styles.mode_title}>解决方案</div>
        <div style={{marginTop: "20px"}}
             className={styles.mode_text}>蚁窝智能针对传统食堂经营痛点，提供“智能称重结算系统”+“智慧食堂改造计划”的一站式解决方案。
        </div>
        <div className={styles.solution_mode_cards}>
          <OverPack playScale={0}
                    style={{marginTop: "0.25rem", width: "100vw"}}>
            <QueueAnim
              type="bottom"
              key="block"
              component={Row as React.ClassType<any, any, any>}
              componentProps={{gutter: [0, 16]}}
              leaveReverse
            >
              <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}} lg={6} sm={24} xs={24}>
                <div className={styles.solution_title_color_orange}>
                  自主快餐模式
                </div>
              </Col>
              {dataSourceSolutionCard.map((item, index) => {
                return (
                  <Col style={{display: "flex", alignItems: "center"}}
                       key={index} sm={6} xs={12} lg={3}>
                    <div className={styles.solution_card}>
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <img className={styles.solution_img} src={item.image} />
                      </div>
                      <div style={{
                        textAlign: "center",
                        marginTop: toRemAuto(19),
                      }} className="text-space-small">{item.title}</div>
                    </div>
                  </Col>
                )
              })}
            </QueueAnim>
          </OverPack>
          <div className={styles.solution_dived} />
          <OverPack playScale={0.1}
                    style={{marginTop: "20px", width: "100vw"}}>
            <QueueAnim
              type="bottom"
              key="block"
              component={Row as React.ClassType<any, any, any>}
              componentProps={{gutter: [0, 16]}}
              leaveReverse
              style={{marginTop: "20px"}}
            >
              <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}} lg={6} sm={24} xs={24}>
                <div className={styles.solution_title_color_orange}>档口售餐模式</div>
              </Col>
              {dataSourceSolutionModeCard.map((item: any, index) => {
                return (
                  <Col style={{display: "flex", alignItems: "center"}}
                       key={index} sm={6} xs={12} lg={3}>
                    <div className={styles.solution_card}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <img className={styles.solution_img} src={item.image} />
                      </div>
                      <div style={{
                        display: "flex",
                        justifyContent: "center"
                      }}>
                        <div>
                          {item.titles.map((item, index) => {
                            return (
                              <div className="text-space-small"
                                   style={{
                                     textAlign: "center",
                                     marginTop: toRemAuto(19)
                                   }}
                                   key={index.toString()}>{item.title}</div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </Col>
                )
              })}
            </QueueAnim>
          </OverPack>
        </div>
      </div>
      <div style={{backgroundColor: "#ffffff"}} className={styles.mode_news}>
        <div className={styles.mode_title}>资讯动态</div>
        <div style={{marginTop: "20px"}}
             className={styles.mode_text}>跟踪时讯，聚焦热点
        </div>
        <Row style={{
          marginTop: "0.4rem",
          justifyContent: "center",
          alignItems: "center",
        }} gutter={[128, 16]}>
          <Col style={{
            display: "flex",
            justifyContent: "center",
          }} md={12} xs={24}>
            <div>
              <Collapse
                style={{backgroundColor: "#ffffff", width: "3rem", minWidth: "200px"}}
                expandIcon={(({isActive}) => <div />)}
                bordered={false}
                items={dataMonthCollapseFunc(dataSourceMonMess)}
                defaultActiveKey={['2']} />
              <div>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#FFB119"
                    }
                  }}
                >
                  <NavLink to={"/news"}>
                    <Button
                      size={"large"}
                      style={{color: "#FFB119", borderColor: "#FFB119", marginTop: "0.1rem"}}>
                      了解更多资讯
                      <img style={{marginLeft: "0.05rem"}}
                           src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_arrow01.png"} />
                    </Button>
                  </NavLink>
                </ConfigProvider>

              </div>
            </div>
          </Col>
          <Col style={{display: isMobile ? "none" : "flex"}} md={12} xs={24}>
            <div className={styles.monthly_rep_background}>
            </div>
          </Col>
        </Row>
      </div>
      <div className={`${styles.mode} ${styles.adv_background}`}>
        <div className={styles.mode_title}>蚁窝优势</div>
        <div style={{marginTop: "0.12rem"}}
             className={styles.mode_text}>蚁窝智能致力于用科技赋能食堂，为食堂经营者和用餐者打造更舒适便利的经营体验和用餐环境，让吃饭成为一件幸福的事！
        </div>
        <div style={{marginTop: "0.85rem"}}>
          <OverPack playScale={0.3}>
            <QueueAnim
              type="bottom"
              key="block"
              leaveReverse={false}
              style={{marginLeft: "5rem", display: "flex"}}
            >
              <div className={styles.adv_sum}>
                {dataSourceAdvDataLeft.map((item: any, index) => {
                  return (
                    <div key={index.toString()} className={styles.adv_data_list}>
                      <TweenOne
                        animation={{
                          Children: {
                            value: item.num,
                            formatMoney: true,
                            floatLength: 0,
                          },
                          duration: 1000,
                          delay: 200,
                          ease: 'easeInOutCirc',
                        }}
                        component={"span"}
                        componentProps={{className: styles.adv_num}}>
                        0
                      </TweenOne>
                      <span className={styles.adv_num}>{item.unit}</span>
                      <div className={styles.adv_text}>{item.text}</div>
                    </div>
                  )
                })}
              </div>
              <div className={styles.adv_sum}>
                {dataSourceAdvRight.map((item: any, index) => {
                  return (
                    <div key={index.toString()} className={styles.adv_data_list}>
                      <TweenOne
                        animation={{
                          Children: {
                            value: item.num,
                            formatMoney: true,
                            floatLength: 0,
                          },
                          duration: 1000,
                          delay: 200,
                          ease: 'easeInOutCirc',
                        }}
                        component={"span"}
                        componentProps={{className: styles.adv_num}}>
                        0
                      </TweenOne>
                      <span className={styles.adv_num}>{item.unit}</span>
                      <div className={styles.adv_text}>{item.text}</div>
                    </div>
                  )
                })}
              </div>
            </QueueAnim>
            <div className={`${styles.adv_foot_text} text-space-small`}>*以上数据截止时间为2023年12月31日</div>
          </OverPack>
        </div>
      </div>
      <div style={{background: "#FFFFFF"}} className={styles.mode_cases}>
        <div className={styles.mode_title}>客户案例</div>
        <div style={{marginTop: "20px"}}
             className={styles.mode_text}>
          跟踪时讯，聚焦热点
        </div>
        <OverPack playScale={0.1}
                  style={{marginTop: "20px", width: "100%"}}>
          <QueueAnim
            type="bottom"
            key="block"
            component={Row as React.ClassType<any, any, any>}
            componentProps={{gutter: [32, 16]}}
            leaveReverse
          >
            {dataSourceCusExample.map((item, index) => {
              return (
                <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                     key={index.toString()} md={12} lg={8} xs={24} sm={12}>
                  <AntdCard hoverable={false} titles={item.titles} text={item.text} imagePath={item.image} />
                </Col>
              )
            })}
          </QueueAnim>
          <div style={{display: "flex", justifyContent: "center", marginTop: "0.2rem",}}>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#FFB119"
                }
              }}
            >
              <NavLink to={"/Case"}>
                <Button size={"large"}
                        className={styles.about_more_btn}>
                  了解更多案例
                  <img style={{marginLeft: "0.05rem"}}
                       src={"https://assets.yiwoaikeji.com/prod/official-website-assets/public/icon_arrow01.png"} />
                </Button>
              </NavLink>
            </ConfigProvider>
          </div>
        </OverPack>
      </div>
      <img className={styles.mode_img} src={assetsUrl + "public/bck_cooperation_cases.jpg"} />
      <div className={`${styles.mode} ${styles.cp_background}`}>
        <div className={styles.mode_title}>合作流程</div>
        <div style={{marginTop: "20px"}}
             className={styles.mode_text}>
          为客户提供一站式全流程服务
        </div>
        <div className={styles.cp_steps}>
          {dataSourceSteps.map((item, index) => {
            return (
              <div key={index.toString()} className={styles.cp_step_item}>
                <img className={styles.cp_step_item_img} src={item.title} />
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: index === dataSourceSteps.length - 1 ? 'none' : 'center'
                }}>
                  <img className={styles.cp_item_img} src={item.image} />
                  <div style={{display: index === dataSourceSteps.length - 1 ? 'none' : 'flex'}}
                       className={styles.cp_item_dived} />
                </div>
                <span className={styles.cp_item_text}>{item.text}</span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default Main;