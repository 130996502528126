export const dataSourceAfterSales = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/action/card/icon_customer_service.png",
    text: "专人线上即时服务咨询",
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/action/card/icon_Difficult_faults.png",
    text: "疑难故障专人上门检修",
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/action/card/icon_service_time_liness.png",
    text: "服务及时性提升30%",
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/action/card/icon_dispatch_efficiency.png",
    text: "派工效率提升65%",
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/action/card/icon_service_staff.png",
    text: "服务人员生产力提升30%",
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/action/card/icon_fully_enclosed_management.png",
    text: "综合全闭环管理，让服务更具价值",
  },
];
export const dataSourceServiceRepair = [
  {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/action/card/icon_online.png",
    title: "线上检修、排查故障",
    text: "技术人员及时响应，线上/远程协助排查故障，指导现场人员进行故障排查、部件更换等，尽快解决故障问题。",
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/action/card/icon_offline.png",
    title: "现场服务、线下上门维修",
    text: "无法线上解决的，公司指派维修技术人员赴现场，客服与现场人员了解故障情况后填写并发起《售后维修服务单》，应注明故障原因、性质、类别、地址及应携带的仪器工具。",
  }, {
    image: "https://assets.yiwoaikeji.com/prod/official-website-assets/action/card/icon_finish_confirm.png",
    title: "检修完成确认",
    text: "售后人员需通知用户预计到达时间，到达后马上进行故障分析、查找、处理，填写《售后维修服务单》并经用户确认、签字并收费。（收费标准详情请联系官方客服人员，检修更换设备零配件严格按照《售后配件标准价格表》收费。）",
  },
]